import { createFileRoute } from "@tanstack/react-router";
import { useTranslation } from "react-i18next";
import React from "react";
import PENTEST_PROJECT_CONTEXT from "src/context/pentest-project-internal";
import { FullPentestProject } from "src/api/generated";
import { Table, TableBody, TableCell, TableRow } from "src/components/base/table";
import { Button } from "src/components/base/button";
import { Subheading } from "src/components/base/heading";
import { Api, UUID } from "src/api/api";
import { toast } from "react-toastify";

/**
 * The properties for {@link PentestProjectFiles}
 */
export type PentestProjectFilesProps = {};

/**
 * The files for a pentest project
 */
export default function PentestProjectFiles(props: PentestProjectFilesProps) {
    const [t] = useTranslation();
    const { obj } = React.useContext(PENTEST_PROJECT_CONTEXT);
    const project = obj as FullPentestProject;

    return (
        <div className={"flex flex-col gap-6"}>
            <div>
                <Subheading>{t("label.files")}</Subheading>
                <Table>
                    <TableBody>
                        <TableRow>
                            <TableCell>{t("pentest.label.kickoff-presentation")}</TableCell>
                            <TableCell>
                                {!project.kick_off_presentation ? (
                                    <small>{t("pentest.description.not-uploaded")}</small>
                                ) : (
                                    <Button
                                        target={"_blank"}
                                        href={`/api/frontend/v1/internal/customers/files/${project.kick_off_presentation.uuid}`}
                                        download={project.kick_off_presentation.name}
                                    >
                                        {t("pentest.button.download")}
                                    </Button>
                                )}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>{t("pentest.label.dialog-presentation")}</TableCell>
                            <TableCell>
                                {!project.dialog_presentation ? (
                                    <small>{t("pentest.description.not-uploaded")}</small>
                                ) : (
                                    <Button
                                        target={"_blank"}
                                        href={`/api/frontend/v1/internal/customers/files/${project.dialog_presentation.uuid}`}
                                        download={project.dialog_presentation.name}
                                    >
                                        {t("pentest.button.download")}
                                    </Button>
                                )}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>{t("pentest.label.management-presentation")}</TableCell>
                            <TableCell>
                                {!project.mgmt_presentation_presentation ? (
                                    <small>{t("pentest.description.not-uploaded")}</small>
                                ) : (
                                    <Button
                                        target={"_blank"}
                                        href={`/api/frontend/v1/internal/customers/files/${project.mgmt_presentation_presentation.uuid}`}
                                        download={project.mgmt_presentation_presentation.name}
                                    >
                                        {t("pentest.button.download")}
                                    </Button>
                                )}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>{t("pentest.label.report")}</TableCell>
                            <TableCell>
                                {!project.report ? (
                                    <small>{t("pentest.description.not-uploaded")}</small>
                                ) : (
                                    <Button
                                        target={"_blank"}
                                        href={`/api/frontend/v1/internal/customers/files/${project.report.uuid}`}
                                        download={project.report.name}
                                    >
                                        {t("pentest.button.download")}
                                    </Button>
                                )}
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </div>
            <div className={"flex flex-col gap-6 "}>
                <Subheading>{t("label.links")}</Subheading>
                {project.modules.external && project.modules.external.targets_state === "Finished" && (
                    <Button
                        onClick={() => project.modules.external && downloadTargets(project.modules.external.targets)}
                    >
                        {t("label.pentest-targets")}
                    </Button>
                )}
                {project.modules.assessment && (
                    <>
                        {project.modules.assessment.technical_questionnaire_state === "Finished" && (
                            <Button
                                onClick={() =>
                                    project.modules.assessment &&
                                    downloadQuestionnaire(
                                        project.modules.assessment.technical_questionnaire,
                                        "technical_questionnaire.txt",
                                    )
                                }
                            >
                                {t("label.questionnaire-tech")}
                            </Button>
                        )}
                        {project.modules.assessment.organizational_questionnaire_state === "Finished" && (
                            <Button
                                onClick={() =>
                                    project.modules.assessment &&
                                    downloadQuestionnaire(
                                        project.modules.assessment.organizational_questionnaire,
                                        "organizational_questionnaire.txt",
                                    )
                                }
                            >
                                {t("label.questionnaire-organisational")}
                            </Button>
                        )}
                    </>
                )}
            </div>
        </div>
    );
}

/**
 * Download the pentest targets
 *
 * @param uuid The uuid of the targets
 */
async function downloadTargets(uuid: UUID) {
    const result = await Api.internal.projects.pentest.targets.get(uuid);
    if (result.isErr) {
        toast.error(result.err.message);
        return;
    }

    const json = result.ok.optional;
    if (json === null || json === undefined) {
        toast.error("Not Found");
        return;
    }

    downloadBlob(new Blob([JSON.stringify(json)], { type: "application/javascript" }), "targets.json");
}

/**
 * Download a questionnaire
 *
 * @param uuid UUID of the questionnaire
 * @param filename Filename to set
 */
async function downloadQuestionnaire(uuid: UUID, filename: string) {
    const result = await Api.internal.questionnaires.exportTxt(uuid);
    if (result.isErr) {
        toast.error(result.err.message);
        return;
    }

    const txt = result.ok.optional;
    if (txt === null || txt === undefined) {
        toast.error("Not Found");
        return;
    }

    downloadBlob(new Blob([txt], { type: "text/plain" }), filename);
}

/**
 * Download a blob
 *
 * @param blob the blob to download
 * @param filename Filename to set
 */
function downloadBlob(blob: Blob, filename: string) {
    const a = document.createElement("a");
    const url = URL.createObjectURL(blob);
    a.setAttribute("href", url);
    a.setAttribute("download", filename);
    a.click();
    URL.revokeObjectURL(url);
}

export const Route = createFileRoute("/_internal/i/pentests/$projectId/_pentest/files")({
    component: PentestProjectFiles,
});
