import React from "react";

/**
 * Format the contents of a textarea for displaying
 *
 * @param value The input string
 * @returns The formatted output
 */
export function formatTextArea(value: string): React.ReactNode {
    const lines: Array<React.ReactNode> = [];

    value.split("\n").forEach((line) => {
        lines.push(<p>{line}</p>);
        lines.push(<br />);
    });

    return lines.slice(0, lines.length - 1);
}
