/* tslint:disable */
/* eslint-disable */
/**
 * Unnamed API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ApiErrorResponse,
  CreateTotpRequest,
  FormResultForNullAndDeleteTotpErrors,
  FormResultForSingleUuidAndCreateTotpErrors,
  ListForSimpleTotpKey,
} from '../models/index';

export interface CreateTotpKeyRequest {
    CreateTotpRequest: CreateTotpRequest;
}

export interface DeleteTotpKeyRequest {
    uuid: string;
}

/**
 * 
 */
export class UserTOTPKeysApi extends runtime.BaseAPI {

    /**
     * Adds a TOTP key to the logged-in user.  This may only be called by local users.
     * Adds a TOTP key to the logged-in user.
     */
    async createTotpKeyRaw(requestParameters: CreateTotpKeyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FormResultForSingleUuidAndCreateTotpErrors>> {
        if (requestParameters['CreateTotpRequest'] == null) {
            throw new runtime.RequiredError(
                'CreateTotpRequest',
                'Required parameter "CreateTotpRequest" was null or undefined when calling createTotpKey().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/frontend/v1/common/users/me/totp`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['CreateTotpRequest'],
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Adds a TOTP key to the logged-in user.  This may only be called by local users.
     * Adds a TOTP key to the logged-in user.
     */
    async createTotpKey(requestParameters: CreateTotpKeyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FormResultForSingleUuidAndCreateTotpErrors> {
        const response = await this.createTotpKeyRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Removes a totp key  This may only be called by local users.
     * Removes a totp key
     */
    async deleteTotpKeyRaw(requestParameters: DeleteTotpKeyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FormResultForNullAndDeleteTotpErrors>> {
        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling deleteTotpKey().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/frontend/v1/common/users/me/totp/{uuid}`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Removes a totp key  This may only be called by local users.
     * Removes a totp key
     */
    async deleteTotpKey(requestParameters: DeleteTotpKeyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FormResultForNullAndDeleteTotpErrors> {
        const response = await this.deleteTotpKeyRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Retrieves TOTP keys for the logged-in user.  This may only be called by local users.
     * Retrieves TOTP keys for the logged-in user.
     */
    async listTotpKeysRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListForSimpleTotpKey>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/frontend/v1/common/users/me/totp`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Retrieves TOTP keys for the logged-in user.  This may only be called by local users.
     * Retrieves TOTP keys for the logged-in user.
     */
    async listTotpKeys(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListForSimpleTotpKey> {
        const response = await this.listTotpKeysRaw(initOverrides);
        return await response.value();
    }

}
