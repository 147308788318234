import { createFileRoute } from "@tanstack/react-router";
import { useTranslation } from "react-i18next";
import { Button } from "src/components/base/button";
import { Api } from "src/api/api";
import { toast } from "react-toastify";
import { CheckCircleIcon } from "@heroicons/react/16/solid";
import React from "react";
import { Field, FieldGroup, Fieldset, Label } from "src/components/base/fieldset";
import HeadingLayout from "src/components/base/heading-layout";

/**
 * The properties for {@link StartInitialScanForm}
 */
export type StartInitialScanFormProps = {};

/**
 *  Form to check the task with a button
 */
function StartInitialScanForm(props: StartInitialScanFormProps) {
    const [t] = useTranslation();
    const { taskId } = Route.useParams();

    return (
        <HeadingLayout heading={t("tasks.label.start-initial-scan")}>
            <Fieldset>
                <FieldGroup>
                    <Field>
                        <div className={"flex items-center gap-3"}>
                            <Label>{t("internal.external-tasks.label.start-initial-scan")}</Label>
                            <Button
                                onClick={async () => {
                                    const res = await Api.internal.tasks.finishExternalV0(taskId);
                                    if (res.isOk) {
                                        window.history.back();
                                    } else if (res.isErr) {
                                        toast.error(res.err.message);
                                    }
                                }}
                            >
                                <CheckCircleIcon />
                            </Button>
                        </div>
                    </Field>
                </FieldGroup>
            </Fieldset>
        </HeadingLayout>
    );
}
export const Route = createFileRoute("/_internal/i/forms/tasks/$taskId/start-initial-scan-v0")({
    component: StartInitialScanForm,
});
