/* tslint:disable */
/* eslint-disable */
/**
 * Unnamed API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ApiErrorResponse,
  FullProjectContacts,
  ListForCustomerSimpleProject,
  ProjectContactCategory,
} from '../models/index';

export interface AddProjectContactCustomerRequest {
    uuid: string;
    category: ProjectContactCategory;
    contact_uuid: string;
}

export interface AddProjectContactInternalRequest {
    uuid: string;
    category: ProjectContactCategory;
    contact_uuid: string;
}

export interface GetProjectContactsCustomerRequest {
    uuid: string;
}

export interface GetProjectContactsInternalRequest {
    uuid: string;
}

export interface RemoveProjectContactCustomerRequest {
    uuid: string;
    category: ProjectContactCategory;
    contact_uuid: string;
}

export interface RemoveProjectContactInternalRequest {
    uuid: string;
    category: ProjectContactCategory;
    contact_uuid: string;
}

/**
 * 
 */
export class ProjectsApi extends runtime.BaseAPI {

    /**
     * Adds a contact to one of a projects\' contact lists
     * Adds a contact to one of a projects\' contact lists
     */
    async addProjectContactCustomerRaw(requestParameters: AddProjectContactCustomerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling addProjectContactCustomer().'
            );
        }

        if (requestParameters['category'] == null) {
            throw new runtime.RequiredError(
                'category',
                'Required parameter "category" was null or undefined when calling addProjectContactCustomer().'
            );
        }

        if (requestParameters['contact_uuid'] == null) {
            throw new runtime.RequiredError(
                'contact_uuid',
                'Required parameter "contact_uuid" was null or undefined when calling addProjectContactCustomer().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['category'] != null) {
            queryParameters['category'] = requestParameters['category'];
        }

        if (requestParameters['contact_uuid'] != null) {
            queryParameters['contact_uuid'] = requestParameters['contact_uuid'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/frontend/v1/customer/projects/{uuid}/contacts`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Adds a contact to one of a projects\' contact lists
     * Adds a contact to one of a projects\' contact lists
     */
    async addProjectContactCustomer(requestParameters: AddProjectContactCustomerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.addProjectContactCustomerRaw(requestParameters, initOverrides);
    }

    /**
     */
    async addProjectContactInternalRaw(requestParameters: AddProjectContactInternalRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling addProjectContactInternal().'
            );
        }

        if (requestParameters['category'] == null) {
            throw new runtime.RequiredError(
                'category',
                'Required parameter "category" was null or undefined when calling addProjectContactInternal().'
            );
        }

        if (requestParameters['contact_uuid'] == null) {
            throw new runtime.RequiredError(
                'contact_uuid',
                'Required parameter "contact_uuid" was null or undefined when calling addProjectContactInternal().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['category'] != null) {
            queryParameters['category'] = requestParameters['category'];
        }

        if (requestParameters['contact_uuid'] != null) {
            queryParameters['contact_uuid'] = requestParameters['contact_uuid'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/frontend/v1/internal/projects/{uuid}/contacts`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async addProjectContactInternal(requestParameters: AddProjectContactInternalRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.addProjectContactInternalRaw(requestParameters, initOverrides);
    }

    /**
     * Retrieve all projects for all customers the user is associated with
     * Retrieve all projects for all customers the user is associated with
     */
    async getAllProjectsCustomerRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListForCustomerSimpleProject>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/frontend/v1/customer/projects`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Retrieve all projects for all customers the user is associated with
     * Retrieve all projects for all customers the user is associated with
     */
    async getAllProjectsCustomer(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListForCustomerSimpleProject> {
        const response = await this.getAllProjectsCustomerRaw(initOverrides);
        return await response.value();
    }

    /**
     * Retrieves a projects contacts
     * Retrieves a projects contacts
     */
    async getProjectContactsCustomerRaw(requestParameters: GetProjectContactsCustomerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FullProjectContacts>> {
        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling getProjectContactsCustomer().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/frontend/v1/customer/projects/{uuid}/contacts`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Retrieves a projects contacts
     * Retrieves a projects contacts
     */
    async getProjectContactsCustomer(requestParameters: GetProjectContactsCustomerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FullProjectContacts> {
        const response = await this.getProjectContactsCustomerRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Retrieves a projects contacts
     * Retrieves a projects contacts
     */
    async getProjectContactsInternalRaw(requestParameters: GetProjectContactsInternalRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FullProjectContacts>> {
        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling getProjectContactsInternal().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/frontend/v1/internal/projects/{uuid}/contacts`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Retrieves a projects contacts
     * Retrieves a projects contacts
     */
    async getProjectContactsInternal(requestParameters: GetProjectContactsInternalRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FullProjectContacts> {
        const response = await this.getProjectContactsInternalRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Removes a contact from one of a projects\' contact lists
     * Removes a contact from one of a projects\' contact lists
     */
    async removeProjectContactCustomerRaw(requestParameters: RemoveProjectContactCustomerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling removeProjectContactCustomer().'
            );
        }

        if (requestParameters['category'] == null) {
            throw new runtime.RequiredError(
                'category',
                'Required parameter "category" was null or undefined when calling removeProjectContactCustomer().'
            );
        }

        if (requestParameters['contact_uuid'] == null) {
            throw new runtime.RequiredError(
                'contact_uuid',
                'Required parameter "contact_uuid" was null or undefined when calling removeProjectContactCustomer().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['category'] != null) {
            queryParameters['category'] = requestParameters['category'];
        }

        if (requestParameters['contact_uuid'] != null) {
            queryParameters['contact_uuid'] = requestParameters['contact_uuid'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/frontend/v1/customer/projects/{uuid}/contacts`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Removes a contact from one of a projects\' contact lists
     * Removes a contact from one of a projects\' contact lists
     */
    async removeProjectContactCustomer(requestParameters: RemoveProjectContactCustomerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.removeProjectContactCustomerRaw(requestParameters, initOverrides);
    }

    /**
     */
    async removeProjectContactInternalRaw(requestParameters: RemoveProjectContactInternalRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling removeProjectContactInternal().'
            );
        }

        if (requestParameters['category'] == null) {
            throw new runtime.RequiredError(
                'category',
                'Required parameter "category" was null or undefined when calling removeProjectContactInternal().'
            );
        }

        if (requestParameters['contact_uuid'] == null) {
            throw new runtime.RequiredError(
                'contact_uuid',
                'Required parameter "contact_uuid" was null or undefined when calling removeProjectContactInternal().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['category'] != null) {
            queryParameters['category'] = requestParameters['category'];
        }

        if (requestParameters['contact_uuid'] != null) {
            queryParameters['contact_uuid'] = requestParameters['contact_uuid'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/frontend/v1/internal/projects/{uuid}/contacts`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async removeProjectContactInternal(requestParameters: RemoveProjectContactInternalRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.removeProjectContactInternalRaw(requestParameters, initOverrides);
    }

}
