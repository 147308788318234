/* tslint:disable */
/* eslint-disable */
/**
 * Unnamed API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ApiErrorResponse,
  CreateQuestionChoice,
  CreateQuestionRequest,
  FormResultForNullAndDeleteQuestionChoiceErrors,
  FormResultForNullAndDeleteQuestionErrors,
  FormResultForNullAndUpdateQuestionErrors,
  FormResultForSingleUuidAndCreateQuestionErrors,
  ListForFullQuestion,
  OptionalForFullQuestion,
  SingleUuid,
  UpdateQuestionChoiceRequest,
  UpdateQuestionRequest,
} from '../models/index';

export interface CreateQuestionOperationRequest {
    CreateQuestionRequest: CreateQuestionRequest;
}

export interface CreateQuestionChoiceRequest {
    uuid: string;
    CreateQuestionChoice: CreateQuestionChoice;
}

export interface DeleteQuestionRequest {
    uuid: string;
}

export interface DeleteQuestionChoiceRequest {
    uuid: string;
}

export interface GetQuestionRequest {
    uuid: string;
}

export interface UpdateQuestionOperationRequest {
    uuid: string;
    UpdateQuestionRequest: UpdateQuestionRequest;
}

export interface UpdateQuestionChoiceOperationRequest {
    uuid: string;
    UpdateQuestionChoiceRequest: UpdateQuestionChoiceRequest;
}

/**
 * 
 */
export class QuestionnaireQuestionsApi extends runtime.BaseAPI {

    /**
     * Creates a new question
     * Creates a new question
     */
    async createQuestionRaw(requestParameters: CreateQuestionOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FormResultForSingleUuidAndCreateQuestionErrors>> {
        if (requestParameters['CreateQuestionRequest'] == null) {
            throw new runtime.RequiredError(
                'CreateQuestionRequest',
                'Required parameter "CreateQuestionRequest" was null or undefined when calling createQuestion().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/frontend/v1/internal/questionnaire/questions`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['CreateQuestionRequest'],
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Creates a new question
     * Creates a new question
     */
    async createQuestion(requestParameters: CreateQuestionOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FormResultForSingleUuidAndCreateQuestionErrors> {
        const response = await this.createQuestionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Creates a new question choice for a single or multiple choice question
     * Creates a new question choice for a single or multiple choice question
     */
    async createQuestionChoiceRaw(requestParameters: CreateQuestionChoiceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SingleUuid>> {
        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling createQuestionChoice().'
            );
        }

        if (requestParameters['CreateQuestionChoice'] == null) {
            throw new runtime.RequiredError(
                'CreateQuestionChoice',
                'Required parameter "CreateQuestionChoice" was null or undefined when calling createQuestionChoice().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/frontend/v1/internal/questionnaire/questions/{uuid}/choices`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['CreateQuestionChoice'],
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Creates a new question choice for a single or multiple choice question
     * Creates a new question choice for a single or multiple choice question
     */
    async createQuestionChoice(requestParameters: CreateQuestionChoiceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SingleUuid> {
        const response = await this.createQuestionChoiceRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Deletes a single unused question
     * Deletes a single unused question
     */
    async deleteQuestionRaw(requestParameters: DeleteQuestionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FormResultForNullAndDeleteQuestionErrors>> {
        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling deleteQuestion().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/frontend/v1/internal/questionnaire/questions/{uuid}`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Deletes a single unused question
     * Deletes a single unused question
     */
    async deleteQuestion(requestParameters: DeleteQuestionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FormResultForNullAndDeleteQuestionErrors> {
        const response = await this.deleteQuestionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Deletes an existing question choice
     * Deletes an existing question choice
     */
    async deleteQuestionChoiceRaw(requestParameters: DeleteQuestionChoiceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FormResultForNullAndDeleteQuestionChoiceErrors>> {
        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling deleteQuestionChoice().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/frontend/v1/internal/questionnaire/questions/choices/{uuid}`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Deletes an existing question choice
     * Deletes an existing question choice
     */
    async deleteQuestionChoice(requestParameters: DeleteQuestionChoiceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FormResultForNullAndDeleteQuestionChoiceErrors> {
        const response = await this.deleteQuestionChoiceRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Retrieves all existing questions
     * Retrieves all existing questions
     */
    async getAllQuestionsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListForFullQuestion>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/frontend/v1/internal/questionnaire/questions`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Retrieves all existing questions
     * Retrieves all existing questions
     */
    async getAllQuestions(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListForFullQuestion> {
        const response = await this.getAllQuestionsRaw(initOverrides);
        return await response.value();
    }

    /**
     * Retrieves a single question
     * Retrieves a single question
     */
    async getQuestionRaw(requestParameters: GetQuestionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OptionalForFullQuestion>> {
        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling getQuestion().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/frontend/v1/internal/questionnaire/questions/{uuid}`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Retrieves a single question
     * Retrieves a single question
     */
    async getQuestion(requestParameters: GetQuestionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OptionalForFullQuestion> {
        const response = await this.getQuestionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Updates an existing question  Updating a question\'s kind is not supported yet. Updating a question\'s choices is done through dedicated endpoints.  To update the order of choices pass a list of their uuids in the desired order. This fill fail if uuids are missing, duplicated or unknown.
     * Updates an existing question
     */
    async updateQuestionRaw(requestParameters: UpdateQuestionOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FormResultForNullAndUpdateQuestionErrors>> {
        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling updateQuestion().'
            );
        }

        if (requestParameters['UpdateQuestionRequest'] == null) {
            throw new runtime.RequiredError(
                'UpdateQuestionRequest',
                'Required parameter "UpdateQuestionRequest" was null or undefined when calling updateQuestion().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/frontend/v1/internal/questionnaire/questions/{uuid}`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['UpdateQuestionRequest'],
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Updates an existing question  Updating a question\'s kind is not supported yet. Updating a question\'s choices is done through dedicated endpoints.  To update the order of choices pass a list of their uuids in the desired order. This fill fail if uuids are missing, duplicated or unknown.
     * Updates an existing question
     */
    async updateQuestion(requestParameters: UpdateQuestionOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FormResultForNullAndUpdateQuestionErrors> {
        const response = await this.updateQuestionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Creates an existing question choice
     * Creates an existing question choice
     */
    async updateQuestionChoiceRaw(requestParameters: UpdateQuestionChoiceOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling updateQuestionChoice().'
            );
        }

        if (requestParameters['UpdateQuestionChoiceRequest'] == null) {
            throw new runtime.RequiredError(
                'UpdateQuestionChoiceRequest',
                'Required parameter "UpdateQuestionChoiceRequest" was null or undefined when calling updateQuestionChoice().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/frontend/v1/internal/questionnaire/questions/choices/{uuid}`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['UpdateQuestionChoiceRequest'],
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Creates an existing question choice
     * Creates an existing question choice
     */
    async updateQuestionChoice(requestParameters: UpdateQuestionChoiceOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateQuestionChoiceRaw(requestParameters, initOverrides);
    }

}
