import { createFileRoute, Outlet } from "@tanstack/react-router";

import React from "react";
import { useTranslation } from "react-i18next";
import { TabMenu, Tab } from "../../../components/base/tab-menu";
import TabLayout from "../../../components/base/tab-layout";
import { Badge } from "../../../components/base/badge";
import INTERNAL_TASK_CONTEXT from "../../../context/tasks-internal";

/**
 * The properties for {@link InternalTasks}
 */
export type InternalTasksProps = {};

/**
 * The overview for internal users of their tasks
 */
export default function InternalTasks(props: InternalTasksProps) {
    const [t] = useTranslation();
    const tasks = React.useContext(INTERNAL_TASK_CONTEXT);

    return (
        <TabLayout
            heading={t("label.tasks")}
            tabs={
                <TabMenu>
                    <Tab href={"/i/tasks/my"}>
                        <span>{t("label.my-tasks")}</span>
                        <Badge color={"blue"}>{tasks.tasks.my_tasks.length}</Badge>
                    </Tab>
                    <Tab href={"/i/tasks/pool"}>
                        <span>{t("label.pool-tasks")}</span>
                        <Badge color={"blue"}>{tasks.tasks.pool_tasks.length}</Badge>
                    </Tab>
                </TabMenu>
            }
        >
            <Outlet />
        </TabLayout>
    );
}

export const Route = createFileRoute("/_internal/i/_tasks")({
    component: InternalTasks,
});
