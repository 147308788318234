import { createFileRoute } from "@tanstack/react-router";
import { useTranslation } from "react-i18next";
import React from "react";
import PENTEST_PROJECT_CONTEXT from "../../../../../../../context/pentest-project-internal";
import { Button } from "../../../../../../../components/base/button";
import { Table, TableBody, TableCell, TableRow } from "../../../../../../../components/base/table";
import { CustomerFullPentestProject } from "../../../../../../../api/generated";

/**
 * The properties for {@link PentestProjectFiles}
 */
export type PentestProjectFilesProps = {};

/**
 * Files of the pentest project
 */
export default function PentestProjectFiles(props: PentestProjectFilesProps) {
    const [t] = useTranslation();
    const { obj } = React.useContext(PENTEST_PROJECT_CONTEXT);
    const project = obj as CustomerFullPentestProject;

    return (
        <Table>
            <TableBody>
                <TableRow>
                    <TableCell>{t("pentest.label.report")}</TableCell>
                    <TableCell>
                        {!project.report ? (
                            <small>{t("pentest.description.not-uploaded")}</small>
                        ) : (
                            <Button
                                target={"_blank"}
                                href={`/api/frontend/v1/customer/customers/files/${project.report.uuid}`}
                                download={project.report.name}
                            >
                                {t("pentest.button.download")}
                            </Button>
                        )}
                    </TableCell>
                </TableRow>
            </TableBody>
        </Table>
    );
}

export const Route = createFileRoute("/_customer/c/projects/pentests/$projectId/_pentest/files")({
    component: PentestProjectFiles,
});
