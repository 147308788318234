import { useTranslation } from "react-i18next";
import { Checkbox, CheckboxField, CheckboxGroup } from "src/components/base/checkbox";
import { Label, RequiredLabel } from "src/components/base/fieldset";
import React from "react";

/**
 * The properties for {@link MeetingAttendees}
 */
export type MeetingAttendeesProps = {
    /** if meeting should be skipped */
    skip: boolean;
    /** if tl takes part in the meeting */
    technical_lead: boolean;
    /** if ta takes part in the meeting */
    technical_assistant: boolean;
    /** if ma takes part in the meeting */
    management_assistant: boolean;
    /** if pm takes part in the meeting */
    project_manager: boolean;
    /** Callback when checkbox changed */
    onChange: (
        technical_lead: boolean,
        technical_assistant: boolean,
        management_assistant: boolean,
        project_manager: boolean,
    ) => void;
};

/**
 * Checkboxes for meeting attendees for enter-[meeting]-results
 */
export default function MeetingAttendees(props: MeetingAttendeesProps) {
    const [tg] = useTranslation();
    const { skip, onChange, technical_assistant, management_assistant, technical_lead, project_manager } = props;

    return (
        <CheckboxGroup>
            <RequiredLabel className={"font-medium"}>{tg("label.attendees")}</RequiredLabel>
            <CheckboxField disabled={skip}>
                <Label>{tg("label.project-manager")}</Label>
                <Checkbox
                    checked={project_manager}
                    onChange={(e) => {
                        onChange(technical_lead, technical_assistant, management_assistant, e);
                    }}
                />
            </CheckboxField>
            <CheckboxField disabled={skip}>
                <Label>{tg("label.mgmt-assistant")}</Label>
                <Checkbox
                    checked={management_assistant}
                    onChange={(e) => {
                        onChange(technical_lead, technical_assistant, e, project_manager);
                    }}
                />
            </CheckboxField>
            <CheckboxField disabled={skip}>
                <Label>{tg("label.tech-lead")}</Label>
                <Checkbox
                    checked={technical_lead}
                    onChange={(e) => {
                        onChange(e, technical_assistant, management_assistant, project_manager);
                    }}
                />
            </CheckboxField>
            <CheckboxField disabled={skip}>
                <Label>{tg("label.tech-assistants")}</Label>
                <Checkbox
                    checked={technical_assistant}
                    onChange={(e) => {
                        onChange(technical_lead, e, management_assistant, project_manager);
                    }}
                />
            </CheckboxField>
        </CheckboxGroup>
    );
}
