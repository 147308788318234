import { createFileRoute, Navigate } from "@tanstack/react-router";

import React from "react";

export const Route = createFileRoute("/_internal/i/_tasks/tasks/")({
    /**
     * Redirect to my tasks
     *
     * @returns Redirect
     */
    component: () => <Navigate to={"/i/tasks/my"} />,
});
