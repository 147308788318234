/* tslint:disable */
/* eslint-disable */
/**
 * Unnamed API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AddPentestTargetRequest,
  ApiErrorResponse,
  FormResultForSingleUuidAndAddPentestTargetErrors,
  OptionalForFullPentestTargets,
  ReviewPentestTargetsResult,
} from '../models/index';

export interface AddPentestTargetOperationRequest {
    uuid: string;
    AddPentestTargetRequest: AddPentestTargetRequest;
}

export interface ApprovePentestTargetsRequest {
    uuid: string;
}

export interface GetPentestTargetsCustomerRequest {
    uuid: string;
}

export interface GetPentestTargetsInternalRequest {
    uuid: string;
}

export interface RemovePentestTargetRequest {
    uuid: string;
}

export interface SubmitPentestTargetsRequest {
    uuid: string;
}

export interface UnsubmitPentestTargetsRequest {
    uuid: string;
}

/**
 * 
 */
export class PentestTargetsApi extends runtime.BaseAPI {

    /**
     * Adds a new pentest target  # Bad Request If the pentest targets don\'t exist. If the targets have already been submitted.
     * Adds a new pentest target
     */
    async addPentestTargetRaw(requestParameters: AddPentestTargetOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FormResultForSingleUuidAndAddPentestTargetErrors>> {
        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling addPentestTarget().'
            );
        }

        if (requestParameters['AddPentestTargetRequest'] == null) {
            throw new runtime.RequiredError(
                'AddPentestTargetRequest',
                'Required parameter "AddPentestTargetRequest" was null or undefined when calling addPentestTarget().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/frontend/v1/customer/pentest/projects/targets/{uuid}`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['AddPentestTargetRequest'],
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Adds a new pentest target  # Bad Request If the pentest targets don\'t exist. If the targets have already been submitted.
     * Adds a new pentest target
     */
    async addPentestTarget(requestParameters: AddPentestTargetOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FormResultForSingleUuidAndAddPentestTargetErrors> {
        const response = await this.addPentestTargetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Approves a pentest project\'s targets  # Bad Request If the project doesn\'t exist or is of another kind than Pentest.
     * Approves a pentest project\'s targets
     */
    async approvePentestTargetsRaw(requestParameters: ApprovePentestTargetsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReviewPentestTargetsResult>> {
        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling approvePentestTargets().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/frontend/v1/internal/pentest/projects/targets/{uuid}/approve`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Approves a pentest project\'s targets  # Bad Request If the project doesn\'t exist or is of another kind than Pentest.
     * Approves a pentest project\'s targets
     */
    async approvePentestTargets(requestParameters: ApprovePentestTargetsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReviewPentestTargetsResult> {
        const response = await this.approvePentestTargetsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Retrieves a pentest targets form
     * Retrieves a pentest targets form
     */
    async getPentestTargetsCustomerRaw(requestParameters: GetPentestTargetsCustomerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OptionalForFullPentestTargets>> {
        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling getPentestTargetsCustomer().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/frontend/v1/customer/pentest/projects/targets/{uuid}`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Retrieves a pentest targets form
     * Retrieves a pentest targets form
     */
    async getPentestTargetsCustomer(requestParameters: GetPentestTargetsCustomerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OptionalForFullPentestTargets> {
        const response = await this.getPentestTargetsCustomerRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Retrieves a pentest project\'s targets  # `Null` If the project doesn\'t exist or is of another kind than Pentest.
     * Retrieves a pentest project\'s targets
     */
    async getPentestTargetsInternalRaw(requestParameters: GetPentestTargetsInternalRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OptionalForFullPentestTargets>> {
        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling getPentestTargetsInternal().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/frontend/v1/internal/pentest/projects/targets/{uuid}`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Retrieves a pentest project\'s targets  # `Null` If the project doesn\'t exist or is of another kind than Pentest.
     * Retrieves a pentest project\'s targets
     */
    async getPentestTargetsInternal(requestParameters: GetPentestTargetsInternalRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OptionalForFullPentestTargets> {
        const response = await this.getPentestTargetsInternalRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Removes an existing pentest target  # Bad Request If the pentest targets don\'t exist. If the targets have already been submitted.
     * Removes an existing pentest target
     */
    async removePentestTargetRaw(requestParameters: RemovePentestTargetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling removePentestTarget().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/frontend/v1/customer/pentest/projects/targets/{uuid}`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Removes an existing pentest target  # Bad Request If the pentest targets don\'t exist. If the targets have already been submitted.
     * Removes an existing pentest target
     */
    async removePentestTarget(requestParameters: RemovePentestTargetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.removePentestTargetRaw(requestParameters, initOverrides);
    }

    /**
     * Submits a pentest targets form  This operation marks the targets as final and notifies a project manager to approve them. The targets can be updated anymore, unless they are rejected by a project manager.  # Bad Request If the pentest targets don\'t exist. If the targets have already been submitted.
     * Submits a pentest targets form
     */
    async submitPentestTargetsRaw(requestParameters: SubmitPentestTargetsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling submitPentestTargets().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/frontend/v1/customer/pentest/projects/targets/{uuid}/submit`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Submits a pentest targets form  This operation marks the targets as final and notifies a project manager to approve them. The targets can be updated anymore, unless they are rejected by a project manager.  # Bad Request If the pentest targets don\'t exist. If the targets have already been submitted.
     * Submits a pentest targets form
     */
    async submitPentestTargets(requestParameters: SubmitPentestTargetsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.submitPentestTargetsRaw(requestParameters, initOverrides);
    }

    /**
     * Removes a pentest project\'s targets\' submit flag allowing the customer to edit them again  # Bad Request If the project doesn\'t exist or is of another kind than Pentest.
     * Removes a pentest project\'s targets\' submit flag allowing the customer to edit them again
     */
    async unsubmitPentestTargetsRaw(requestParameters: UnsubmitPentestTargetsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReviewPentestTargetsResult>> {
        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling unsubmitPentestTargets().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/frontend/v1/internal/pentest/projects/targets/{uuid}/unsubmit`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Removes a pentest project\'s targets\' submit flag allowing the customer to edit them again  # Bad Request If the project doesn\'t exist or is of another kind than Pentest.
     * Removes a pentest project\'s targets\' submit flag allowing the customer to edit them again
     */
    async unsubmitPentestTargets(requestParameters: UnsubmitPentestTargetsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReviewPentestTargetsResult> {
        const response = await this.unsubmitPentestTargetsRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
