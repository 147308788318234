import { createFileRoute, Navigate } from "@tanstack/react-router";

import React from "react";

/**
 * The properties for {@link ReportRedirect}
 */
export type ReportRedirectProps = {};

/**
 * Redirect to a pentest report
 */
function ReportRedirect(props: ReportRedirectProps) {
    const { projectId } = Route.useParams();

    return <Navigate to={"/c/projects/pentests/$projectId/files"} params={{ projectId }} />;
}

export const Route = createFileRoute("/_links/links/_c/c/pentests/$projectId")({
    component: ReportRedirect,
});
