import { createFileRoute } from "@tanstack/react-router";
import { useTranslation } from "react-i18next";
import HeadingLayout from "src/components/base/heading-layout";
import { Field, FieldGroup, Label } from "src/components/base/fieldset";
import { Button } from "src/components/base/button";
import { Api } from "src/api/api";
import { toast } from "react-toastify";
import { CheckCircleIcon } from "@heroicons/react/16/solid";
import React, { useEffect } from "react";
import { MeetingAttendee } from "src/api/generated";
import { Fieldset } from "@headlessui/react";
import { Subheading } from "src/components/base/heading";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "src/components/base/table";
import { Text } from "src/components/base/text";

/**
 * The properties for {@link InviteDialog}
 */
export type InviteDialogProps = {};

/**
 * The form to see details for dialog invitation and a check button
 */
function InviteDialog(props: InviteDialogProps) {
    const [t] = useTranslation();
    const { taskId } = Route.useParams();
    const [date, setDate] = React.useState("");
    const [attendees, setAttendees] = React.useState<Array<MeetingAttendee>>([]);

    useEffect(() => {
        Api.internal.projects.pentest.getInviteMeeting(taskId).then((res) => {
            res.match(
                (res) => {
                    setDate(res.date);
                    setAttendees(res.attendees);
                },
                (err) => toast.error(err.message),
            );
        });
    }, []);

    return (
        <HeadingLayout heading={t("tasks.label.invite-it-dialog")}>
            <Fieldset>
                <FieldGroup>
                    <Text>
                        {t("internal.invite-meeting.description", {
                            date: new Date(date).toLocaleDateString(),
                            time: new Date(date).toLocaleTimeString(),
                        })}
                    </Text>

                    <Subheading>{t("internal.invite-meeting.heading")}</Subheading>

                    <Field>
                        <Subheading>{t("internal.invite-meeting.label.intern")}</Subheading>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableHeader>{t("label.position")}</TableHeader>
                                    <TableHeader>{t("label.name")}</TableHeader>
                                    <TableHeader>{t("label.mail")}</TableHeader>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {attendees.map((attendee) =>
                                    attendee.role.role === "ProjectManager" ? (
                                        <TableRow key={attendee.mail}>
                                            <TableCell>{t("label.project-manager")}</TableCell>
                                            <TableCell>{attendee.name}</TableCell>
                                            <TableCell>{attendee.mail}</TableCell>
                                        </TableRow>
                                    ) : undefined,
                                )}
                                {attendees.map((attendee) =>
                                    attendee.role.role === "ManagementAssistant" ? (
                                        <TableRow key={attendee.mail}>
                                            <TableCell>{t("label.mgmt-assistant")}</TableCell>
                                            <TableCell>{attendee.name}</TableCell>
                                            <TableCell>{attendee.mail}</TableCell>
                                        </TableRow>
                                    ) : undefined,
                                )}
                                {attendees.map((attendee) =>
                                    attendee.role.role === "TechnicalLead" ? (
                                        <TableRow key={attendee.mail}>
                                            <TableCell>{t("label.tech-lead")}</TableCell>
                                            <TableCell>{attendee.name}</TableCell>
                                            <TableCell>{attendee.mail}</TableCell>
                                        </TableRow>
                                    ) : undefined,
                                )}
                                {attendees.map((attendee) =>
                                    attendee.role.role === "TechnicalAssistant" ? (
                                        <TableRow key={attendee.mail}>
                                            <TableCell>{t("label.tech-assistant")}</TableCell>
                                            <TableCell>{attendee.name}</TableCell>
                                            <TableCell>{attendee.mail}</TableCell>
                                        </TableRow>
                                    ) : undefined,
                                )}
                            </TableBody>
                        </Table>
                    </Field>

                    <Field>
                        <Label>{t("internal.invite-meeting.label.customer")}</Label>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableHeader>{t("label.contact")}</TableHeader>
                                    <TableHeader>{t("label.name")}</TableHeader>
                                    <TableHeader>{t("label.mail")}</TableHeader>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {attendees
                                    .filter((attendee) => {
                                        return attendee.role.role === "Customer" && attendee.role.technical;
                                    })
                                    .map((attendee) => (
                                        <TableRow key={attendee.mail}>
                                            <TableCell>{t("label.contact-it")}</TableCell>
                                            <TableCell>{attendee.name}</TableCell>
                                            <TableCell>{attendee.mail}</TableCell>
                                        </TableRow>
                                    ))}
                                {attendees
                                    .filter((attendee) => {
                                        return attendee.role.role === "Customer" && attendee.role.management;
                                    })
                                    .map((attendee) => (
                                        <TableRow key={attendee.mail}>
                                            <TableCell>{t("label.contact-management")}</TableCell>
                                            <TableCell>{attendee.name}</TableCell>
                                            <TableCell>{attendee.mail}</TableCell>
                                        </TableRow>
                                    ))}
                                {attendees
                                    .filter((attendee) => {
                                        return attendee.role.role === "Customer" && attendee.role.cc;
                                    })
                                    .map((attendee) => (
                                        <TableRow key={attendee.mail}>
                                            <TableCell>{t("label.contact-cc")}</TableCell>
                                            <TableCell>{attendee.name}</TableCell>
                                            <TableCell>{attendee.mail}</TableCell>
                                        </TableRow>
                                    ))}
                            </TableBody>
                        </Table>
                    </Field>

                    <div className={"flex items-center gap-3"}>
                        <Label>{t("internal.invite-meeting.label.invite-sent")}</Label>
                        <Button
                            onClick={async () => {
                                const res = await Api.internal.tasks.finishExternalV0(taskId);
                                if (res.isOk) {
                                    window.history.back();
                                } else if (res.isErr) {
                                    toast.error(res.err.message);
                                }
                            }}
                        >
                            <CheckCircleIcon />
                        </Button>
                    </div>
                </FieldGroup>
            </Fieldset>
        </HeadingLayout>
    );
}

export const Route = createFileRoute("/_internal/i/forms/tasks/$taskId/invite-dialog-v0")({
    component: InviteDialog,
});
