/* tslint:disable */
/* eslint-disable */
/**
 * Unnamed API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ApiErrorResponse,
  CreateCustomerAddressRequest,
  SingleUuid,
  UpdateCustomerAddressRequest,
} from '../models/index';

export interface CreateCustomerAddressOperationRequest {
    uuid: string;
    CreateCustomerAddressRequest: CreateCustomerAddressRequest;
}

export interface DeleteCustomerAddressRequest {
    uuid: string;
}

export interface UpdateCustomerAddressOperationRequest {
    uuid: string;
    UpdateCustomerAddressRequest: UpdateCustomerAddressRequest;
}

/**
 * 
 */
export class CustomerAddressesApi extends runtime.BaseAPI {

    /**
     * Creates a new customer address
     * Creates a new customer address
     */
    async createCustomerAddressRaw(requestParameters: CreateCustomerAddressOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SingleUuid>> {
        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling createCustomerAddress().'
            );
        }

        if (requestParameters['CreateCustomerAddressRequest'] == null) {
            throw new runtime.RequiredError(
                'CreateCustomerAddressRequest',
                'Required parameter "CreateCustomerAddressRequest" was null or undefined when calling createCustomerAddress().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/frontend/v1/internal/customers/{uuid}/addresses`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['CreateCustomerAddressRequest'],
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Creates a new customer address
     * Creates a new customer address
     */
    async createCustomerAddress(requestParameters: CreateCustomerAddressOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SingleUuid> {
        const response = await this.createCustomerAddressRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Deletes a customer address
     * Deletes a customer address
     */
    async deleteCustomerAddressRaw(requestParameters: DeleteCustomerAddressRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling deleteCustomerAddress().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/frontend/v1/internal/customers/addresses/{uuid}`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Deletes a customer address
     * Deletes a customer address
     */
    async deleteCustomerAddress(requestParameters: DeleteCustomerAddressRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteCustomerAddressRaw(requestParameters, initOverrides);
    }

    /**
     * Updates an existing customer address
     * Updates an existing customer address
     */
    async updateCustomerAddressRaw(requestParameters: UpdateCustomerAddressOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling updateCustomerAddress().'
            );
        }

        if (requestParameters['UpdateCustomerAddressRequest'] == null) {
            throw new runtime.RequiredError(
                'UpdateCustomerAddressRequest',
                'Required parameter "UpdateCustomerAddressRequest" was null or undefined when calling updateCustomerAddress().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/frontend/v1/internal/customers/addresses/{uuid}`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['UpdateCustomerAddressRequest'],
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Updates an existing customer address
     * Updates an existing customer address
     */
    async updateCustomerAddress(requestParameters: UpdateCustomerAddressOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateCustomerAddressRaw(requestParameters, initOverrides);
    }

}
