import { createFileRoute, Navigate } from "@tanstack/react-router";

import React from "react";

/**
 * The properties for {@link PentestProjectRedirect}
 */
export type PentestProjectRedirectProps = {};

/**
 * Redirect to the general pentest project overview
 */
function PentestProjectRedirect(props: PentestProjectRedirectProps) {
    const { projectId } = Route.useParams();
    return <Navigate to={"/i/pentests/$projectId/general"} params={{ projectId }} />;
}

export const Route = createFileRoute("/_links/links/_i/i/pentests/$projectId/")({
    component: PentestProjectRedirect,
});
