/* tslint:disable */
/* eslint-disable */
/**
 * Unnamed API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ApiErrorResponse,
  CreateTotpRequest,
  CreateUserInviteRequest,
  CreateWebauthnRequest,
  FormResultForNullAndCreateTotpErrors,
  FormResultForNullAndDeletePasswordErrors,
  FormResultForNullAndDeleteWebauthnKeyErrors,
  FormResultForNullAndNull,
  FormResultForSimpleUserInviteAndCreateUserInviteErrors,
  FormResultForWebAuthnSchemaAndCreateWebauthnErrors,
  ListForSimpleUserInvite,
  PrepareUserInviteResponse,
  SetPasswordRequest,
  WebAuthnRegisterResult,
} from '../models/index';

export interface CompleteInviteWebauthnRequest {
    body: any | null;
}

export interface CreateTotpKeyInviteRequest {
    CreateTotpRequest: CreateTotpRequest;
}

export interface CreateUserInviteOperationRequest {
    CreateUserInviteRequest: CreateUserInviteRequest;
}

export interface CreateWebauthnKeyInviteRequest {
    CreateWebauthnRequest: CreateWebauthnRequest;
}

export interface DeleteTotpKeyInviteRequest {
    uuid: string;
}

export interface DeleteUserInviteRequest {
    uuid: string;
}

export interface DeleteWebauthnKeyInviteRequest {
    uuid: string;
}

export interface PrepareUserInviteRequest {
    uuid: string;
}

export interface SetPasswordInviteRequest {
    SetPasswordRequest: SetPasswordRequest;
}

/**
 * 
 */
export class UserInvitesApi extends runtime.BaseAPI {

    /**
     * Accepts an invitation with the authentication methods provided after calling prepare.  This may only be called after prepare.
     * Accepts an invitation with the authentication methods provided after calling prepare.
     */
    async acceptInviteRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FormResultForNullAndNull>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/frontend/v1/common/user-invites/accept`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Accepts an invitation with the authentication methods provided after calling prepare.  This may only be called after prepare.
     * Accepts an invitation with the authentication methods provided after calling prepare.
     */
    async acceptInvite(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FormResultForNullAndNull> {
        const response = await this.acceptInviteRaw(initOverrides);
        return await response.value();
    }

    /**
     * Complete the webauthn challenge for adding a new key  This may only be called between prepare and submit.
     * Complete the webauthn challenge for adding a new key
     */
    async completeInviteWebauthnRaw(requestParameters: CompleteInviteWebauthnRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<WebAuthnRegisterResult>> {
        if (requestParameters['body'] == null) {
            throw new runtime.RequiredError(
                'body',
                'Required parameter "body" was null or undefined when calling completeInviteWebauthn().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/frontend/v1/common/user-invites/complete-webauthn`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['body'] as any,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Complete the webauthn challenge for adding a new key  This may only be called between prepare and submit.
     * Complete the webauthn challenge for adding a new key
     */
    async completeInviteWebauthn(requestParameters: CompleteInviteWebauthnRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<WebAuthnRegisterResult> {
        const response = await this.completeInviteWebauthnRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Adds a TOTP key  This may only be called between prepare and submit.
     * Adds a TOTP key
     */
    async createTotpKeyInviteRaw(requestParameters: CreateTotpKeyInviteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FormResultForNullAndCreateTotpErrors>> {
        if (requestParameters['CreateTotpRequest'] == null) {
            throw new runtime.RequiredError(
                'CreateTotpRequest',
                'Required parameter "CreateTotpRequest" was null or undefined when calling createTotpKeyInvite().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/frontend/v1/common/user-invites/totp`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['CreateTotpRequest'],
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Adds a TOTP key  This may only be called between prepare and submit.
     * Adds a TOTP key
     */
    async createTotpKeyInvite(requestParameters: CreateTotpKeyInviteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FormResultForNullAndCreateTotpErrors> {
        const response = await this.createTotpKeyInviteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Invite a new (local) user
     * Invite a new (local) user
     */
    async createUserInviteRaw(requestParameters: CreateUserInviteOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FormResultForSimpleUserInviteAndCreateUserInviteErrors>> {
        if (requestParameters['CreateUserInviteRequest'] == null) {
            throw new runtime.RequiredError(
                'CreateUserInviteRequest',
                'Required parameter "CreateUserInviteRequest" was null or undefined when calling createUserInvite().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/frontend/v1/admin/user-invites`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['CreateUserInviteRequest'],
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Invite a new (local) user
     * Invite a new (local) user
     */
    async createUserInvite(requestParameters: CreateUserInviteOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FormResultForSimpleUserInviteAndCreateUserInviteErrors> {
        const response = await this.createUserInviteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Adds a webauthn key  This may only be called between prepare and submit.
     * Adds a webauthn key
     */
    async createWebauthnKeyInviteRaw(requestParameters: CreateWebauthnKeyInviteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FormResultForWebAuthnSchemaAndCreateWebauthnErrors>> {
        if (requestParameters['CreateWebauthnRequest'] == null) {
            throw new runtime.RequiredError(
                'CreateWebauthnRequest',
                'Required parameter "CreateWebauthnRequest" was null or undefined when calling createWebauthnKeyInvite().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/frontend/v1/common/user-invites/webauthn`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['CreateWebauthnRequest'],
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Adds a webauthn key  This may only be called between prepare and submit.
     * Adds a webauthn key
     */
    async createWebauthnKeyInvite(requestParameters: CreateWebauthnKeyInviteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FormResultForWebAuthnSchemaAndCreateWebauthnErrors> {
        const response = await this.createWebauthnKeyInviteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Deletes an already set password (and all MFA keys!)  This may only be called between prepare and submit.
     * Deletes an already set password (and all MFA keys!)
     */
    async deletePasswordInviteRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FormResultForNullAndDeletePasswordErrors>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/frontend/v1/common/user-invites/password`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Deletes an already set password (and all MFA keys!)  This may only be called between prepare and submit.
     * Deletes an already set password (and all MFA keys!)
     */
    async deletePasswordInvite(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FormResultForNullAndDeletePasswordErrors> {
        const response = await this.deletePasswordInviteRaw(initOverrides);
        return await response.value();
    }

    /**
     * Removes a TOTP key  This may only be called between prepare and submit.
     * Removes a TOTP key
     */
    async deleteTotpKeyInviteRaw(requestParameters: DeleteTotpKeyInviteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling deleteTotpKeyInvite().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/frontend/v1/common/user-invites/totp/{uuid}`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Removes a TOTP key  This may only be called between prepare and submit.
     * Removes a TOTP key
     */
    async deleteTotpKeyInvite(requestParameters: DeleteTotpKeyInviteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteTotpKeyInviteRaw(requestParameters, initOverrides);
    }

    /**
     * Delete an outstanding invite
     * Delete an outstanding invite
     */
    async deleteUserInviteRaw(requestParameters: DeleteUserInviteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling deleteUserInvite().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/frontend/v1/admin/user-invites/{uuid}`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete an outstanding invite
     * Delete an outstanding invite
     */
    async deleteUserInvite(requestParameters: DeleteUserInviteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteUserInviteRaw(requestParameters, initOverrides);
    }

    /**
     * Removes a webauthn key  This may only be called between prepare and submit.
     * Removes a webauthn key
     */
    async deleteWebauthnKeyInviteRaw(requestParameters: DeleteWebauthnKeyInviteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FormResultForNullAndDeleteWebauthnKeyErrors>> {
        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling deleteWebauthnKeyInvite().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/frontend/v1/common/user-invites/webauthn/{uuid}`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Removes a webauthn key  This may only be called between prepare and submit.
     * Removes a webauthn key
     */
    async deleteWebauthnKeyInvite(requestParameters: DeleteWebauthnKeyInviteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FormResultForNullAndDeleteWebauthnKeyErrors> {
        const response = await this.deleteWebauthnKeyInviteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Retrieve all outstanding invites (expired or not)
     * Retrieve all outstanding invites (expired or not)
     */
    async getAllUserInvitesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListForSimpleUserInvite>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/frontend/v1/admin/user-invites`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Retrieve all outstanding invites (expired or not)
     * Retrieve all outstanding invites (expired or not)
     */
    async getAllUserInvites(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListForSimpleUserInvite> {
        const response = await this.getAllUserInvitesRaw(initOverrides);
        return await response.value();
    }

    /**
     * Gets an invitation\'s details to display to the user before accepting
     * Gets an invitation\'s details to display to the user before accepting
     */
    async prepareUserInviteRaw(requestParameters: PrepareUserInviteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PrepareUserInviteResponse>> {
        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling prepareUserInvite().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/frontend/v1/common/user-invites/{uuid}`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Gets an invitation\'s details to display to the user before accepting
     * Gets an invitation\'s details to display to the user before accepting
     */
    async prepareUserInvite(requestParameters: PrepareUserInviteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PrepareUserInviteResponse> {
        const response = await this.prepareUserInviteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Sets a new password (overwriting an already set one)  This may only be called between prepare and submit.
     * Sets a new password (overwriting an already set one)
     */
    async setPasswordInviteRaw(requestParameters: SetPasswordInviteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['SetPasswordRequest'] == null) {
            throw new runtime.RequiredError(
                'SetPasswordRequest',
                'Required parameter "SetPasswordRequest" was null or undefined when calling setPasswordInvite().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/frontend/v1/common/user-invites/password`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['SetPasswordRequest'],
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Sets a new password (overwriting an already set one)  This may only be called between prepare and submit.
     * Sets a new password (overwriting an already set one)
     */
    async setPasswordInvite(requestParameters: SetPasswordInviteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.setPasswordInviteRaw(requestParameters, initOverrides);
    }

}
