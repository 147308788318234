import { createFileRoute } from "@tanstack/react-router";

import React from "react";
import { useTranslation } from "react-i18next";
import Form from "src/components/base/form";
import { useForm } from "@tanstack/react-form";
import { ErrorMessage, Field, FieldGroup, Fieldset, Label, RequiredLabel } from "src/components/base/fieldset";
import { Button } from "src/components/base/button";
import { Input } from "src/components/base/input";
import { Api } from "src/api/api";
import { Radio, RadioField, RadioGroup } from "src/components/base/radio";
import { AnswerType } from "src/api/generated";
import { clsx } from "clsx";
import HeadingLayout from "src/components/base/heading-layout";
import { toast } from "react-toastify";

const radioClasses = clsx(
    "flex rounded-lg border-2 border-zinc-200 bg-zinc-100 p-3 dark:border-zinc-700 dark:bg-zinc-800",
    // Active
    "has-[[data-checked]]:border-blue-500",
);

/**
 * The properties for {@link CreateQuestionnaireQuestion}
 */
export type CreateQuestionnaireQuestionProps = {};

/**
 * Create a new questionnaire question
 */
export default function CreateQuestionnaireQuestion(props: CreateQuestionnaireQuestionProps) {
    const [t] = useTranslation();
    const navigate = Route.useNavigate();

    const createForm = useForm({
        defaultValues: {
            name: "",
            kind: "Info" as AnswerType,
        },
        // eslint-disable-next-line
        onSubmit: async ({ value, formApi }) => {
            (
                await Api.internal.questionnaires.questions.create({
                    name: value.name,
                    kind: value.kind,
                })
            ).match(
                (res) => {
                    if (res.result === "Ok") {
                        navigate({
                            to: "/i/questionnaires/questions/$questionId",
                            params: { questionId: res.value.uuid },
                        });
                    } else {
                        if (res.error.name) {
                            formApi.setFieldMeta("name", (meta) => {
                                meta.errors.push(t("internal.create-questionnaire-question.error.name-in-use"));
                                return meta;
                            });
                        }
                    }
                },
                (err) => toast.error(err.message),
            );
        },
    });

    return (
        <HeadingLayout heading={t("internal.create-questionnaire-question.heading.create-question")}>
            <Form onSubmit={createForm.handleSubmit} className={"max-w-lg"}>
                <Fieldset>
                    <FieldGroup>
                        <createForm.Field name={"name"}>
                            {(fieldApi) => (
                                <Field>
                                    <RequiredLabel>
                                        {t("internal.create-questionnaire-question.label.question-name")}
                                    </RequiredLabel>
                                    <Input
                                        autoFocus={true}
                                        required={true}
                                        value={fieldApi.state.value}
                                        invalid={fieldApi.state.meta.errors.length > 0}
                                        onChange={(e) => fieldApi.handleChange(e.target.value)}
                                        onBlur={fieldApi.handleBlur}
                                    />
                                    {fieldApi.state.meta.errors.map((err) => (
                                        <ErrorMessage>{err}</ErrorMessage>
                                    ))}
                                </Field>
                            )}
                        </createForm.Field>

                        <createForm.Field name={"kind"}>
                            {(fieldApi) => (
                                <Field>
                                    <RequiredLabel>
                                        {t("internal.create-questionnaire-question.label.question-type")}
                                    </RequiredLabel>
                                    <RadioGroup
                                        value={fieldApi.state.value}
                                        onChange={(value: AnswerType) => fieldApi.handleChange(value)}
                                    >
                                        <RadioField className={radioClasses}>
                                            <Radio color={"blue"} value={"Info"} />
                                            <Label>Info</Label>
                                        </RadioField>

                                        <RadioField className={radioClasses}>
                                            <Radio color={"blue"} value={"Boolean"} />
                                            <Label>Boolean</Label>
                                        </RadioField>

                                        <RadioField className={radioClasses}>
                                            <Radio color={"blue"} value={"Number"} />
                                            <Label>Number</Label>
                                        </RadioField>

                                        <RadioField className={radioClasses}>
                                            <Radio color={"blue"} value={"SingleChoice"} />
                                            <Label>Single Choice</Label>
                                        </RadioField>

                                        <RadioField className={radioClasses}>
                                            <Radio color={"blue"} value={"MultipleChoices"} />
                                            <Label>Multiple Choice</Label>
                                        </RadioField>
                                    </RadioGroup>
                                </Field>
                            )}
                        </createForm.Field>

                        <Button type={"submit"} color={"blue"}>
                            {t("button.create")}
                        </Button>
                    </FieldGroup>
                </Fieldset>
            </Form>
        </HeadingLayout>
    );
}

export const Route = createFileRoute("/_internal/i/questionnaires/questions/create")({
    component: CreateQuestionnaireQuestion,
});
