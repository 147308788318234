import { createFileRoute } from "@tanstack/react-router";

import React, { useContext, useEffect } from "react";
import { Heading, Subheading } from "../../../components/base/heading";
import { useTranslation } from "react-i18next";
import USER_CONTEXT from "../../../context/user";
import { Api } from "../../../api/api";
import { toast } from "react-toastify";
import { CustomerFullCustomer, CustomerSimpleProject } from "../../../api/generated";
import { clsx } from "clsx";
import { Badge } from "../../../components/base/badge";
import { StarIcon } from "@heroicons/react/20/solid";
import { Link } from "../../../components/base/link";
import UserAvatar from "../../../components/user-avatar";
import ContactCard from "../../../components/contact-card";

/**
 * The properties for {@link Dashboard}
 */
export type DashboardProps = {};

/**
 * The dashboard for the customer
 */
export default function Dashboard(props: DashboardProps) {
    const [t] = useTranslation();
    const userContext = useContext(USER_CONTEXT);

    const [projects, setProjects] = React.useState<Array<CustomerSimpleProject>>([]);
    const [customer, setCustomer] = React.useState<CustomerFullCustomer>();

    /**
     * Retrieve the projects of this user
     */
    const refreshProjects = () => {
        Api.customer.projects.all().then((res) =>
            res.match(
                (list) => setProjects(list.list),
                (err) => toast.error(err.message),
            ),
        );
    };

    useEffect(() => {
        refreshProjects();
        Api.customer.customers.get().then((res) =>
            res.match(
                (c) => setCustomer(c),
                (err) => toast.error(err.message),
            ),
        );
    }, []);

    return (
        <div className={"flex flex-col gap-12"}>
            <Heading>{t("customer.dashboard.heading.welcome", { name: userContext.user.display_name })}</Heading>

            <div className={"flex flex-col gap-3"}>
                <Subheading>{t("customer.dashboard.heading.your-contact")}</Subheading>
                {customer && (
                    <ContactCard
                        hideBadges={true}
                        className={"place-self-start"}
                        contact={{
                            ...customer.project_manager,
                            name: customer.project_manager.display_name,
                            user: customer.project_manager,
                        }}
                    />
                )}
            </div>

            <div className={"flex flex-col gap-3"}>
                <Subheading>{t("customer.dashboard.heading.projects")}</Subheading>
                <div className={"grid gap-3 lg:grid-cols-2 xl:grid-cols-3"}>
                    {projects.map((project) => (
                        <Link
                            key={project.uuid}
                            className={clsx(
                                // Base
                                "flex flex-col gap-3 rounded-lg border p-6 shadow",
                                // Color
                                //"border-zinc-200 bg-zinc-100",
                                // Dark
                                "dark:border-zinc-800 dark:bg-zinc-800",
                                // Hover
                                "transition-colors duration-100 hover:border-blue-500",
                            )}
                            href={"/c/projects/pentests/$projectId/general"}
                            params={{ projectId: project.uuid }}
                        >
                            <span className={"border-b border-zinc-300 pb-4 dark:text-white"}>{project.kind}</span>

                            <Badge color={"blue"}>
                                <div className={"flex items-center gap-3 p-2"}>
                                    <StarIcon className="size-4" />
                                    <UserAvatar
                                        user={{
                                            ...project.customer.project_manager,
                                            name: project.customer.project_manager.display_name,
                                        }}
                                        className={"size-8"}
                                    />
                                    <span className={"text-sm"}>{project.customer.project_manager.display_name}</span>
                                </div>
                            </Badge>
                        </Link>
                    ))}
                </div>
            </div>
        </div>
    );
}

export const Route = createFileRoute("/_customer/c/dashboard")({
    component: Dashboard,
});
