/* tslint:disable */
/* eslint-disable */
/**
 * Unnamed API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ApiErrorResponse,
  CreateQuestionGroupRequest,
  FormResultForNullAndDeleteQuestionGroupErrors,
  FormResultForNullAndUpdateQuestionGroupErrors,
  FormResultForSingleUuidAndCreateQuestionGroupErrors,
  ListForSimpleQuestionGroup,
  OptionalForFullQuestionGroup,
  UpdateQuestionGroupRequest,
} from '../models/index';

export interface CreateQuestionGroupOperationRequest {
    CreateQuestionGroupRequest: CreateQuestionGroupRequest;
}

export interface DeleteQuestionGroupRequest {
    uuid: string;
}

export interface GetQuestionGroupRequest {
    uuid: string;
}

export interface UpdateQuestionGroupOperationRequest {
    uuid: string;
    UpdateQuestionGroupRequest: UpdateQuestionGroupRequest;
}

/**
 * 
 */
export class QuestionnaireQuestionGroupsApi extends runtime.BaseAPI {

    /**
     * Creates a new question group
     * Creates a new question group
     */
    async createQuestionGroupRaw(requestParameters: CreateQuestionGroupOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FormResultForSingleUuidAndCreateQuestionGroupErrors>> {
        if (requestParameters['CreateQuestionGroupRequest'] == null) {
            throw new runtime.RequiredError(
                'CreateQuestionGroupRequest',
                'Required parameter "CreateQuestionGroupRequest" was null or undefined when calling createQuestionGroup().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/frontend/v1/internal/questionnaire/groups`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['CreateQuestionGroupRequest'],
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Creates a new question group
     * Creates a new question group
     */
    async createQuestionGroup(requestParameters: CreateQuestionGroupOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FormResultForSingleUuidAndCreateQuestionGroupErrors> {
        const response = await this.createQuestionGroupRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Deletes a single unused question group
     * Deletes a single unused question group
     */
    async deleteQuestionGroupRaw(requestParameters: DeleteQuestionGroupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FormResultForNullAndDeleteQuestionGroupErrors>> {
        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling deleteQuestionGroup().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/frontend/v1/internal/questionnaire/groups/{uuid}`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Deletes a single unused question group
     * Deletes a single unused question group
     */
    async deleteQuestionGroup(requestParameters: DeleteQuestionGroupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FormResultForNullAndDeleteQuestionGroupErrors> {
        const response = await this.deleteQuestionGroupRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Retrieves all existing question groups
     * Retrieves all existing question groups
     */
    async getAllQuestionGroupsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListForSimpleQuestionGroup>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/frontend/v1/internal/questionnaire/groups`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Retrieves all existing question groups
     * Retrieves all existing question groups
     */
    async getAllQuestionGroups(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListForSimpleQuestionGroup> {
        const response = await this.getAllQuestionGroupsRaw(initOverrides);
        return await response.value();
    }

    /**
     * Retrieves a single existing question groups
     * Retrieves a single existing question groups
     */
    async getQuestionGroupRaw(requestParameters: GetQuestionGroupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OptionalForFullQuestionGroup>> {
        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling getQuestionGroup().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/frontend/v1/internal/questionnaire/groups/{uuid}`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Retrieves a single existing question groups
     * Retrieves a single existing question groups
     */
    async getQuestionGroup(requestParameters: GetQuestionGroupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OptionalForFullQuestionGroup> {
        const response = await this.getQuestionGroupRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Updates an existing question group  Currently, questions can only be updated if the group ist not used yet by any questionnaire.
     * Updates an existing question group
     */
    async updateQuestionGroupRaw(requestParameters: UpdateQuestionGroupOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FormResultForNullAndUpdateQuestionGroupErrors>> {
        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling updateQuestionGroup().'
            );
        }

        if (requestParameters['UpdateQuestionGroupRequest'] == null) {
            throw new runtime.RequiredError(
                'UpdateQuestionGroupRequest',
                'Required parameter "UpdateQuestionGroupRequest" was null or undefined when calling updateQuestionGroup().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/frontend/v1/internal/questionnaire/groups/{uuid}`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['UpdateQuestionGroupRequest'],
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Updates an existing question group  Currently, questions can only be updated if the group ist not used yet by any questionnaire.
     * Updates an existing question group
     */
    async updateQuestionGroup(requestParameters: UpdateQuestionGroupOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FormResultForNullAndUpdateQuestionGroupErrors> {
        const response = await this.updateQuestionGroupRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
