import { Dialog, DialogActions, DialogBody, DialogProps, DialogTitle } from "../../base/dialog";
import { useTranslation } from "react-i18next";
import { Api } from "../../../api/api";
import React from "react";
import { toast } from "react-toastify";
import Form from "../../base/form";
import { Field, FieldGroup, Fieldset, Label, RequiredLabel } from "../../base/fieldset";
import { Input } from "../../base/input";
import { Button } from "../../base/button";
import { useForm } from "@tanstack/react-form";

/**
 * The properties for {@link DialogCreateContact}
 */
export type DialogCreateContactProps = DialogProps & {
    /** Uuid of customer */
    customerUuid: string;

    /** The action that should be called if the form was submitted successfully */
    onSubmit: (contactUuid: string) => void;
};

/**
 * The dialogs to add a new contact for a customer
 */
export default function DialogCreateContact(props: DialogCreateContactProps) {
    const { customerUuid, onSubmit, ...dialogProps } = props;

    const [t] = useTranslation();

    const createForm = useForm({
        defaultValues: {
            email: "",
            phone: "",
            name: "",
        },

        // eslint-disable-next-line
        onSubmit: async ({ value }) => {
            await Api.internal.customers.contacts
                .create(customerUuid, {
                    email: value.email,
                    name: value.name,
                    phone: value.phone,
                })
                .then((result) =>
                    result.match(
                        ({ uuid }) => onSubmit(uuid),
                        (err) => {
                            toast.error(err.message);
                        },
                    ),
                );
        },
    });

    return (
        <Dialog open={dialogProps.open} onClose={dialogProps.onClose}>
            <Form onSubmit={createForm.handleSubmit}>
                <DialogTitle>{t("internal.create-contact.heading")}</DialogTitle>
                <DialogBody>
                    <Fieldset>
                        <FieldGroup>
                            <createForm.Field name={"name"}>
                                {(fieldApi) => (
                                    <Field>
                                        <RequiredLabel>{t("label.name")}</RequiredLabel>
                                        <Input
                                            autoFocus={true}
                                            type={"text"}
                                            value={fieldApi.state.value}
                                            required={true}
                                            onChange={(e) => fieldApi.handleChange(e.target.value)}
                                        />
                                    </Field>
                                )}
                            </createForm.Field>
                            <createForm.Field name={"email"}>
                                {(fieldApi) => (
                                    <Field>
                                        <RequiredLabel>{t("label.mail")}</RequiredLabel>
                                        <Input
                                            type={"email"}
                                            value={fieldApi.state.value}
                                            required={true}
                                            onChange={(e) => fieldApi.handleChange(e.target.value)}
                                        />
                                    </Field>
                                )}
                            </createForm.Field>
                            <createForm.Field name={"phone"}>
                                {(fieldApi) => (
                                    <Field>
                                        <Label>{t("label.phone")}</Label>
                                        <Input
                                            type={"tel"}
                                            value={fieldApi.state.value}
                                            onChange={(e) => fieldApi.handleChange(e.target.value)}
                                        />
                                    </Field>
                                )}
                            </createForm.Field>
                        </FieldGroup>
                    </Fieldset>
                </DialogBody>
                <DialogActions>
                    <Button type={"submit"} color={"blue"}>
                        {t("internal.create-contact.button")}
                    </Button>
                </DialogActions>
            </Form>
        </Dialog>
    );
}
