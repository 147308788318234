import { createFileRoute } from "@tanstack/react-router";

import React, { useEffect } from "react";
import HeadingLayout from "../../../../../components/base/heading-layout";
import { useTranslation } from "react-i18next";
import { Button } from "../../../../../components/base/button";
import { Api } from "../../../../../api/api";
import { SimpleQuestionnaireTemplate } from "../../../../../api/generated";
import { toast } from "react-toastify";
import { Field } from "../../../../../components/base/fieldset";
import { Text } from "../../../../../components/base/text";
import { Input } from "../../../../../components/base/input";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "../../../../../components/base/table";
import {
    Dropdown,
    DropdownButton,
    DropdownItem,
    DropdownLabel,
    DropdownMenu,
} from "../../../../../components/base/dropdown";
import { EllipsisVerticalIcon } from "@heroicons/react/20/solid";
import { Dialog, DialogActions, DialogBody, DialogTitle } from "../../../../../components/base/dialog";

/**
 * The properties for {@link QuestionnaireTemplates}
 */
export type QuestionnaireTemplatesProps = {};

/**
 * The overview of all questionnaire templates
 */
export default function QuestionnaireTemplates(props: QuestionnaireTemplatesProps) {
    const [t] = useTranslation();

    const [templates, setTemplates] = React.useState<Array<SimpleQuestionnaireTemplate>>([]);
    const [templateQuery, setTemplateQuery] = React.useState("");
    const [deleteTemplate, setDeleteTemplate] = React.useState<SimpleQuestionnaireTemplate>();

    /**
     * Refresh the templates
     */
    const refresh = () => {
        Api.internal.questionnaires.templates.all().then((res) =>
            res.match(
                (list) => setTemplates(list.list),
                (err) => toast.error(err.message),
            ),
        );
    };

    useEffect(() => {
        refresh();
    }, []);

    const filtered =
        templateQuery === ""
            ? templates
            : templates.filter((t) => t.name.toLowerCase().includes(templateQuery.toLowerCase()));

    return (
        <HeadingLayout
            heading={t("internal.questionnaire-templates.heading.questionnaire-templates")}
            headingDescription={t("internal.questionnaire-templates.description.questionnaire-templates")}
            headingChildren={
                <Button href={"/i/questionnaires/templates/create"}>
                    {t("internal.questionnaire-templates.button.create-template")}
                </Button>
            }
        >
            <Field className={"flex w-fit flex-col gap-3"}>
                <Text className={"!text-white"}>{t("label.search")}</Text>
                <Input value={templateQuery} onChange={(e) => setTemplateQuery(e.target.value)} />
            </Field>

            <Table dense={true}>
                <TableHead>
                    <TableRow>
                        <TableHeader>{t("internal.questionnaire-templates.label.template-name")}</TableHeader>
                        <TableHeader>{t("label.no-of-groups")}</TableHeader>
                        <TableHeader>{t("label.instances")}</TableHeader>
                        <TableHeader className={"max-xl:hidden"}>{t("label.created-at")}</TableHeader>
                        <TableHeader className={"max-xl:hidden"}>{t("label.created-by")}</TableHeader>
                        <TableHeader className={"w-0"}>
                            <span className={"sr-only"}>{t("accessibility.actions")}</span>
                        </TableHeader>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {filtered.map((template) => (
                        <TableRow
                            key={template.uuid}
                            href={"/i/questionnaires/templates/$questionnaireId"}
                            params={{ questionnaireId: template.uuid }}
                        >
                            <TableCell className={"max-w-[40ch] overflow-hidden text-ellipsis"}>
                                {template.name}
                            </TableCell>
                            <TableCell>{template.groups}</TableCell>
                            <TableCell>{template.instances}</TableCell>
                            <TableCell className={"max-xl:hidden"}>
                                {new Date(template.created.at).toLocaleDateString()}
                            </TableCell>
                            <TableCell className={"max-xl:hidden"}>{template.created.by?.display_name}</TableCell>
                            <TableCell>
                                <Dropdown>
                                    <DropdownButton plain={true}>
                                        <EllipsisVerticalIcon />
                                    </DropdownButton>
                                    <DropdownMenu anchor={"bottom end"}>
                                        <DropdownItem onClick={() => setDeleteTemplate(template)}>
                                            <DropdownLabel>{t("button.delete")}</DropdownLabel>
                                        </DropdownItem>
                                    </DropdownMenu>
                                </Dropdown>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>

            {deleteTemplate && (
                <Dialog open={true} onClose={() => setDeleteTemplate(undefined)}>
                    <DialogBody>
                        <DialogTitle>
                            {t("dialog.heading.delete-template", { template: deleteTemplate.name })}
                        </DialogTitle>
                        <DialogActions>
                            <Button onClick={() => setDeleteTemplate(undefined)}>{t("button.no")}</Button>
                            <Button
                                color={"red"}
                                onClick={() => {
                                    Api.internal.questionnaires.templates.delete(deleteTemplate.uuid).then((res) =>
                                        res.match(
                                            (res) => {
                                                if (res.result === "Ok") {
                                                    refresh();
                                                } else {
                                                    toast.error(t("internal.questionnaire-templates.error.in-use"));
                                                }
                                            },
                                            (err) => toast.error(err.message),
                                        ),
                                    );
                                    setDeleteTemplate(undefined);
                                }}
                            >
                                {t("button.yes")}
                            </Button>
                        </DialogActions>
                    </DialogBody>
                </Dialog>
            )}
        </HeadingLayout>
    );
}

export const Route = createFileRoute("/_internal/i/questionnaires/templates/")({
    component: QuestionnaireTemplates,
});
