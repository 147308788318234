import { createFileRoute } from "@tanstack/react-router";
import React from "react";
import { useTranslation } from "react-i18next";
import PENTEST_PROJECT_CONTEXT from "../../../../../../context/pentest-project-internal";
import { Subheading } from "../../../../../../components/base/heading";
import ContactCard from "../../../../../../components/contact-card";

/**
 * The properties for {@link PentestProjectContacts}
 */
export type PentestProjectContactsProps = {};

/**
 * The contacts for a pentest project
 */
export default function PentestProjectContacts(props: PentestProjectContactsProps) {
    const [t] = useTranslation();
    const { obj } = React.useContext(PENTEST_PROJECT_CONTEXT);

    return (
        <div className={"flex flex-col gap-6"}>
            <Subheading>{t("label.contacts-tech")}</Subheading>
            <div className={"grid grid-cols-2 gap-12"}>
                {obj.contacts.technical.map((contact) => (
                    <ContactCard contact={{ ...contact, mail: contact.email }} />
                ))}
            </div>
            <Subheading>{t("label.contacts-mgmt")}</Subheading>
            <div className={"grid grid-cols-2 gap-12"}>
                {obj.contacts.management.map((contact) => (
                    <ContactCard contact={{ ...contact, mail: contact.email }} />
                ))}
            </div>
            <Subheading>{t("label.contacts-cc")}</Subheading>
            <div className={"grid grid-cols-2 gap-12"}>
                {obj.contacts.cc.map((contact) => (
                    <ContactCard contact={{ ...contact, mail: contact.email }} />
                ))}
            </div>
        </div>
    );
}

export const Route = createFileRoute("/_internal/i/pentests/$projectId/_pentest/contacts")({
    component: PentestProjectContacts,
});
