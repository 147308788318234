import { createFileRoute } from "@tanstack/react-router";

import React from "react";
import HeadingLayout from "src/components/base/heading-layout";
import { useTranslation } from "react-i18next";
import { useForm } from "@tanstack/react-form";
import Form from "src/components/base/form";
import { ErrorMessage, Field, FieldGroup, Fieldset, RequiredLabel } from "src/components/base/fieldset";
import { Button } from "src/components/base/button";
import FileInput from "src/components/base/file-input";
import { Api } from "src/api/api";
import { toast } from "react-toastify";

/**
 * The properties for {@link UploadKickoffPresentation}
 */
export type UploadKickoffPresentationProps = {};

/**
 * Upload the kickoff presentation
 */
function UploadKickoffPresentation(props: UploadKickoffPresentationProps) {
    const [t] = useTranslation();
    const { taskId } = Route.useParams();
    const navigate = Route.useNavigate();

    const form = useForm({
        defaultValues: {
            file: null as FileList | null,
        },
        // eslint-disable-next-line
        onSubmit: async ({ value, formApi }) => {
            if (!value.file || value.file.length === 0) {
                formApi.setFieldMeta("file", (meta) => {
                    meta.errors.push(t("error.invalid-file"));
                    return meta;
                });
                return;
            }

            const res = await Api.internal.projects.pentest.uploadKickoffPresentationV0(
                taskId,
                value.file[0].name,
                value.file[0],
            );

            res.match(
                () => {
                    navigate({ to: "/i/tasks/my" });
                },
                (err) => toast.error(err.message),
            );
        },
    });

    return (
        <HeadingLayout heading={t("internal.upload-kickoff-presentation.heading")}>
            <Form onSubmit={form.handleSubmit} className={"max-w-lg"}>
                <Fieldset>
                    <FieldGroup>
                        <form.Field name={"file"}>
                            {(fieldApi) => (
                                <Field>
                                    <RequiredLabel>{t("label.presentation")}</RequiredLabel>
                                    <FileInput
                                        required={true}
                                        invalid={fieldApi.state.meta.errors.length > 0}
                                        onChange={(e) => fieldApi.handleChange(e.target.files)}
                                    />
                                    {fieldApi.state.meta.errors.map((e) => (
                                        <ErrorMessage>{e}</ErrorMessage>
                                    ))}
                                </Field>
                            )}
                        </form.Field>

                        <Button type={"submit"} color={"blue"}>
                            {t("button.submit")}
                        </Button>
                    </FieldGroup>
                </Fieldset>
            </Form>
        </HeadingLayout>
    );
}

export const Route = createFileRoute("/_internal/i/forms/tasks/$taskId/upload-kickoff-presentation")({
    component: UploadKickoffPresentation,
});
