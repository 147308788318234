/* tslint:disable */
/* eslint-disable */
/**
 * Unnamed API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ApiErrorResponse,
  FormResultForNullAndDeletePasswordErrors,
  FormResultForNullAndSetPasswordErrors,
  FormResultForNullAndTestMatrixUserErrors,
  FormResultForNullAndUpdateMeErrors,
  FormResultForNullAndUpdateMeInternalErrors,
  FullMeUser,
  InternalGroups,
  ListForFullUser,
  ListForInternalUser,
  SetPasswordRequest,
  UpdateMeInternalRequest,
  UpdateMeRequest,
} from '../models/index';

export interface DeleteUserRequest {
    uuid: string;
}

export interface GetProfileImageRequest {
    uuid: string;
}

export interface SetInternalGroupsRequest {
    uuid: string;
    InternalGroups: InternalGroups;
}

export interface SetPasswordMeRequest {
    SetPasswordRequest: SetPasswordRequest;
}

export interface SetProfileImageRequest {
    body: Blob;
}

export interface UpdateMeCommonRequest {
    UpdateMeRequest: UpdateMeRequest;
}

export interface UpdateMeInternalOperationRequest {
    UpdateMeInternalRequest: UpdateMeInternalRequest;
}

/**
 * 
 */
export class UsersApi extends runtime.BaseAPI {

    /**
     * Deletes an existing password (and all MFA keys!)
     * Deletes an existing password (and all MFA keys!)
     */
    async deletePasswordMeRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FormResultForNullAndDeletePasswordErrors>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/frontend/v1/common/users/me/password`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Deletes an existing password (and all MFA keys!)
     * Deletes an existing password (and all MFA keys!)
     */
    async deletePasswordMe(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FormResultForNullAndDeletePasswordErrors> {
        const response = await this.deletePasswordMeRaw(initOverrides);
        return await response.value();
    }

    /**
     * Deletes the current user\'s profile image without setting a new one
     * Deletes the current user\'s profile image without setting a new one
     */
    async deleteProfileImageRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/frontend/v1/common/users/me/profile-image`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Deletes the current user\'s profile image without setting a new one
     * Deletes the current user\'s profile image without setting a new one
     */
    async deleteProfileImage(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteProfileImageRaw(initOverrides);
    }

    /**
     * Deletes a user
     * Deletes a user
     */
    async deleteUserRaw(requestParameters: DeleteUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling deleteUser().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/frontend/v1/admin/users/{uuid}`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Deletes a user
     * Deletes a user
     */
    async deleteUser(requestParameters: DeleteUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteUserRaw(requestParameters, initOverrides);
    }

    /**
     * Retrieves a list of all internal users
     * Retrieves a list of all internal users
     */
    async getAllInternalUsersRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListForInternalUser>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/frontend/v1/internal/users/internal`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Retrieves a list of all internal users
     * Retrieves a list of all internal users
     */
    async getAllInternalUsers(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListForInternalUser> {
        const response = await this.getAllInternalUsersRaw(initOverrides);
        return await response.value();
    }

    /**
     * Retrieves an unordered, unsorted list of all users
     * Retrieves an unordered, unsorted list of all users
     */
    async getAllUsersRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListForFullUser>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/frontend/v1/admin/users`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Retrieves an unordered, unsorted list of all users
     * Retrieves an unordered, unsorted list of all users
     */
    async getAllUsers(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListForFullUser> {
        const response = await this.getAllUsersRaw(initOverrides);
        return await response.value();
    }

    /**
     * Retrieve the currently logged-in user
     * Retrieve the currently logged-in user
     */
    async getMeRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FullMeUser>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/frontend/v1/common/users/me`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Retrieve the currently logged-in user
     * Retrieve the currently logged-in user
     */
    async getMe(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FullMeUser> {
        const response = await this.getMeRaw(initOverrides);
        return await response.value();
    }

    /**
     * Retrieves a user\'s profile image
     * Retrieves a user\'s profile image
     */
    async getProfileImageRaw(requestParameters: GetProfileImageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling getProfileImage().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/frontend/v1/common/users/{uuid}/profile-image`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Retrieves a user\'s profile image
     * Retrieves a user\'s profile image
     */
    async getProfileImage(requestParameters: GetProfileImageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.getProfileImageRaw(requestParameters, initOverrides);
    }

    /**
     * Overwrites an internal user\'s groups
     * Overwrites an internal user\'s groups
     */
    async setInternalGroupsRaw(requestParameters: SetInternalGroupsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling setInternalGroups().'
            );
        }

        if (requestParameters['InternalGroups'] == null) {
            throw new runtime.RequiredError(
                'InternalGroups',
                'Required parameter "InternalGroups" was null or undefined when calling setInternalGroups().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/frontend/v1/admin/users/{uuid}/groups`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['InternalGroups'],
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Overwrites an internal user\'s groups
     * Overwrites an internal user\'s groups
     */
    async setInternalGroups(requestParameters: SetInternalGroupsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.setInternalGroupsRaw(requestParameters, initOverrides);
    }

    /**
     * Change the password of the currently logged-in user  This may only be called by local users
     * Change the password of the currently logged-in user
     */
    async setPasswordMeRaw(requestParameters: SetPasswordMeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FormResultForNullAndSetPasswordErrors>> {
        if (requestParameters['SetPasswordRequest'] == null) {
            throw new runtime.RequiredError(
                'SetPasswordRequest',
                'Required parameter "SetPasswordRequest" was null or undefined when calling setPasswordMe().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/frontend/v1/common/users/me/password`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['SetPasswordRequest'],
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Change the password of the currently logged-in user  This may only be called by local users
     * Change the password of the currently logged-in user
     */
    async setPasswordMe(requestParameters: SetPasswordMeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FormResultForNullAndSetPasswordErrors> {
        const response = await this.setPasswordMeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Sets the current user\'s profile image
     * Sets the current user\'s profile image
     */
    async setProfileImageRaw(requestParameters: SetProfileImageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['body'] == null) {
            throw new runtime.RequiredError(
                'body',
                'Required parameter "body" was null or undefined when calling setProfileImage().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/octet-stream';

        const response = await this.request({
            path: `/api/frontend/v1/common/users/me/profile-image`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['body'] as any,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Sets the current user\'s profile image
     * Sets the current user\'s profile image
     */
    async setProfileImage(requestParameters: SetProfileImageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.setProfileImageRaw(requestParameters, initOverrides);
    }

    /**
     * Sends a set message to the configured matrix user
     * Sends a set message to the configured matrix user
     */
    async testMatrixUserInternalRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FormResultForNullAndTestMatrixUserErrors>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/frontend/v1/internal/users/me/test-matrix-user`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Sends a set message to the configured matrix user
     * Sends a set message to the configured matrix user
     */
    async testMatrixUserInternal(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FormResultForNullAndTestMatrixUserErrors> {
        const response = await this.testMatrixUserInternalRaw(initOverrides);
        return await response.value();
    }

    /**
     * Updates some of the currently logged-in user\'s details
     * Updates some of the currently logged-in user\'s details
     */
    async updateMeCommonRaw(requestParameters: UpdateMeCommonRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FormResultForNullAndUpdateMeErrors>> {
        if (requestParameters['UpdateMeRequest'] == null) {
            throw new runtime.RequiredError(
                'UpdateMeRequest',
                'Required parameter "UpdateMeRequest" was null or undefined when calling updateMeCommon().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/frontend/v1/common/users/me`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['UpdateMeRequest'],
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Updates some of the currently logged-in user\'s details
     * Updates some of the currently logged-in user\'s details
     */
    async updateMeCommon(requestParameters: UpdateMeCommonRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FormResultForNullAndUpdateMeErrors> {
        const response = await this.updateMeCommonRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Updates some of the currently logged-in user\'s details
     * Updates some of the currently logged-in user\'s details
     */
    async updateMeInternalRaw(requestParameters: UpdateMeInternalOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FormResultForNullAndUpdateMeInternalErrors>> {
        if (requestParameters['UpdateMeInternalRequest'] == null) {
            throw new runtime.RequiredError(
                'UpdateMeInternalRequest',
                'Required parameter "UpdateMeInternalRequest" was null or undefined when calling updateMeInternal().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/frontend/v1/internal/users/me`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['UpdateMeInternalRequest'],
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Updates some of the currently logged-in user\'s details
     * Updates some of the currently logged-in user\'s details
     */
    async updateMeInternal(requestParameters: UpdateMeInternalOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FormResultForNullAndUpdateMeInternalErrors> {
        const response = await this.updateMeInternalRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
