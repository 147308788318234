import { createFileRoute, Outlet } from "@tanstack/react-router";

import React from "react";
import { useTranslation } from "react-i18next";
import TabLayout from "../../../../../components/base/tab-layout";
import { Tab, TabMenu } from "../../../../../components/base/tab-menu";
import PENTEST_PROJECT_CONTEXT, { PentestProjectProvider } from "../../../../../context/pentest-project-internal";
import { Button } from "src/components/base/button";
import { ChevronLeftIcon } from "@heroicons/react/20/solid";
import { Text } from "src/components/base/text";

/**
 * The properties for {@link PentestProjectLayout}
 */
export type PentestProjectProps = {};

/**
 * The overview for a pentest project
 */
export default function PentestProjectLayout(props: PentestProjectProps) {
    const [t] = useTranslation();
    const { projectId } = Route.useParams();

    return (
        <PentestProjectProvider pentestProject={projectId} role={"Internal"}>
            <PENTEST_PROJECT_CONTEXT.Consumer>
                {({ obj }) => (
                    <>
                        <Button
                            className={"mb-6"}
                            plain={true}
                            href={"/i/customers/$customerId/general"}
                            params={{ customerId: obj.customer.uuid }}
                        >
                            <ChevronLeftIcon />
                            <Text>{t("button.customer")}</Text>
                        </Button>
                        <TabLayout
                            heading={t("internal.pentest-project.heading.pentest-project")}
                            headingDescription={t("internal.pentest-project.description.pentest-project", {
                                customer: obj.customer.legal_name,
                            })}
                            tabs={
                                <TabMenu>
                                    <Tab href={"/i/pentests/$projectId/general"} params={{ projectId }}>
                                        {t("label.general")}
                                    </Tab>
                                    <Tab href={"/i/pentests/$projectId/files"} params={{ projectId }}>
                                        {t("label.files")}
                                    </Tab>
                                    <Tab href={"/i/pentests/$projectId/contacts"} params={{ projectId }}>
                                        {t("label.contacts")}
                                    </Tab>
                                    <Tab href={"/i/pentests/$projectId/notes"} params={{ projectId }}>
                                        {t("label.notes")}
                                    </Tab>
                                    <Tab href={"/i/pentests/$projectId/schedule"} params={{ projectId }}>
                                        {t("label.schedule")}
                                    </Tab>
                                </TabMenu>
                            }
                        >
                            <Outlet />
                        </TabLayout>
                    </>
                )}
            </PENTEST_PROJECT_CONTEXT.Consumer>
        </PentestProjectProvider>
    );
}

export const Route = createFileRoute("/_internal/i/pentests/$projectId/_pentest")({
    component: PentestProjectLayout,
});
