import { createFileRoute } from "@tanstack/react-router";

import React from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "@tanstack/react-form";
import Form from "src/components/base/form";
import { ErrorMessage, Field, FieldGroup, Fieldset, RequiredLabel } from "src/components/base/fieldset";
import { Button } from "src/components/base/button";
import { Input } from "src/components/base/input";
import { Api } from "src/api/api";
import { toast } from "react-toastify";
import HeadingLayout from "src/components/base/heading-layout";
import { validateDate } from "src/utils/validators";

/**
 * The properties for {@link ScheduleKickoffForm}
 */
export type ScheduleKickoffFormProps = {};

/**
 * The form to schedule the kickoff
 */
function ScheduleKickoffForm(props: ScheduleKickoffFormProps) {
    const [t] = useTranslation();
    const { taskId } = Route.useParams();

    const form = useForm({
        defaultValues: {
            date: "",
        },
        // eslint-disable-next-line
        onSubmit: async ({ value, formApi }) => {
            const kick_off = validateDate(value.date);

            if (kick_off.isErr) {
                formApi.setFieldMeta("date", (meta) => {
                    meta.errors.push(t("error.invalid-date"));
                    return meta;
                });
                return;
            }

            const res = await Api.internal.projects.pentest.scheduleKickoffV0(taskId, {
                kickoff_date: kick_off.ok.toISOString(),
            });
            res.match(
                (res) => {
                    if (res.result === "Ok") {
                        history.back();
                    } else {
                        formApi.setFieldMeta("date", (meta) => {
                            meta.errors.push(t("error.date-in-past"));

                            return meta;
                        });
                    }
                },
                (err) => toast.error(err.message),
            );
        },
    });

    return (
        <HeadingLayout heading={t("internal.schedule-kickoff.heading")}>
            <Form onSubmit={form.handleSubmit}>
                <Fieldset>
                    <FieldGroup>
                        <form.Field name={"date"}>
                            {(fieldApi) => (
                                <Field>
                                    <RequiredLabel>{t("label.kickoff-date")}</RequiredLabel>
                                    <Input
                                        type={"datetime-local"}
                                        required={true}
                                        autoFocus={true}
                                        invalid={fieldApi.state.meta.errors.length > 0}
                                        value={fieldApi.state.value}
                                        onChange={(e) => fieldApi.handleChange(e.target.value)}
                                    />
                                    {fieldApi.state.meta.errors.map((e) => (
                                        <ErrorMessage>{e}</ErrorMessage>
                                    ))}
                                </Field>
                            )}
                        </form.Field>

                        <Button type={"submit"} color={"blue"}>
                            {t("button.submit")}
                        </Button>
                    </FieldGroup>
                </Fieldset>
            </Form>
        </HeadingLayout>
    );
}

export const Route = createFileRoute("/_internal/i/forms/tasks/$taskId/schedule-kickoff-v0")({
    component: ScheduleKickoffForm,
});
