import { createFileRoute, Outlet } from "@tanstack/react-router";

import React from "react";
import { Tab, TabMenu } from "../../../../../components/base/tab-menu";
import TabLayout from "../../../../../components/base/tab-layout";
import { useTranslation } from "react-i18next";
import { Button } from "../../../../../components/base/button";
import { ChevronLeftIcon } from "@heroicons/react/20/solid";
import { Text } from "../../../../../components/base/text";
import CUSTOMER_CONTEXT, { CustomerProvider } from "../../../../../context/customer";

/**
 * The properties for {@link CustomerLayout}
 */
export type CustomerLayoutProps = {};

/**
 * The layout for viewing a customer
 */
export default function CustomerLayout(props: CustomerLayoutProps) {
    const [t] = useTranslation();
    const { customerId } = Route.useParams();

    return (
        <CustomerProvider customerId={customerId}>
            <Button className={"mb-6"} plain={true} href={"/i/customers"}>
                <ChevronLeftIcon />
                <Text>{t("button.customer-management")}</Text>
            </Button>

            <CUSTOMER_CONTEXT.Consumer>
                {({ customer }) => (
                    <TabLayout
                        heading={customer.legal_name}
                        tabs={
                            <TabMenu>
                                <Tab href={"/i/customers/$customerId/general"}>{t("label.general")}</Tab>
                                <Tab href={"/i/customers/$customerId/contacts"}>{t("label.contacts")}</Tab>
                            </TabMenu>
                        }
                    >
                        <Outlet />
                    </TabLayout>
                )}
            </CUSTOMER_CONTEXT.Consumer>
        </CustomerProvider>
    );
}

export const Route = createFileRoute("/_internal/i/customers/$customerId/_customer")({
    component: CustomerLayout,
});
