import { createFileRoute } from "@tanstack/react-router";

import React from "react";
import { useTranslation } from "react-i18next";
import PENTEST_PROJECT_CONTEXT from "src/context/pentest-project-internal";
import { FullPentestProject } from "src/api/generated";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "src/components/base/table";
import { formatTextArea } from "src/utils/formatter";

/**
 * The properties for {@link PentestNotes}
 */
export type PentestNotesProps = {};

/**
 * Pentest notes
 */
function PentestNotes(props: PentestNotesProps) {
    const [t] = useTranslation();
    const { obj } = React.useContext(PENTEST_PROJECT_CONTEXT);
    const project = obj as FullPentestProject;

    return (
        <Table>
            <TableHead>
                <TableRow>
                    <TableHeader>{t("label.title")}</TableHeader>
                    <TableHeader>{t("label.notes")}</TableHeader>
                </TableRow>
            </TableHead>
            <TableBody>
                <TableRow key={"notes"}>
                    <TableCell>{t("label.project-creation-notes")}</TableCell>
                    <TableCell>{formatTextArea(project.notes)}</TableCell>
                </TableRow>

                {project.kickoff_results && (
                    <TableRow key={"kickoff"}>
                        <TableCell>{t("label.kickoff")}</TableCell>
                        <TableCell>{formatTextArea(project.kickoff_results.notes)}</TableCell>
                    </TableRow>
                )}

                {project.dialog_results && (
                    <TableRow key={"dialog"}>
                        <TableCell>{t("label.dialog")}</TableCell>
                        <TableCell>{formatTextArea(project.dialog_results.notes)}</TableCell>
                    </TableRow>
                )}

                {project.debrief_results && (
                    <TableRow key={"debrief"}>
                        <TableCell>{t("label.debrief")}</TableCell>
                        <TableCell>{formatTextArea(project.debrief_results.notes)}</TableCell>
                    </TableRow>
                )}

                {project.mgmt_presentation_results && (
                    <>
                        <TableRow key={"mgmt-presentation"}>
                            <TableCell>{t("label.mgmt-presentation")}</TableCell>
                            <TableCell>{formatTextArea(project.mgmt_presentation_results.notes)}</TableCell>
                        </TableRow>
                        <TableRow key={"mgmt-presentation-upsell"}>
                            <TableCell>{t("label.upsell-options")}</TableCell>
                            <TableCell>{formatTextArea(project.mgmt_presentation_results.upsale_options)}</TableCell>
                        </TableRow>
                    </>
                )}
            </TableBody>
        </Table>
    );
}

export const Route = createFileRoute("/_internal/i/pentests/$projectId/_pentest/notes")({
    component: PentestNotes,
});
