/* tslint:disable */
/* eslint-disable */
/**
 * Unnamed API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ApiErrorResponse,
  EnterPentestDebriefResultsV0Request,
  EnterPentestDialogResultsV0Request,
  EnterPentestDialogResultsV1Request,
  EnterPentestKickoffResultsV0Request,
  EnterPentestKickoffResultsV1Request,
  EnterPentestMgmtPresentationResultsV0Request,
  EnterPentestMgmtPresentationResultsV1Request,
  FormResultForNullAndEnterPentestDebriefResultsV0Errors,
  FormResultForNullAndEnterPentestDialogResultsV0Errors,
  FormResultForNullAndEnterPentestDialogResultsV1Errors,
  FormResultForNullAndEnterPentestKickoffResultsV0Errors,
  FormResultForNullAndEnterPentestKickoffResultsV1Errors,
  FormResultForNullAndEnterPentestMgmtPresentationResultsV0Errors,
  FormResultForNullAndEnterPentestMgmtPresentationResultsV1Errors,
  FormResultForNullAndScheduleDebriefV0Errors,
  FormResultForNullAndScheduleDialogV0Errors,
  FormResultForNullAndScheduleKickoffV0Errors,
  FormResultForNullAndScheduleKickoffV1Errors,
  FormResultForNullAndScheduleMgmtPresentationV0Errors,
  GetPentestEnterResultsResponse,
  GetPentestInviteMeetingResponse,
  ScheduleDebriefV0Request,
  ScheduleDialogV0Request,
  ScheduleKickoffV0Request,
  ScheduleKickoffV1Request,
  ScheduleMgmtPresentationV0Request,
} from '../models/index';

export interface EnterPentestDebriefResultsV0OperationRequest {
    uuid: string;
    EnterPentestDebriefResultsV0Request: EnterPentestDebriefResultsV0Request;
}

export interface EnterPentestDialogResultsV0OperationRequest {
    uuid: string;
    EnterPentestDialogResultsV0Request: EnterPentestDialogResultsV0Request;
}

export interface EnterPentestDialogResultsV1OperationRequest {
    uuid: string;
    EnterPentestDialogResultsV1Request: EnterPentestDialogResultsV1Request;
}

export interface EnterPentestKickoffResultsV0OperationRequest {
    uuid: string;
    EnterPentestKickoffResultsV0Request: EnterPentestKickoffResultsV0Request;
}

export interface EnterPentestKickoffResultsV1OperationRequest {
    uuid: string;
    EnterPentestKickoffResultsV1Request: EnterPentestKickoffResultsV1Request;
}

export interface EnterPentestMgmtPresentationResultsV0OperationRequest {
    uuid: string;
    EnterPentestMgmtPresentationResultsV0Request: EnterPentestMgmtPresentationResultsV0Request;
}

export interface EnterPentestMgmtPresentationResultsV1OperationRequest {
    uuid: string;
    EnterPentestMgmtPresentationResultsV1Request: EnterPentestMgmtPresentationResultsV1Request;
}

export interface GetPentestEnterResultsRequest {
    uuid: string;
}

export interface GetPentestInviteMeetingRequest {
    uuid: string;
}

export interface ScheduleDebriefV0OperationRequest {
    uuid: string;
    ScheduleDebriefV0Request: ScheduleDebriefV0Request;
}

export interface ScheduleDialogV0OperationRequest {
    uuid: string;
    ScheduleDialogV0Request: ScheduleDialogV0Request;
}

export interface ScheduleKickoffV0OperationRequest {
    uuid: string;
    ScheduleKickoffV0Request: ScheduleKickoffV0Request;
}

export interface ScheduleKickoffV1OperationRequest {
    uuid: string;
    ScheduleKickoffV1Request: ScheduleKickoffV1Request;
}

export interface ScheduleMgmtPresentationV0OperationRequest {
    uuid: string;
    ScheduleMgmtPresentationV0Request: ScheduleMgmtPresentationV0Request;
}

export interface UploadPentestDialogPresentationV0Request {
    uuid: string;
    file_name: string;
    body: Blob;
}

export interface UploadPentestKickoffPresentationV0Request {
    uuid: string;
    file_name: string;
    body: Blob;
}

export interface UploadPentestMgmtPresentationV0Request {
    uuid: string;
    file_name: string;
    body: Blob;
}

export interface UploadPentestReportV0Request {
    uuid: string;
    file_name: string;
    body: Blob;
}

/**
 * 
 */
export class PentestTasksApi extends runtime.BaseAPI {

    /**
     * Finishes the `EnterDebriefResultsV1` task
     * Finishes the `EnterDebriefResultsV1` task
     */
    async enterPentestDebriefResultsV0Raw(requestParameters: EnterPentestDebriefResultsV0OperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FormResultForNullAndEnterPentestDebriefResultsV0Errors>> {
        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling enterPentestDebriefResultsV0().'
            );
        }

        if (requestParameters['EnterPentestDebriefResultsV0Request'] == null) {
            throw new runtime.RequiredError(
                'EnterPentestDebriefResultsV0Request',
                'Required parameter "EnterPentestDebriefResultsV0Request" was null or undefined when calling enterPentestDebriefResultsV0().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/frontend/v1/internal/pentest/tasks/{uuid}/debrief-results-v0`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['EnterPentestDebriefResultsV0Request'],
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Finishes the `EnterDebriefResultsV1` task
     * Finishes the `EnterDebriefResultsV1` task
     */
    async enterPentestDebriefResultsV0(requestParameters: EnterPentestDebriefResultsV0OperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FormResultForNullAndEnterPentestDebriefResultsV0Errors> {
        const response = await this.enterPentestDebriefResultsV0Raw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Finishes the `EnterDialogResultsV0` task
     * Finishes the `EnterDialogResultsV0` task
     */
    async enterPentestDialogResultsV0Raw(requestParameters: EnterPentestDialogResultsV0OperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FormResultForNullAndEnterPentestDialogResultsV0Errors>> {
        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling enterPentestDialogResultsV0().'
            );
        }

        if (requestParameters['EnterPentestDialogResultsV0Request'] == null) {
            throw new runtime.RequiredError(
                'EnterPentestDialogResultsV0Request',
                'Required parameter "EnterPentestDialogResultsV0Request" was null or undefined when calling enterPentestDialogResultsV0().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/frontend/v1/internal/pentest/tasks/{uuid}/dialog-results-v0`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['EnterPentestDialogResultsV0Request'],
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Finishes the `EnterDialogResultsV0` task
     * Finishes the `EnterDialogResultsV0` task
     */
    async enterPentestDialogResultsV0(requestParameters: EnterPentestDialogResultsV0OperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FormResultForNullAndEnterPentestDialogResultsV0Errors> {
        const response = await this.enterPentestDialogResultsV0Raw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Finishes the `EnterDialogResultsV1` task
     * Finishes the `EnterDialogResultsV1` task
     */
    async enterPentestDialogResultsV1Raw(requestParameters: EnterPentestDialogResultsV1OperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FormResultForNullAndEnterPentestDialogResultsV1Errors>> {
        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling enterPentestDialogResultsV1().'
            );
        }

        if (requestParameters['EnterPentestDialogResultsV1Request'] == null) {
            throw new runtime.RequiredError(
                'EnterPentestDialogResultsV1Request',
                'Required parameter "EnterPentestDialogResultsV1Request" was null or undefined when calling enterPentestDialogResultsV1().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/frontend/v1/internal/pentest/tasks/{uuid}/dialog-results-v1`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['EnterPentestDialogResultsV1Request'],
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Finishes the `EnterDialogResultsV1` task
     * Finishes the `EnterDialogResultsV1` task
     */
    async enterPentestDialogResultsV1(requestParameters: EnterPentestDialogResultsV1OperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FormResultForNullAndEnterPentestDialogResultsV1Errors> {
        const response = await this.enterPentestDialogResultsV1Raw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Finishes the `EnterKickOffResultsV0` task
     * Finishes the `EnterKickOffResultsV0` task
     */
    async enterPentestKickoffResultsV0Raw(requestParameters: EnterPentestKickoffResultsV0OperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FormResultForNullAndEnterPentestKickoffResultsV0Errors>> {
        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling enterPentestKickoffResultsV0().'
            );
        }

        if (requestParameters['EnterPentestKickoffResultsV0Request'] == null) {
            throw new runtime.RequiredError(
                'EnterPentestKickoffResultsV0Request',
                'Required parameter "EnterPentestKickoffResultsV0Request" was null or undefined when calling enterPentestKickoffResultsV0().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/frontend/v1/internal/pentest/tasks/{uuid}/kickoff-results-v0`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['EnterPentestKickoffResultsV0Request'],
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Finishes the `EnterKickOffResultsV0` task
     * Finishes the `EnterKickOffResultsV0` task
     */
    async enterPentestKickoffResultsV0(requestParameters: EnterPentestKickoffResultsV0OperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FormResultForNullAndEnterPentestKickoffResultsV0Errors> {
        const response = await this.enterPentestKickoffResultsV0Raw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Finishes the `EnterKickOffResultsV1` task
     * Finishes the `EnterKickOffResultsV1` task
     */
    async enterPentestKickoffResultsV1Raw(requestParameters: EnterPentestKickoffResultsV1OperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FormResultForNullAndEnterPentestKickoffResultsV1Errors>> {
        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling enterPentestKickoffResultsV1().'
            );
        }

        if (requestParameters['EnterPentestKickoffResultsV1Request'] == null) {
            throw new runtime.RequiredError(
                'EnterPentestKickoffResultsV1Request',
                'Required parameter "EnterPentestKickoffResultsV1Request" was null or undefined when calling enterPentestKickoffResultsV1().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/frontend/v1/internal/pentest/tasks/{uuid}/kickoff-results-v1`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['EnterPentestKickoffResultsV1Request'],
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Finishes the `EnterKickOffResultsV1` task
     * Finishes the `EnterKickOffResultsV1` task
     */
    async enterPentestKickoffResultsV1(requestParameters: EnterPentestKickoffResultsV1OperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FormResultForNullAndEnterPentestKickoffResultsV1Errors> {
        const response = await this.enterPentestKickoffResultsV1Raw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Finishes the `EnterMgmtPresentationResultsV0` task
     * Finishes the `EnterMgmtPresentationResultsV0` task
     */
    async enterPentestMgmtPresentationResultsV0Raw(requestParameters: EnterPentestMgmtPresentationResultsV0OperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FormResultForNullAndEnterPentestMgmtPresentationResultsV0Errors>> {
        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling enterPentestMgmtPresentationResultsV0().'
            );
        }

        if (requestParameters['EnterPentestMgmtPresentationResultsV0Request'] == null) {
            throw new runtime.RequiredError(
                'EnterPentestMgmtPresentationResultsV0Request',
                'Required parameter "EnterPentestMgmtPresentationResultsV0Request" was null or undefined when calling enterPentestMgmtPresentationResultsV0().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/frontend/v1/internal/pentest/tasks/{uuid}/mgmt-presentation-results-v0`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['EnterPentestMgmtPresentationResultsV0Request'],
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Finishes the `EnterMgmtPresentationResultsV0` task
     * Finishes the `EnterMgmtPresentationResultsV0` task
     */
    async enterPentestMgmtPresentationResultsV0(requestParameters: EnterPentestMgmtPresentationResultsV0OperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FormResultForNullAndEnterPentestMgmtPresentationResultsV0Errors> {
        const response = await this.enterPentestMgmtPresentationResultsV0Raw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Finishes the `EnterMgmtPresentationResultsV1` task
     * Finishes the `EnterMgmtPresentationResultsV1` task
     */
    async enterPentestMgmtPresentationResultsV1Raw(requestParameters: EnterPentestMgmtPresentationResultsV1OperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FormResultForNullAndEnterPentestMgmtPresentationResultsV1Errors>> {
        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling enterPentestMgmtPresentationResultsV1().'
            );
        }

        if (requestParameters['EnterPentestMgmtPresentationResultsV1Request'] == null) {
            throw new runtime.RequiredError(
                'EnterPentestMgmtPresentationResultsV1Request',
                'Required parameter "EnterPentestMgmtPresentationResultsV1Request" was null or undefined when calling enterPentestMgmtPresentationResultsV1().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/frontend/v1/internal/pentest/tasks/{uuid}/mgmt-presentation-results-v1`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['EnterPentestMgmtPresentationResultsV1Request'],
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Finishes the `EnterMgmtPresentationResultsV1` task
     * Finishes the `EnterMgmtPresentationResultsV1` task
     */
    async enterPentestMgmtPresentationResultsV1(requestParameters: EnterPentestMgmtPresentationResultsV1OperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FormResultForNullAndEnterPentestMgmtPresentationResultsV1Errors> {
        const response = await this.enterPentestMgmtPresentationResultsV1Raw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Retrieve additional information for the `EnterKickOffResultsV1`, `EnterDialogResultsV1`, `EnterDebriefResultsV1` and `EnterMgmtPresentationResultsV1` tasks
     * Retrieve additional information for the `EnterKickOffResultsV1`, `EnterDialogResultsV1`, `EnterDebriefResultsV1` and `EnterMgmtPresentationResultsV1` tasks
     */
    async getPentestEnterResultsRaw(requestParameters: GetPentestEnterResultsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetPentestEnterResultsResponse>> {
        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling getPentestEnterResults().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/frontend/v1/internal/pentest/tasks/{uuid}/enter-results`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Retrieve additional information for the `EnterKickOffResultsV1`, `EnterDialogResultsV1`, `EnterDebriefResultsV1` and `EnterMgmtPresentationResultsV1` tasks
     * Retrieve additional information for the `EnterKickOffResultsV1`, `EnterDialogResultsV1`, `EnterDebriefResultsV1` and `EnterMgmtPresentationResultsV1` tasks
     */
    async getPentestEnterResults(requestParameters: GetPentestEnterResultsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetPentestEnterResultsResponse> {
        const response = await this.getPentestEnterResultsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Retrieve additional information for the `InviteITDialogV0`, `InviteITDebriefV0` and `InviteMgmtPresentationV0` tasks
     * Retrieve additional information for the `InviteITDialogV0`, `InviteITDebriefV0` and `InviteMgmtPresentationV0` tasks
     */
    async getPentestInviteMeetingRaw(requestParameters: GetPentestInviteMeetingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetPentestInviteMeetingResponse>> {
        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling getPentestInviteMeeting().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/frontend/v1/internal/pentest/tasks/{uuid}/invite-meeting`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Retrieve additional information for the `InviteITDialogV0`, `InviteITDebriefV0` and `InviteMgmtPresentationV0` tasks
     * Retrieve additional information for the `InviteITDialogV0`, `InviteITDebriefV0` and `InviteMgmtPresentationV0` tasks
     */
    async getPentestInviteMeeting(requestParameters: GetPentestInviteMeetingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetPentestInviteMeetingResponse> {
        const response = await this.getPentestInviteMeetingRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Finishes the `ScheduleDebriefV0` task
     * Finishes the `ScheduleDebriefV0` task
     */
    async scheduleDebriefV0Raw(requestParameters: ScheduleDebriefV0OperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FormResultForNullAndScheduleDebriefV0Errors>> {
        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling scheduleDebriefV0().'
            );
        }

        if (requestParameters['ScheduleDebriefV0Request'] == null) {
            throw new runtime.RequiredError(
                'ScheduleDebriefV0Request',
                'Required parameter "ScheduleDebriefV0Request" was null or undefined when calling scheduleDebriefV0().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/frontend/v1/internal/pentest/tasks/{uuid}/schedule-debrief-v0`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['ScheduleDebriefV0Request'],
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Finishes the `ScheduleDebriefV0` task
     * Finishes the `ScheduleDebriefV0` task
     */
    async scheduleDebriefV0(requestParameters: ScheduleDebriefV0OperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FormResultForNullAndScheduleDebriefV0Errors> {
        const response = await this.scheduleDebriefV0Raw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Finishes the `ScheduleDialogV0` task
     * Finishes the `ScheduleDialogV0` task
     */
    async scheduleDialogV0Raw(requestParameters: ScheduleDialogV0OperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FormResultForNullAndScheduleDialogV0Errors>> {
        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling scheduleDialogV0().'
            );
        }

        if (requestParameters['ScheduleDialogV0Request'] == null) {
            throw new runtime.RequiredError(
                'ScheduleDialogV0Request',
                'Required parameter "ScheduleDialogV0Request" was null or undefined when calling scheduleDialogV0().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/frontend/v1/internal/pentest/tasks/{uuid}/schedule-dialog-v0`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['ScheduleDialogV0Request'],
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Finishes the `ScheduleDialogV0` task
     * Finishes the `ScheduleDialogV0` task
     */
    async scheduleDialogV0(requestParameters: ScheduleDialogV0OperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FormResultForNullAndScheduleDialogV0Errors> {
        const response = await this.scheduleDialogV0Raw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Finishes the `ScheduleKickoffV0` task
     * Finishes the `ScheduleKickoffV0` task
     */
    async scheduleKickoffV0Raw(requestParameters: ScheduleKickoffV0OperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FormResultForNullAndScheduleKickoffV0Errors>> {
        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling scheduleKickoffV0().'
            );
        }

        if (requestParameters['ScheduleKickoffV0Request'] == null) {
            throw new runtime.RequiredError(
                'ScheduleKickoffV0Request',
                'Required parameter "ScheduleKickoffV0Request" was null or undefined when calling scheduleKickoffV0().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/frontend/v1/internal/pentest/tasks/{uuid}/schedule-kickoff-v0`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['ScheduleKickoffV0Request'],
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Finishes the `ScheduleKickoffV0` task
     * Finishes the `ScheduleKickoffV0` task
     */
    async scheduleKickoffV0(requestParameters: ScheduleKickoffV0OperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FormResultForNullAndScheduleKickoffV0Errors> {
        const response = await this.scheduleKickoffV0Raw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Finishes the `ScheduleKickoffV1` task
     * Finishes the `ScheduleKickoffV1` task
     */
    async scheduleKickoffV1Raw(requestParameters: ScheduleKickoffV1OperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FormResultForNullAndScheduleKickoffV1Errors>> {
        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling scheduleKickoffV1().'
            );
        }

        if (requestParameters['ScheduleKickoffV1Request'] == null) {
            throw new runtime.RequiredError(
                'ScheduleKickoffV1Request',
                'Required parameter "ScheduleKickoffV1Request" was null or undefined when calling scheduleKickoffV1().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/frontend/v1/internal/pentest/tasks/{uuid}/schedule-kickoff-v1`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['ScheduleKickoffV1Request'],
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Finishes the `ScheduleKickoffV1` task
     * Finishes the `ScheduleKickoffV1` task
     */
    async scheduleKickoffV1(requestParameters: ScheduleKickoffV1OperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FormResultForNullAndScheduleKickoffV1Errors> {
        const response = await this.scheduleKickoffV1Raw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Finishes the `ScheduleMgmtPresentationV0` task
     * Finishes the `ScheduleMgmtPresentationV0` task
     */
    async scheduleMgmtPresentationV0Raw(requestParameters: ScheduleMgmtPresentationV0OperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FormResultForNullAndScheduleMgmtPresentationV0Errors>> {
        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling scheduleMgmtPresentationV0().'
            );
        }

        if (requestParameters['ScheduleMgmtPresentationV0Request'] == null) {
            throw new runtime.RequiredError(
                'ScheduleMgmtPresentationV0Request',
                'Required parameter "ScheduleMgmtPresentationV0Request" was null or undefined when calling scheduleMgmtPresentationV0().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/frontend/v1/internal/pentest/tasks/{uuid}/schedule-mgmt-presentation-v0`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['ScheduleMgmtPresentationV0Request'],
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Finishes the `ScheduleMgmtPresentationV0` task
     * Finishes the `ScheduleMgmtPresentationV0` task
     */
    async scheduleMgmtPresentationV0(requestParameters: ScheduleMgmtPresentationV0OperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FormResultForNullAndScheduleMgmtPresentationV0Errors> {
        const response = await this.scheduleMgmtPresentationV0Raw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Finishes the `UploadPentestDialogPresentationV0` task
     * Finishes the `UploadPentestDialogPresentationV0` task
     */
    async uploadPentestDialogPresentationV0Raw(requestParameters: UploadPentestDialogPresentationV0Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling uploadPentestDialogPresentationV0().'
            );
        }

        if (requestParameters['file_name'] == null) {
            throw new runtime.RequiredError(
                'file_name',
                'Required parameter "file_name" was null or undefined when calling uploadPentestDialogPresentationV0().'
            );
        }

        if (requestParameters['body'] == null) {
            throw new runtime.RequiredError(
                'body',
                'Required parameter "body" was null or undefined when calling uploadPentestDialogPresentationV0().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['file_name'] != null) {
            queryParameters['file_name'] = requestParameters['file_name'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/octet-stream';

        const response = await this.request({
            path: `/api/frontend/v1/internal/pentest/tasks/{uuid}/dialog-presentation-v0`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['body'] as any,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Finishes the `UploadPentestDialogPresentationV0` task
     * Finishes the `UploadPentestDialogPresentationV0` task
     */
    async uploadPentestDialogPresentationV0(requestParameters: UploadPentestDialogPresentationV0Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.uploadPentestDialogPresentationV0Raw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Finishes the `UploadPentestKickoffPresentationV0` task
     * Finishes the `UploadPentestKickoffPresentationV0` task
     */
    async uploadPentestKickoffPresentationV0Raw(requestParameters: UploadPentestKickoffPresentationV0Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling uploadPentestKickoffPresentationV0().'
            );
        }

        if (requestParameters['file_name'] == null) {
            throw new runtime.RequiredError(
                'file_name',
                'Required parameter "file_name" was null or undefined when calling uploadPentestKickoffPresentationV0().'
            );
        }

        if (requestParameters['body'] == null) {
            throw new runtime.RequiredError(
                'body',
                'Required parameter "body" was null or undefined when calling uploadPentestKickoffPresentationV0().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['file_name'] != null) {
            queryParameters['file_name'] = requestParameters['file_name'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/octet-stream';

        const response = await this.request({
            path: `/api/frontend/v1/internal/pentest/tasks/{uuid}/kickoff-presentation-v0`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['body'] as any,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Finishes the `UploadPentestKickoffPresentationV0` task
     * Finishes the `UploadPentestKickoffPresentationV0` task
     */
    async uploadPentestKickoffPresentationV0(requestParameters: UploadPentestKickoffPresentationV0Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.uploadPentestKickoffPresentationV0Raw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Finishes the `UploadPentestMgmtPresentationPresentationV0` task
     * Finishes the `UploadPentestMgmtPresentationPresentationV0` task
     */
    async uploadPentestMgmtPresentationV0Raw(requestParameters: UploadPentestMgmtPresentationV0Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling uploadPentestMgmtPresentationV0().'
            );
        }

        if (requestParameters['file_name'] == null) {
            throw new runtime.RequiredError(
                'file_name',
                'Required parameter "file_name" was null or undefined when calling uploadPentestMgmtPresentationV0().'
            );
        }

        if (requestParameters['body'] == null) {
            throw new runtime.RequiredError(
                'body',
                'Required parameter "body" was null or undefined when calling uploadPentestMgmtPresentationV0().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['file_name'] != null) {
            queryParameters['file_name'] = requestParameters['file_name'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/octet-stream';

        const response = await this.request({
            path: `/api/frontend/v1/internal/pentest/tasks/{uuid}/mgmt-presentation-presentation-v0`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['body'] as any,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Finishes the `UploadPentestMgmtPresentationPresentationV0` task
     * Finishes the `UploadPentestMgmtPresentationPresentationV0` task
     */
    async uploadPentestMgmtPresentationV0(requestParameters: UploadPentestMgmtPresentationV0Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.uploadPentestMgmtPresentationV0Raw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Finishes the `UploadPentestReportV0` task
     * Finishes the `UploadPentestReportV0` task
     */
    async uploadPentestReportV0Raw(requestParameters: UploadPentestReportV0Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling uploadPentestReportV0().'
            );
        }

        if (requestParameters['file_name'] == null) {
            throw new runtime.RequiredError(
                'file_name',
                'Required parameter "file_name" was null or undefined when calling uploadPentestReportV0().'
            );
        }

        if (requestParameters['body'] == null) {
            throw new runtime.RequiredError(
                'body',
                'Required parameter "body" was null or undefined when calling uploadPentestReportV0().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['file_name'] != null) {
            queryParameters['file_name'] = requestParameters['file_name'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/octet-stream';

        const response = await this.request({
            path: `/api/frontend/v1/internal/pentest/tasks/{uuid}/report-v0`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['body'] as any,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Finishes the `UploadPentestReportV0` task
     * Finishes the `UploadPentestReportV0` task
     */
    async uploadPentestReportV0(requestParameters: UploadPentestReportV0Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.uploadPentestReportV0Raw(requestParameters, initOverrides);
        return await response.value();
    }

}
