import { createFileRoute, Outlet } from "@tanstack/react-router";

import React from "react";
import USER_CONTEXT from "src/context/user";

/**
 * The properties for {@link InternalGuard}
 */
export type InternalGuardProps = {};

/**
 * A guard that only
 */
function InternalGuard(props: InternalGuardProps) {
    const { user } = React.useContext(USER_CONTEXT);

    if (user.permissions.role !== "Internal") {
        // TODO: Better error view
        return undefined;
    }

    return <Outlet />;
}

export const Route = createFileRoute("/_links/links/_i")({
    component: InternalGuard,
});
