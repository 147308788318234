import { createFileRoute } from "@tanstack/react-router";

import React from "react";
import PENTEST_PROJECT_CONTEXT from "../../../../../../../context/pentest-project-internal";
import ContactCard from "../../../../../../../components/contact-card";
import { Subheading } from "../../../../../../../components/base/heading";
import { useTranslation } from "react-i18next";

/**
 * The properties for {@link PentestProjectContacts}
 */
export type PentestProjectContactsProps = {};

/**
 * Contacts of the pentest project
 */
export default function PentestProjectContacts(props: PentestProjectContactsProps) {
    const [t] = useTranslation();
    const { obj } = React.useContext(PENTEST_PROJECT_CONTEXT);

    return (
        <div className={"flex flex-col gap-6"}>
            <div className={"flex flex-col gap-3"}>
                <Subheading>{t("label.contacts-tech")}</Subheading>
                <div className={"grid gap-6 lg:grid-cols-2"}>
                    {obj.contacts.technical.map((contact) => (
                        <ContactCard contact={{ ...contact, mail: contact.email }} />
                    ))}
                </div>
            </div>

            <div className={"flex flex-col gap-3"}>
                <Subheading>{t("label.contacts-mgmt")}</Subheading>
                <div className={"grid gap-6 lg:grid-cols-2"}>
                    {obj.contacts.management.map((contact) => (
                        <ContactCard contact={{ ...contact, mail: contact.email }} />
                    ))}
                </div>
            </div>
            <div className={"flex flex-col gap-3"}>
                <Subheading>{t("label.contacts-cc")}</Subheading>
                <div className={"grid gap-6 lg:grid-cols-2"}>
                    {obj.contacts.cc.map((contact) => (
                        <ContactCard contact={{ ...contact, mail: contact.email }} />
                    ))}
                </div>
            </div>
        </div>
    );
}

export const Route = createFileRoute("/_customer/c/projects/pentests/$projectId/_pentest/contacts")({
    component: PentestProjectContacts,
});
