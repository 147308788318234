import React from "react";
import { Text } from "../base/text";
import { GiArmorPunch, GiChoice, GiCombinationLock, GiLaptop, GiMagnifyingGlass } from "react-icons/gi";
import { clsx } from "clsx";
import { useTranslation } from "react-i18next";
import { CustomerFullPentestModules } from "../../api/generated";

/**
 * The properties for {@link BookedPentestModules}
 */
export type BookedPentestModules = {
    /** The modules of the pentest */
    modules: CustomerFullPentestModules;
};

/**
 * The booked modules of a pentest
 */
export default function BookedPentestModules(props: BookedPentestModules) {
    const [t] = useTranslation();

    const { modules } = props;

    return (
        <div className={"grid grid-cols-3 gap-3 text-center md:grid-cols-5"}>
            <div className={"grid grid-rows-3 justify-items-center gap-2"}>
                {!modules.external && <Text>{t("label.external-pentest")}</Text>}
                {modules.external && modules.external.level === "BasicScan" && (
                    <Text>{t("label.external-pentest-base")}</Text>
                )}
                {modules.external && modules.external.level === "DeepScan" && (
                    <Text>{t("label.external-pentest-deep")}</Text>
                )}
                <GiArmorPunch
                    size="40"
                    className={clsx(
                        // default
                        "text-white dark:text-neutral-600",
                        modules.external
                            ? // active
                              "drop-shadow-glow dark:!text-neutral-300 dark:drop-shadow-glow-dark"
                            : // disabled
                              "!drop-shadow-glow-disabled dark:!drop-shadow-glow-dark-disabled",
                    )}
                />
            </div>

            <div className={"grid grid-rows-3 justify-items-center gap-2"}>
                <Text>{t("label.internal-risk-assessment")}</Text>

                <GiChoice
                    size="40"
                    className={clsx(
                        // default
                        "text-white dark:text-neutral-600",
                        modules.assessment?.level === "Extended"
                            ? // active
                              "drop-shadow-glow dark:!text-neutral-300 dark:drop-shadow-glow-dark"
                            : // disabled
                              "!drop-shadow-glow-disabled dark:!drop-shadow-glow-dark-disabled",
                    )}
                />
            </div>

            <div className={"grid grid-rows-3 justify-items-center gap-2"}>
                <Text>{t("label.internal-audit")}</Text>
                <GiMagnifyingGlass
                    size="40"
                    className={clsx(
                        // default
                        "text-white dark:text-neutral-600",
                        modules.internal
                            ? // active
                              "drop-shadow-glow dark:!text-neutral-300 dark:drop-shadow-glow-dark"
                            : // disabled
                              "!drop-shadow-glow-disabled dark:!drop-shadow-glow-dark-disabled",
                    )}
                />
                {modules.internal && modules.internal.level === "Simple" && (
                    <Text>{t("label.internal-audit-simple")}</Text>
                )}
                {modules.internal && modules.internal.level === "Extended" && (
                    <Text>{t("label.internal-audit-extended")}</Text>
                )}
            </div>

            <div className={"grid grid-rows-3 justify-items-center gap-2"}>
                <Text>{t("label.physical-pentest")}</Text>

                <GiCombinationLock
                    size="40"
                    className={clsx(
                        // default
                        "text-white dark:text-neutral-600",
                        modules.physical
                            ? // active
                              "drop-shadow-glow dark:!text-neutral-300 dark:drop-shadow-glow-dark"
                            : // disabled
                              "!drop-shadow-glow-disabled dark:!drop-shadow-glow-dark-disabled",
                    )}
                />
                {modules.physical && modules.physical.level === "AccessCheck" && (
                    <Text>{t("label.physical-pentest-access-check")}</Text>
                )}
                {modules.physical && modules.physical.level === "RedTeaming" && (
                    <Text>{t("label.physical-pentest-red-teaming")}</Text>
                )}
            </div>

            <div className={"grid grid-rows-3 justify-items-center gap-2"}>
                <Text>{t("label.device-pentest")}</Text>

                <GiLaptop
                    size="40"
                    className={clsx(
                        // default
                        "text-white dark:text-neutral-600",
                        modules.device?.level === "Yes"
                            ? // active
                              "drop-shadow-glow dark:!text-neutral-300 dark:drop-shadow-glow-dark"
                            : // disabled
                              "!drop-shadow-glow-disabled dark:!drop-shadow-glow-dark-disabled",
                    )}
                />
            </div>
        </div>
    );
}
