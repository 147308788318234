import React from "react";
import { Dialog, DialogActions, DialogBody, DialogProps, DialogTitle } from "../../base/dialog";
import { useTranslation } from "react-i18next";
import Form from "../../base/form";
import { FieldGroup, Fieldset, Label, Legend } from "../../base/fieldset";
import { Checkbox, CheckboxField, CheckboxGroup } from "../../base/checkbox";
import { InternalGroups } from "../../../api/generated";
import { Button } from "../../base/button";
import { Api, UUID } from "../../../api/api";
import { toast } from "react-toastify";

/**
 * The properties for {@link DialogUserGroups}
 */
export type DialogUserGroupsProps = DialogProps & DialogUserGroupsAdditional;

/**
 * Additional props for settings the user group
 */
export type DialogUserGroupsAdditional = {
    /** The uuid of the user */
    uuid: UUID;
    /** The current groups of the internal user */
    groups: InternalGroups;
};

/**
 * The dialogs to change the groups of an internal user
 */
export default function DialogUserGroups(props: DialogUserGroupsProps) {
    const [t] = useTranslation();

    const { uuid, groups, ...other } = props;

    const [currentGroups, setCurrentGroups] = React.useState<InternalGroups>(groups);

    /**
     * Set the groups on the user
     */
    const setUserGroups = () => {
        Api.admin.users.setInternalGroups(uuid, currentGroups).then((res) =>
            res.match(
                () => props.onClose(false),
                (err) => toast.error(err.message),
            ),
        );
    };

    return (
        <Dialog {...other}>
            <Form onSubmit={setUserGroups}>
                <DialogTitle>{t("admin.user-groups.heading")}</DialogTitle>
                <DialogBody>
                    <Fieldset>
                        <FieldGroup>
                            <CheckboxGroup>
                                <Legend>{t("label.groups")}</Legend>
                                <CheckboxField>
                                    <Checkbox
                                        color={"blue"}
                                        checked={currentGroups.billing}
                                        onChange={(e) =>
                                            setCurrentGroups({
                                                ...currentGroups,
                                                billing: e,
                                            })
                                        }
                                    />
                                    <Label>{t("label.billing")}</Label>
                                </CheckboxField>
                                <CheckboxField>
                                    <Checkbox
                                        color={"blue"}
                                        checked={currentGroups.project_management}
                                        onChange={(e) =>
                                            setCurrentGroups({
                                                ...currentGroups,
                                                project_management: e,
                                            })
                                        }
                                    />
                                    <Label>{t("label.project-management")}</Label>
                                </CheckboxField>
                                <CheckboxField>
                                    <Checkbox
                                        color={"blue"}
                                        checked={currentGroups.sales}
                                        onChange={(e) => setCurrentGroups({ ...currentGroups, sales: e })}
                                    />
                                    <Label>{t("label.sales")}</Label>
                                </CheckboxField>
                                <CheckboxField>
                                    <Checkbox
                                        color={"blue"}
                                        checked={currentGroups.support}
                                        onChange={(e) =>
                                            setCurrentGroups({
                                                ...currentGroups,
                                                support: e,
                                            })
                                        }
                                    />
                                    <Label>{t("label.support")}</Label>
                                </CheckboxField>
                                <CheckboxField>
                                    <Checkbox
                                        color={"blue"}
                                        checked={currentGroups.technical}
                                        onChange={(e) =>
                                            setCurrentGroups({
                                                ...currentGroups,
                                                technical: e,
                                            })
                                        }
                                    />
                                    <Label>{t("label.technical")}</Label>
                                </CheckboxField>
                                <CheckboxField>
                                    <Checkbox
                                        color={"blue"}
                                        checked={currentGroups.quality_assurance}
                                        onChange={(e) =>
                                            setCurrentGroups({
                                                ...currentGroups,
                                                quality_assurance: e,
                                            })
                                        }
                                    />
                                    <Label>{t("label.quality-assurance")}</Label>
                                </CheckboxField>
                                <CheckboxField>
                                    <Checkbox
                                        color={"blue"}
                                        checked={currentGroups.power_user}
                                        onChange={(e) =>
                                            setCurrentGroups({
                                                ...currentGroups,
                                                power_user: e,
                                            })
                                        }
                                    />
                                    <Label>{t("label.power-user")}</Label>
                                </CheckboxField>
                            </CheckboxGroup>
                        </FieldGroup>
                    </Fieldset>
                </DialogBody>
                <DialogActions>
                    <Button type={"submit"} color={"blue"}>
                        {t("admin.user-groups.button")}
                    </Button>
                </DialogActions>
            </Form>
        </Dialog>
    );
}
