import { createFileRoute } from "@tanstack/react-router";
import { useTranslation } from "react-i18next";
import { Api } from "src/api/api";
import { toast } from "react-toastify";
import CONSOLE from "src/utils/console";
import React, { useEffect } from "react";
import HeadingLayout from "src/components/base/heading-layout";
import { Field, FieldGroup, Fieldset, Label, Legend } from "src/components/base/fieldset";
import { Text } from "src/components/base/text";
import { Input } from "src/components/base/input";
import { Divider } from "src/components/base/divider";
import { Button } from "src/components/base/button";
import { EyeIcon, EyeSlashIcon } from "@heroicons/react/20/solid";

/**
 * The properties for {@link MatrixSettings}
 */
export type MatrixSettingsProps = {};

/**
 * The mail settings for the site
 */
function MatrixSettings(props: MatrixSettingsProps) {
    const [t] = useTranslation();
    const [adminRoom, setAdminRoom] = React.useState("");
    const [homeserver, setHomeserver] = React.useState("");
    const [username, setUsername] = React.useState("");
    const [password, setPassword] = React.useState("");

    const [showPassword, setShowPassword] = React.useState(false);

    const [testReceiver, setTestReceiver] = React.useState("");

    /**
     * Fetch the setting
     */
    const fetchSettings = () => {
        Api.admin.matrixSettings.get().then((res) => {
            res.match(
                (ok) => {
                    const settings = ok.optional;
                    if (settings === undefined) {
                        CONSOLE.debug("No settings found on server");
                        return;
                    }

                    setAdminRoom(settings.admin_room);
                    setHomeserver(settings.homeserver);
                    setUsername(settings.username);
                    setPassword(settings.password);
                },
                (err) => toast.error(err.message),
            );
        });
    };

    /**
     * Save the settings
     */
    const saveSettings = () => {
        Api.admin.matrixSettings.set({ admin_room: adminRoom, password, homeserver, username }).then((res) => {
            res.match(
                () => toast.success(t("admin.matrix-settings.toast.settings-saved")),
                (err) => toast.error(err.message),
            );
        });
    };

    /**
     * Send a matrix message to test the current settings
     */
    const sendTestMessage = () => {
        const toastId = toast.loading(t("admin.matrix-settings.toast.sending-test-message"));

        Api.admin.matrixSettings.test(testReceiver).then((res) => {
            res.match(
                (_) => {
                    toast.update(toastId, {
                        render: t("admin.matrix-settings.toast.test-message-sent"),
                        type: "success",
                        autoClose: 1500,
                        isLoading: false,
                    });
                },
                (err) => {
                    toast.update(toastId, { render: err.message, type: "error", autoClose: 1500, isLoading: false });
                },
            );
        });
    };

    useEffect(() => {
        fetchSettings();
    }, []);

    return (
        <>
            <HeadingLayout heading={t("label.matrix-settings")}>
                <form
                    method={"post"}
                    onSubmit={(e) => {
                        e.preventDefault();
                        saveSettings();
                    }}
                >
                    <Fieldset>
                        <FieldGroup>
                            <Legend>{t("label.server")}</Legend>
                            <Field className={"grid grid-cols-1 items-start gap-3 sm:grid-cols-2"}>
                                <span>
                                    <Label>{t("label.admin-room")}</Label>
                                    <Text>{t("admin.matrix-settings.description.admin-room")}</Text>
                                </span>
                                <Input
                                    value={adminRoom}
                                    onChange={(e) => setAdminRoom(e.target.value)}
                                    required={true}
                                />
                            </Field>
                            <Field className={"grid grid-cols-1 items-start gap-3 sm:grid-cols-2"}>
                                <span>
                                    <Label>{t("label.homeserver")}</Label>
                                    <Text>{t("admin.matrix-settings.description.homeserver")}</Text>
                                </span>
                                <Input
                                    value={homeserver}
                                    onChange={(e) => setHomeserver(e.target.value)}
                                    required={true}
                                />
                            </Field>
                            <Divider />

                            <Legend>{t("label.login")}</Legend>

                            <Field className={"grid grid-cols-1 items-start gap-3 sm:grid-cols-2"}>
                                <span>
                                    <Label>{t("label.user-name")}</Label>
                                    <Text>{t("admin.matrix-settings.description.username")}</Text>
                                </span>
                                <Input value={username} onChange={(e) => setUsername(e.target.value)} required={true} />
                            </Field>
                            <Field className={"grid grid-cols-1 items-start gap-3 sm:grid-cols-2"}>
                                <span>
                                    <Label>{t("label.password")}</Label>
                                    <Text>{t("admin.matrix-settings.description.password")}</Text>
                                </span>
                                <span className={"flex gap-3"}>
                                    <Input
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                        type={showPassword ? "text" : "password"}
                                        required={true}
                                    />
                                    <Button
                                        plain={true}
                                        onClick={() => {
                                            setShowPassword((prev) => !prev);
                                        }}
                                    >
                                        {showPassword ? <EyeSlashIcon /> : <EyeIcon />}
                                    </Button>
                                </span>
                            </Field>
                            <Divider />
                            <div className={"flex justify-end gap-6"}>
                                <Button type={"submit"} color={"blue"}>
                                    {t("button.save-settings")}
                                </Button>
                            </div>
                        </FieldGroup>
                    </Fieldset>
                </form>
            </HeadingLayout>
            <HeadingLayout className={"mt-12"} heading={t("admin.matrix-settings.heading")}>
                <form
                    method={"post"}
                    onSubmit={(e) => {
                        e.preventDefault();
                        sendTestMessage();
                    }}
                >
                    <Fieldset className={"max-w-2xl"}>
                        <FieldGroup>
                            <Field>
                                <Label>{t("label.receiver")}</Label>
                                <Text>{t("admin.matrix-settings.description.receiver")}</Text>
                                <Input
                                    className={"mt-3"}
                                    value={testReceiver}
                                    onChange={(e) => setTestReceiver(e.target.value)}
                                    required={true}
                                />
                            </Field>
                            <Button type={"submit"} color={"blue"}>
                                {t("button.send-message")}
                            </Button>
                        </FieldGroup>
                    </Fieldset>
                </form>
            </HeadingLayout>
        </>
    );
}

export const Route = createFileRoute("/_admin/a/settings/matrix")({
    component: MatrixSettings,
});
