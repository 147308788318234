import { createFileRoute, useNavigate } from "@tanstack/react-router";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
    CreatePentestModules,
    CreatePentestProjectRequest,
    FullCustomerContact,
    InternalUser,
    ProjectKind,
    SimpleCustomer,
    SimpleQuestionnaireTemplate,
    SimpleUser,
} from "src/api/generated";
import { Api } from "src/api/api";
import { toast } from "react-toastify";
import StepBar, { Step } from "src/components/base/step-bar";
import { Button } from "src/components/base/button";
import {
    Description,
    ErrorMessage,
    Field,
    FieldGroup,
    Fieldset,
    Label,
    RequiredLabel,
} from "src/components/base/fieldset";
import { Heading } from "src/components/base/heading";
import { Input } from "src/components/base/input";
import { Textarea } from "src/components/base/textarea";
import { AnimatePresence, motion } from "framer-motion";
import { Combobox, ComboboxOption, StatelessCombobox } from "src/components/base/combobox";
import { Radio, RadioField, RadioGroup } from "src/components/base/radio";
import { Text } from "src/components/base/text";
import Form from "src/components/base/form";
import { Checkbox, CheckboxField, CheckboxGroup } from "src/components/base/checkbox";
import BlockUnsavedChanges from "src/components/base/unsaved-changes";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "src/components/base/table";
import { TrashIcon } from "@heroicons/react/20/solid";
import { DividerText } from "src/components/base/divider";
import { Dialog, DialogActions, DialogBody } from "src/components/base/dialog";

/**
 * The properties for {@link CreateProject}
 */
export type CreateProjectProps = {};

/**
 * Create a new project
 */
export default function CreateProject(props: CreateProjectProps) {
    const [t] = useTranslation();
    const { customer } = Route.useSearch();

    const navigate = useNavigate();

    // General
    const [step, setStep] = React.useState<
        "customer" | "customer-user" | "project-type" | "modules-pentest" | "project-data-pentest"
    >("customer");
    const [customerList, setCustomerList] = React.useState<Array<SimpleCustomer>>([]);
    const [internalList, setInternalList] = React.useState<Array<InternalUser>>([]);

    // Customer selection
    const [customerQuery, setCustomerQuery] = React.useState("");
    const [customerCurrent, setCustomerCurrent] = React.useState<SimpleCustomer | null>(null);

    // Customer user selection
    const [customerContacts, setCustomerContacts] = React.useState<Array<FullCustomerContact>>([]);
    const [initialCustomerContact, setInitialCustomerContact] = React.useState<FullCustomerContact | null>(null);
    const [initialCustomerUserQuery, setInitialCustomerUserQuery] = React.useState("");

    const [showCustomerContactCreation, setShowCustomerContactCreation] = React.useState(false);
    const [newCustomerContactName, setNewCustomerContactName] = React.useState("");
    const [newCustomerContactMail, setNewCustomerContactMail] = React.useState("");
    const [newCustomerContactPhone, setNewCustomerContactPhone] = React.useState("");

    // Project selection
    const [projectType, setProjectType] = React.useState<ProjectKind>("Pentest");

    // Modules pentest
    const [modulesPentest, setModulesPentest] = React.useState<CreatePentestModules>({});

    // Pentest data
    const [questionnaires, setQuestionnaires] = React.useState<Array<SimpleQuestionnaireTemplate>>([]);
    const [notes, setNotes] = React.useState("");

    const [kickoffDate, setKickoffDate] = React.useState("");
    const [kickoffDateError, setKickoffDateError] = React.useState("");
    const [techLead, setTechLead] = React.useState<InternalUser | null>(null);
    const [techLeadQuery, setTechLeadQuery] = React.useState("");

    const [pm, setPm] = React.useState<SimpleUser | null>(null);
    const [mgmtAssistant, setMgmtAssistant] = React.useState<InternalUser | null>(null);
    const [mgmtAssistQuery, setMgmtAssistQuery] = React.useState("");

    const [techAssistantQuery, setTechAssistantQuery] = React.useState("");
    const [techAssistants, setTechAssistants] = React.useState<Array<InternalUser>>([]);

    const [questionnaireManagementCurr, setQuestionnaireManagementCurr] =
        React.useState<SimpleQuestionnaireTemplate | null>(null);
    const [questionnaireManagementQuery, setQuestionnaireManagementQuery] = React.useState("");
    const [questionnaireManagementError, setQuestionnaireManagementError] = React.useState("");
    const [questionnaireTechCurr, setQuestionnaireTechCurr] = React.useState<SimpleQuestionnaireTemplate | null>(null);
    const [questionnaireTechQuery, setQuestionnaireTechQuery] = React.useState("");
    const [questionnaireTechError, setQuestionnaireTechError] = React.useState("");

    /**
     * Create a pentest project
     */
    const createPentestProject = () => {
        let kickoff;
        try {
            kickoff = new Date(kickoffDate).toISOString();
        } catch (e: unknown) {
            kickoff = undefined;
        }

        let assessmentModule: CreatePentestProjectRequest["modules"]["assessment"] = undefined;
        if (modulesPentest.assessment) {
            if (!questionnaireTechCurr) {
                setQuestionnaireTechError(t("error.select-one"));
            } else {
                setQuestionnaireTechError("");
            }
            if (!questionnaireManagementCurr) {
                setQuestionnaireManagementError(t("error.select-one"));
            } else {
                setQuestionnaireManagementError("");
            }

            if (!questionnaireTechCurr || !questionnaireManagementCurr) {
                // Early return as it is invalid to have the assessment module selected, but no questionnaire templates
                return;
            }

            assessmentModule = {
                level: modulesPentest.assessment.level,
                organizational_questionnaire_template: questionnaireManagementCurr.uuid,
                technical_questionnaire_template: questionnaireTechCurr.uuid,
            };
        }

        kickoffDateError === "" &&
            customerCurrent &&
            techLead &&
            initialCustomerContact &&
            Api.internal.projects.pentest
                .create({
                    management_assistant: mgmtAssistant?.uuid,
                    customer_uuid: customerCurrent.uuid,
                    technical_lead: techLead.uuid,
                    kick_off: kickoff,
                    technical_assistant: techAssistants.map((ta) => ta.uuid),
                    notes,
                    initial_contact: initialCustomerContact.uuid,
                    modules: {
                        ...modulesPentest,
                        assessment: assessmentModule,
                    },
                })
                .then((res) =>
                    res.match(
                        (res) => {
                            if (res.result === "Ok") {
                                navigate({
                                    to: "/i/pentests/$projectId/general",
                                    params: { projectId: res.value.uuid },
                                    ignoreBlocker: true,
                                }).then();
                            } else {
                                if (res.error.kick_off) {
                                    setKickoffDateError(t("internal.create-project.error.date-in-past"));
                                }
                            }
                        },
                        (err) => toast.error(err.message),
                    ),
                );
    };

    /**
     * Retrieve the customer contacts from the current selected customer
     *
     * @param customer The customer to retrieve the contacts for
     */
    const getCustomerContacts = async (customer: SimpleCustomer) => {
        const res = await Api.internal.customers.get(customer.uuid);
        res.match(
            (customer) => {
                if (customer.optional) {
                    setCustomerContacts(customer.optional?.contacts);
                }
            },
            (err) => toast.error(err.message),
        );
    };

    useEffect(() => {
        Api.internal.customers.all().then((res) =>
            res.match(
                (list) => {
                    setCustomerList(list.list);

                    if (customerCurrent === undefined && customer !== undefined) {
                        const curr = list.list.find((c) => c.uuid === customer);

                        if (curr) {
                            setCustomerCurrent(curr);
                            getCustomerContacts(curr).then(() => setStep("customer-user"));
                        }
                    }
                },
                (err) => toast.error(err.message),
            ),
        );

        Api.internal.internalUsers.all().then((res) =>
            res.match(
                (list) => setInternalList(list.list),
                (err) => toast.error(err.message),
            ),
        );

        Api.internal.questionnaires.templates.all().then((res) =>
            res.match(
                (list) => setQuestionnaires(list.list),
                (err) => toast.error(err.message),
            ),
        );
    }, []);

    const steps: Array<Step> = [
        {
            label: "Select customer",
            state: step === "customer" ? "active" : "finished",
            description: "The customer the project should be attached to",
        },
        {
            label: "Select initial customer user",
            state: step === "customer-user" ? "active" : step === "customer" ? "pending" : "finished",
        },
        {
            label: "Select project type",
            state:
                step === "project-type"
                    ? "active"
                    : step === "customer-user" || step === "customer"
                      ? "pending"
                      : "finished",
        },
        {
            label: "Select pentest modules",
            state: step === "modules-pentest" ? "active" : step === "project-data-pentest" ? "finished" : "pending",
        },
        {
            label: "Enter project data",
            description: "Depending on project type",
            state: step === "project-data-pentest" ? "active" : "pending",
        },
    ];

    const initial = { x: 300, opacity: 0 };
    const animate = { x: 0, opacity: 1 };
    const exit = { x: -300, opacity: 0 };

    const filteredCustomerContacts =
        initialCustomerUserQuery === ""
            ? customerContacts
            : customerContacts.filter((c) => c.name.toLowerCase().includes(initialCustomerUserQuery.toLowerCase()));

    const filteredCustomers =
        customerQuery === ""
            ? customerList
            : customerList.filter((c) => c.legal_name.toLowerCase().includes(customerQuery.toLowerCase()));

    const techs = internalList.filter((c) => c.groups.technical);
    const filteredTechLeads =
        techLeadQuery === ""
            ? techs.filter((user) => !techAssistants.some((u) => u.uuid === user.uuid))
            : techs
                  .filter((user) => !techAssistants.some((u) => u.uuid === user.uuid))
                  .filter((user) => user.display_name.toLowerCase().includes(techLeadQuery.toLowerCase()));
    const filteredTechAssistants =
        techAssistantQuery === ""
            ? techs
                  .filter((user) => !techAssistants.some((ta) => ta.uuid === user.uuid))
                  .filter((user) => user.uuid !== techLead?.uuid)
            : techs
                  .filter((user) => !techAssistants.some((ta) => ta.uuid === user.uuid))
                  .filter((user) => user.display_name.toLowerCase().includes(techAssistantQuery.toLowerCase()));

    const pms = internalList.filter((c) => c.groups.project_management);
    const filteredMgmtAssist =
        mgmtAssistQuery !== ""
            ? pms
                  .filter((user) => pm && user.uuid !== pm.uuid)
                  .filter((user) => user.display_name.toLowerCase().includes(mgmtAssistQuery.toLowerCase()))
            : pms.filter((user) => pm && user.uuid !== pm.uuid);

    const filteredTechQuestionnaires =
        questionnaireTechQuery === ""
            ? questionnaires
            : questionnaires.filter((q) => q.name.toLowerCase().includes(questionnaireTechQuery.toLowerCase()));

    const filteredManagementQuestionnaires =
        questionnaireManagementQuery === ""
            ? questionnaires
            : questionnaires.filter((q) => q.name.toLowerCase().includes(questionnaireManagementQuery.toLowerCase()));

    return (
        <div className={"flex justify-around gap-12"}>
            <div className={"w-full max-w-lg overflow-hidden p-1"}>
                <Heading className={"mb-6"}>{t("internal.create-project.heading")}</Heading>

                {customerList.length === 0 && (
                    <div key={"no-customers"} className={"flex flex-col gap-6"}>
                        <Text>{t("internal.create-project.description.no-customers")}</Text>
                        <Button className={"w-fit"} color={"blue"} href={"/i/customers"}>
                            {t("internal.create-project.button.create-customer")}
                        </Button>
                    </div>
                )}
                <AnimatePresence mode={"wait"} initial={false}>
                    {step === "customer" && (
                        <motion.div
                            key={"customer"}
                            initial={initial}
                            animate={animate}
                            exit={exit}
                            className={"flex flex-col gap-6"}
                        >
                            <Form
                                onSubmit={() => {
                                    if (customerCurrent) {
                                        setPm(customerCurrent.project_manager);
                                        getCustomerContacts(customerCurrent).then(() => setStep("customer-user"));
                                    }
                                }}
                            >
                                <Fieldset>
                                    <FieldGroup>
                                        <Field>
                                            <RequiredLabel>{t("label.customer")}</RequiredLabel>
                                            <Combobox
                                                value={customerCurrent}
                                                onChange={(value) => setCustomerCurrent(value)}
                                                onClose={() => setCustomerQuery("")}
                                                onQueryChange={(query) => setCustomerQuery(query)}
                                                queryDisplay={(customer) => (customer ? customer.legal_name : "")}
                                            >
                                                {filteredCustomers.map((customer) => (
                                                    <ComboboxOption key={customer.uuid} value={customer}>
                                                        <Label>{customer.legal_name}</Label>
                                                    </ComboboxOption>
                                                ))}
                                            </Combobox>
                                        </Field>

                                        <div className={"flex justify-end"}>
                                            <Button type={"submit"} color={"blue"} disabled={!customerCurrent}>
                                                {t("button.next")}
                                            </Button>
                                        </div>
                                    </FieldGroup>
                                </Fieldset>
                            </Form>
                        </motion.div>
                    )}

                    {step === "customer-user" && (
                        <motion.div key={"customer-user"} initial={initial} animate={animate} exit={exit}>
                            <Form
                                onSubmit={() => {
                                    setStep("project-type");
                                }}
                            >
                                <Fieldset>
                                    <FieldGroup>
                                        <Field>
                                            <RequiredLabel>
                                                {t("internal.create-project.label.initial-project-user")}
                                            </RequiredLabel>
                                            <Combobox
                                                value={initialCustomerContact}
                                                onChange={(cc) => setInitialCustomerContact(cc)}
                                                onClose={() => setInitialCustomerUserQuery("")}
                                                queryDisplay={(q) => (q ? q.name : "")}
                                                onQueryChange={(q) => setInitialCustomerUserQuery(q)}
                                            >
                                                {filteredCustomerContacts.map((cc) => (
                                                    <ComboboxOption value={cc}>
                                                        <Label>{cc.name}</Label>
                                                    </ComboboxOption>
                                                ))}
                                            </Combobox>
                                        </Field>

                                        <DividerText text={t("label.or")} />

                                        <div className={"flex flex-col gap-3"}>
                                            <Button onClick={() => setShowCustomerContactCreation(true)}>
                                                {t("internal.create-project.button.create-contact")}
                                            </Button>
                                        </div>

                                        <div className={"flex justify-end gap-3"}>
                                            <Button plain={true} onClick={() => setStep("customer")}>
                                                {t("button.back")}
                                            </Button>
                                            <Button type={"submit"} color={"blue"}>
                                                {t("button.next")}
                                            </Button>
                                        </div>
                                    </FieldGroup>
                                </Fieldset>
                            </Form>
                        </motion.div>
                    )}

                    {step === "project-type" && (
                        <motion.div key={"project-type"} initial={initial} animate={animate} exit={exit}>
                            <Form onSubmit={() => setStep("modules-pentest")}>
                                <Fieldset>
                                    <FieldGroup>
                                        <RadioGroup
                                            value={projectType}
                                            onChange={(type: ProjectKind) => setProjectType(type)}
                                            aria-label={t("accessibility.project-type")}
                                        >
                                            <RadioField
                                                className={
                                                    "rounded-lg border-2 border-zinc-200 bg-zinc-50 p-3 transition-colors duration-100 hover:border-blue-600 has-[[data-checked]]:border-blue-600 dark:bg-zinc-800"
                                                }
                                            >
                                                <Radio value={"Pentest"} color={"blue"} />
                                                <Label>{t("internal.create-project.label.type-pentest")}</Label>
                                            </RadioField>

                                            <RadioField
                                                disabled={true}
                                                className={
                                                    "rounded-lg border-2 border-zinc-200 bg-zinc-50 p-3 transition-colors duration-100 hover:border-blue-600 data-[disabled]:border-zinc-200 dark:border-zinc-700 dark:bg-zinc-800 data-[disabled]:dark:border-zinc-800"
                                                }
                                            >
                                                <Radio value={"coming-soon"} color={"blue"} />
                                                <Label>{t("internal.create-project.label.type-coming-soon")}</Label>
                                            </RadioField>
                                        </RadioGroup>

                                        <div className={"flex justify-end gap-3"}>
                                            <Button plain={true} onClick={() => setStep("customer-user")}>
                                                {t("button.back")}
                                            </Button>
                                            <Button type={"submit"} color={"blue"}>
                                                {t("button.next")}
                                            </Button>
                                        </div>
                                    </FieldGroup>
                                </Fieldset>
                            </Form>
                        </motion.div>
                    )}

                    {step === "modules-pentest" && (
                        <motion.div initial={initial} animate={animate} exit={exit}>
                            <Form
                                onSubmit={() => {
                                    if (Object.values(modulesPentest).every((module) => module === undefined)) {
                                        toast.error(t("internal.create-project.error.no-modules-selected"));
                                        return;
                                    }

                                    setStep("project-data-pentest");
                                }}
                            >
                                <Fieldset>
                                    <FieldGroup>
                                        <Field>
                                            <Label>{t("label.external-pentest")}</Label>
                                            <CheckboxGroup>
                                                <CheckboxField>
                                                    <Checkbox
                                                        color={"blue"}
                                                        checked={modulesPentest.external?.level === "BasicScan"}
                                                        onChange={(e) =>
                                                            setModulesPentest((prev) => ({
                                                                ...prev,
                                                                external: e
                                                                    ? {
                                                                          level: "BasicScan",
                                                                          num_domains: 0,
                                                                          num_ips: 0,
                                                                      }
                                                                    : undefined,
                                                            }))
                                                        }
                                                    />
                                                    <Label>{t("label.external-pentest-base")}</Label>
                                                </CheckboxField>
                                                <CheckboxField>
                                                    <Checkbox
                                                        color={"blue"}
                                                        checked={modulesPentest.external?.level === "DeepScan"}
                                                        onChange={(e) =>
                                                            setModulesPentest((prev) => ({
                                                                ...prev,
                                                                external: e
                                                                    ? {
                                                                          level: "DeepScan",
                                                                          num_ips: 0,
                                                                          num_domains: 0,
                                                                      }
                                                                    : undefined,
                                                            }))
                                                        }
                                                    />
                                                    <Label>{t("label.external-pentest-deep")}</Label>
                                                </CheckboxField>
                                            </CheckboxGroup>
                                        </Field>

                                        <Field>
                                            <Label>{t("label.internal-risk-assessment")}</Label>
                                            <CheckboxGroup>
                                                <CheckboxField>
                                                    <Checkbox
                                                        color={"blue"}
                                                        checked={modulesPentest.assessment?.level === "Extended"}
                                                        onChange={(e) =>
                                                            setModulesPentest((prev) => ({
                                                                ...prev,
                                                                assessment: e
                                                                    ? {
                                                                          level: "Extended",
                                                                          technical_questionnaire_template: "",
                                                                          organizational_questionnaire_template: "",
                                                                      }
                                                                    : undefined,
                                                            }))
                                                        }
                                                    />
                                                    <Label>{t("label.internal-risk-assessment-extended")}</Label>
                                                </CheckboxField>
                                            </CheckboxGroup>
                                        </Field>

                                        <Field>
                                            <Label>{t("label.internal-audit")}</Label>
                                            <CheckboxGroup>
                                                <CheckboxField>
                                                    <Checkbox
                                                        color={"blue"}
                                                        checked={modulesPentest.internal?.level === "Simple"}
                                                        onChange={(e) =>
                                                            setModulesPentest((prev) => ({
                                                                ...prev,
                                                                internal: e
                                                                    ? {
                                                                          level: "Simple",
                                                                          tru: false,
                                                                          access_methods: "",
                                                                      }
                                                                    : undefined,
                                                            }))
                                                        }
                                                    />
                                                    <Label>{t("label.internal-audit-simple")}</Label>
                                                </CheckboxField>
                                                <CheckboxField>
                                                    <Checkbox
                                                        color={"blue"}
                                                        checked={modulesPentest.internal?.level === "Extended"}
                                                        onChange={(e) =>
                                                            setModulesPentest((prev) => ({
                                                                ...prev,
                                                                internal: e
                                                                    ? {
                                                                          level: "Extended",
                                                                          tru: false,
                                                                          access_methods: "",
                                                                      }
                                                                    : undefined,
                                                            }))
                                                        }
                                                    />
                                                    <Label>{t("label.internal-audit-extended")}</Label>
                                                </CheckboxField>
                                            </CheckboxGroup>
                                        </Field>

                                        <Field>
                                            <Label>{t("label.physical-pentest")}</Label>
                                            <CheckboxGroup>
                                                <CheckboxField>
                                                    <Checkbox
                                                        color={"blue"}
                                                        checked={modulesPentest.physical?.level === "AccessCheck"}
                                                        onChange={(e) =>
                                                            setModulesPentest((prev) => ({
                                                                ...prev,
                                                                physical: e ? { level: "AccessCheck" } : undefined,
                                                            }))
                                                        }
                                                    />
                                                    <Label>{t("label.physical-pentest-access-check")}</Label>
                                                </CheckboxField>
                                                <CheckboxField>
                                                    <Checkbox
                                                        color={"blue"}
                                                        checked={modulesPentest.physical?.level === "RedTeaming"}
                                                        onChange={(e) =>
                                                            setModulesPentest((prev) => ({
                                                                ...prev,
                                                                physical: e ? { level: "RedTeaming" } : undefined,
                                                            }))
                                                        }
                                                    />
                                                    <Label>{t("label.physical-pentest-red-teaming")}</Label>
                                                </CheckboxField>
                                            </CheckboxGroup>
                                        </Field>

                                        <Field>
                                            <Label>{t("label.device-pentest")}</Label>
                                            <CheckboxGroup>
                                                <CheckboxField>
                                                    <Checkbox
                                                        color={"blue"}
                                                        checked={modulesPentest.device?.level === "Yes"}
                                                        onChange={(e) =>
                                                            setModulesPentest((prev) => ({
                                                                ...prev,
                                                                device: e ? { level: "Yes", infos: "" } : undefined,
                                                            }))
                                                        }
                                                    />
                                                    <Label>{t("label.device-pentest")}</Label>
                                                </CheckboxField>
                                            </CheckboxGroup>
                                        </Field>

                                        <div className={"flex justify-end gap-3"}>
                                            <Button plain={true} onClick={() => setStep("project-type")}>
                                                {t("button.back")}
                                            </Button>
                                            <Button type={"submit"} color={"blue"}>
                                                {t("button.next")}
                                            </Button>
                                        </div>
                                    </FieldGroup>
                                </Fieldset>
                            </Form>
                        </motion.div>
                    )}

                    {step === "project-data-pentest" && (
                        <motion.div key={"project-data-pentest"} initial={initial} animate={animate} exit={exit}>
                            <Form onSubmit={createPentestProject}>
                                <Fieldset>
                                    <FieldGroup>
                                        <Field>
                                            <Label>{t("label.kickoff-date")}</Label>
                                            <Input
                                                autoFocus={true}
                                                type={"datetime-local"}
                                                value={kickoffDate}
                                                onChange={(e) => setKickoffDate(e.target.value)}
                                                invalid={kickoffDateError !== ""}
                                                onBlur={() => {
                                                    const curr = new Date(kickoffDate);
                                                    if (curr < new Date()) {
                                                        setKickoffDateError(
                                                            t("internal.create-project.error.date-in-past"),
                                                        );
                                                    } else {
                                                        setKickoffDateError("");
                                                    }
                                                }}
                                            />
                                            {kickoffDateError !== "" && <ErrorMessage>{kickoffDateError}</ErrorMessage>}
                                        </Field>

                                        <Field>
                                            <Label>{t("label.mgmt-assistant")}</Label>
                                            <Combobox
                                                value={mgmtAssistant}
                                                onChange={(ma) => {
                                                    setMgmtAssistant(ma);
                                                }}
                                                onClose={() => setMgmtAssistQuery("")}
                                                queryDisplay={(ma) => (ma ? ma.display_name : "")}
                                                onQueryChange={(query) => setMgmtAssistQuery(query)}
                                            >
                                                {filteredMgmtAssist.map((ma) => (
                                                    <ComboboxOption key={ma.uuid} value={ma}>
                                                        <Label>{ma.display_name}</Label>
                                                    </ComboboxOption>
                                                ))}
                                            </Combobox>
                                        </Field>

                                        <Field>
                                            <RequiredLabel>{t("label.tech-lead")}</RequiredLabel>
                                            <Combobox
                                                value={techLead}
                                                onChange={(tl) => {
                                                    setTechLead(tl);
                                                }}
                                                onClose={() => setTechLeadQuery("")}
                                                queryDisplay={(tl) => (tl ? tl.display_name : "")}
                                                onQueryChange={(query) => setTechLeadQuery(query)}
                                            >
                                                {filteredTechLeads.map((tl) => (
                                                    <ComboboxOption value={tl}>
                                                        <Label>{tl.display_name}</Label>
                                                    </ComboboxOption>
                                                ))}
                                            </Combobox>
                                        </Field>

                                        <Field>
                                            <Label>{t("label.tech-assistants")}</Label>
                                            <StatelessCombobox<InternalUser | null>
                                                onChange={(ta) => {
                                                    ta && setTechAssistants((prev) => [...prev, ta]);
                                                }}
                                                onClose={() => setTechAssistantQuery("")}
                                                onQueryChange={(query) => setTechAssistantQuery(query)}
                                            >
                                                {filteredTechAssistants.map((ta) => (
                                                    <ComboboxOption value={ta}>
                                                        <Label>{ta.display_name}</Label>
                                                    </ComboboxOption>
                                                ))}
                                            </StatelessCombobox>
                                            {techAssistants.length !== 0 && (
                                                <Table>
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableHeader>{t("label.name")}</TableHeader>
                                                            <TableHeader className={"w-0"}>
                                                                <span className={"sr-only"}>
                                                                    {t("accessibility.actions")}
                                                                </span>
                                                            </TableHeader>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {techAssistants.map((ta) => (
                                                            <TableRow key={ta.uuid}>
                                                                <TableCell>{ta.display_name}</TableCell>
                                                                <TableCell>
                                                                    <Button
                                                                        plain={true}
                                                                        onClick={() =>
                                                                            setTechAssistants((prev) => [
                                                                                ...prev.filter(
                                                                                    (user) => user.uuid !== ta.uuid,
                                                                                ),
                                                                            ])
                                                                        }
                                                                    >
                                                                        <TrashIcon />
                                                                    </Button>
                                                                </TableCell>
                                                            </TableRow>
                                                        ))}
                                                    </TableBody>
                                                </Table>
                                            )}
                                        </Field>

                                        {modulesPentest.external && (
                                            <>
                                                <Field>
                                                    <RequiredLabel>
                                                        {t("internal.create-project.label.no-target-ips")}
                                                    </RequiredLabel>
                                                    <Input
                                                        required={true}
                                                        type={"number"}
                                                        min={1}
                                                        value={modulesPentest.external.num_ips}
                                                        onChange={(e) =>
                                                            setModulesPentest((prev) => ({
                                                                ...prev,
                                                                external: prev.external
                                                                    ? {
                                                                          ...prev.external,
                                                                          num_ips: parseInt(e.target.value),
                                                                      }
                                                                    : undefined,
                                                            }))
                                                        }
                                                    />
                                                </Field>
                                                <Field>
                                                    <RequiredLabel>
                                                        {t("internal.create-project.label.no-target-domains")}
                                                    </RequiredLabel>
                                                    <Input
                                                        required={true}
                                                        type={"number"}
                                                        min={1}
                                                        value={modulesPentest.external.num_domains}
                                                        onChange={(e) =>
                                                            setModulesPentest((prev) => ({
                                                                ...prev,
                                                                external: prev.external
                                                                    ? {
                                                                          ...prev.external,
                                                                          num_domains: parseInt(e.target.value),
                                                                      }
                                                                    : undefined,
                                                            }))
                                                        }
                                                    />
                                                </Field>
                                            </>
                                        )}

                                        {modulesPentest.assessment && (
                                            <>
                                                <Field>
                                                    <RequiredLabel>
                                                        {t("internal.create-project.label.questionnaire-template-tech")}
                                                    </RequiredLabel>
                                                    <Combobox
                                                        value={questionnaireTechCurr}
                                                        onChange={(q) => setQuestionnaireTechCurr(q)}
                                                        onClose={() => setQuestionnaireTechQuery("")}
                                                        queryDisplay={(q) => (q ? q.name : "")}
                                                        onQueryChange={(q) => setQuestionnaireTechQuery(q)}
                                                        invalid={questionnaireTechError !== ""}
                                                    >
                                                        {filteredTechQuestionnaires.map((q) => (
                                                            <ComboboxOption value={q}>
                                                                <Label>{q.name}</Label>
                                                            </ComboboxOption>
                                                        ))}
                                                    </Combobox>
                                                    {questionnaireTechError && (
                                                        <ErrorMessage>{questionnaireTechError}</ErrorMessage>
                                                    )}
                                                </Field>
                                                <Field>
                                                    <RequiredLabel>
                                                        {t(
                                                            "internal.create-project.label.questionnaire-template-management",
                                                        )}
                                                    </RequiredLabel>
                                                    <Combobox
                                                        value={questionnaireManagementCurr}
                                                        onChange={(q) => setQuestionnaireManagementCurr(q)}
                                                        onClose={() => setQuestionnaireManagementQuery("")}
                                                        queryDisplay={(q) => (q ? q.name : "")}
                                                        onQueryChange={(q) => setQuestionnaireManagementQuery(q)}
                                                        invalid={questionnaireManagementError !== ""}
                                                    >
                                                        {filteredManagementQuestionnaires.map((q) => (
                                                            <ComboboxOption value={q}>
                                                                <Label>{q.name}</Label>
                                                            </ComboboxOption>
                                                        ))}
                                                    </Combobox>
                                                    {questionnaireManagementError && (
                                                        <ErrorMessage>{questionnaireManagementError}</ErrorMessage>
                                                    )}
                                                </Field>
                                            </>
                                        )}

                                        <Field>
                                            <Label>{t("label.notes")}</Label>
                                            <Description>
                                                {t("internal.create-project.description.pentest-project-notes")}
                                            </Description>
                                            <Textarea value={notes} onChange={(e) => setNotes(e.target.value)} />
                                        </Field>

                                        <div className={"flex justify-end gap-3"}>
                                            <Button plain={true} onClick={() => setStep("modules-pentest")}>
                                                {t("button.back")}
                                            </Button>
                                            <Button type={"submit"} color={"blue"}>
                                                {t("button.next")}
                                            </Button>
                                        </div>
                                    </FieldGroup>
                                </Fieldset>
                            </Form>
                        </motion.div>
                    )}
                </AnimatePresence>
            </div>

            <StepBar className={"hidden sm:block"} steps={steps} />

            {showCustomerContactCreation && (
                <Dialog open={true} onClose={() => setShowCustomerContactCreation(false)}>
                    <Form
                        onSubmit={async () => {
                            if (!customerCurrent) {
                                return;
                            }

                            const res = await Api.internal.customers.contacts.create(customerCurrent.uuid, {
                                name: newCustomerContactName,
                                email: newCustomerContactMail,
                                phone: newCustomerContactPhone,
                            });

                            res.match(
                                ({ uuid }) => {
                                    Api.internal.customers.contacts.get(uuid).then((res) =>
                                        res.match(
                                            (opt) => {
                                                setInitialCustomerContact(opt.optional ? opt.optional : null);
                                                setShowCustomerContactCreation(false);
                                                setNewCustomerContactPhone("");
                                                setNewCustomerContactName("");
                                                setNewCustomerContactMail("");
                                                setStep("project-type");
                                            },
                                            (err) => toast.error(err.message),
                                        ),
                                    );
                                },
                                (err) => toast.error(err.message),
                            );
                        }}
                    >
                        <DialogBody>
                            <Fieldset>
                                <FieldGroup>
                                    <Field>
                                        <RequiredLabel>{t("label.name")}</RequiredLabel>
                                        <Input
                                            required={true}
                                            autoFocus={true}
                                            value={newCustomerContactName}
                                            onChange={(e) => setNewCustomerContactName(e.target.value)}
                                        />
                                    </Field>
                                    <Field>
                                        <RequiredLabel>{t("label.mail")}</RequiredLabel>
                                        <Input
                                            type={"email"}
                                            required={true}
                                            value={newCustomerContactMail}
                                            onChange={(e) => setNewCustomerContactMail(e.target.value)}
                                        />
                                    </Field>
                                    <Field>
                                        <Label>{t("label.phone")}</Label>
                                        <Input
                                            type={"tel"}
                                            value={newCustomerContactPhone}
                                            onChange={(e) => setNewCustomerContactPhone(e.target.value)}
                                        />
                                    </Field>

                                    <DialogActions>
                                        <Button color={"blue"} type={"submit"}>
                                            {t("button.create")}
                                        </Button>
                                    </DialogActions>
                                </FieldGroup>
                            </Fieldset>
                        </DialogBody>
                    </Form>
                </Dialog>
            )}

            <BlockUnsavedChanges condition={step !== "customer"} />
        </div>
    );
}

export const Route = createFileRoute("/_internal/i/projects/create")({
    component: CreateProject,
    /**
     * Validation of the search term, that is used to prefill the customer
     *
     * @param search The provided search
     *
     * @returns the customer uuid in an object else it is set to undefined
     */
    validateSearch: (
        search: Record<string, unknown>,
    ): {
        /** The customer that should be prefilled (uuid) */
        customer?: string;
    } => ({ customer: "customer" in search ? (search.customer as string) || undefined : undefined }),
});
