import React, { useEffect } from "react";
import { toast } from "react-toastify";
import { Api, UUID } from "../api/api";
import { CustomerFullPentestProject, FullPentestProject } from "../api/generated/";
import { Text } from "../components/base/text";

/** Data provided by the {@link PENTEST_PROJECT_CONTEXT} */
export type PentestProjectContext = {
    /** The object */
    obj: FullPentestProject | CustomerFullPentestProject;

    /** Reload the context information */
    reset: () => void;
};

/** {@link React.Context} to access {@link FullPentestProject}  */
const PENTEST_PROJECT_CONTEXT = React.createContext<PentestProjectContext>({
    /**
     * The obj
     */
    obj: {
        archived: false,
        created_at: "",
        customer: {
            approved_at: "",
            created_at: "",
            legal_name: "",
            notes: "",
            project_manager: {
                display_name: "",
                has_profile_image: false,
                mail: "",
                phone: "",
                role: "Administrator",
                uuid: "",
            },
            uuid: "",
        },
        kind: "Pentest",
        modules: {},
        contacts: {
            cc: [],
            management: [],
            technical: [],
        },
        notes: "",
        state: "NotDue",
        technical_assistants: [],
        technical_lead: {
            display_name: "",
            has_profile_image: false,
            mail: "",
            phone: "",
            role: "Administrator",
            uuid: "",
        },
        uuid: "",
    },

    /**
     * Reset the object
     */
    reset: () => {},
});
PENTEST_PROJECT_CONTEXT.displayName = "PentestProjectContext";
export default PENTEST_PROJECT_CONTEXT;

/**
 * The properties for {@link PentestProjectProvider}
 */
export type PentestProjectProviderProps = {
    /** The id of the object */
    pentestProject: UUID;
    /** The role to execute the pentest project request */
    role: "Internal" | "Customer";
    /** Children of the context */
    children?: React.ReactNode;
};

/**
 * The provider
 */
export function PentestProjectProvider(props: PentestProjectProviderProps) {
    const { pentestProject, children } = props;

    const [obj, setObj] = React.useState<FullPentestProject | CustomerFullPentestProject | null>(null);

    /**
     * Retrieve
     */
    const fetch = async () => {
        const res =
            props.role === "Internal"
                ? await Api.internal.projects.pentest.get(pentestProject)
                : await Api.customer.projects.pentest.get(pentestProject);

        res.match(
            (opt) => setObj(opt.optional ? opt.optional : null),
            (err) => toast.error(err.message),
        );
    };

    useEffect(() => {
        fetch().then();
    }, [pentestProject]);

    if (!obj) {
        return <Text>Loading</Text>;
    }

    return (
        <PENTEST_PROJECT_CONTEXT.Provider
            value={{
                obj,
                reset:
                    /**
                     * Update the context
                     */
                    () => {
                        fetch().then();
                    },
            }}
        >
            {children}
        </PENTEST_PROJECT_CONTEXT.Provider>
    );
}
