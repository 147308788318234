import { createFileRoute } from "@tanstack/react-router";

import React from "react";

/**
 * The properties for {@link PentestProjectTargets}
 */
export type PentestProjectTargetsProps = {};

/**
 * The targets for a pentest
 */
export default function PentestProjectTargets(props: PentestProjectTargetsProps) {
    return <div></div>;
}

export const Route = createFileRoute("/_internal/i/pentests/$projectId/targets")({
    component: PentestProjectTargets,
});
