import React, { useEffect } from "react";
import HeadingLayout from "src/components/base/heading-layout";
import { useTranslation } from "react-i18next";
import { Field, FieldGroup, Fieldset, Label, Legend } from "src/components/base/fieldset";
import { Button } from "src/components/base/button";
import { Divider } from "src/components/base/divider";
import { Api } from "src/api/api";
import { toast } from "react-toastify";
import { Text } from "src/components/base/text";
import { Input } from "src/components/base/input";
import CONSOLE from "src/utils/console";
import { EyeIcon, EyeSlashIcon } from "@heroicons/react/20/solid";
import { createFileRoute } from "@tanstack/react-router";

/**
 * The properties for {@link MailSettings}
 */
export type MailSettingsProps = {};

/**
 * The mail settings for the site
 */
function MailSettings(props: MailSettingsProps) {
    const [t] = useTranslation();

    const [host, setHost] = React.useState("");
    const [port, setPort] = React.useState(465);
    const [username, setUsername] = React.useState("");
    const [password, setPassword] = React.useState("");
    const [fromMail, setFromMail] = React.useState("");
    const [fromName, setFromName] = React.useState("");

    const [showPassword, setShowPassword] = React.useState(false);

    const [testReceiver, setTestReceiver] = React.useState("");

    /**
     * Fetch the setting
     */
    const fetchSettings = () => {
        Api.admin.mailSettings.get().then((res) =>
            res.match(
                (ok) => {
                    const settings = ok.optional;
                    if (settings === undefined) {
                        CONSOLE.debug("No settings found on server");
                        return;
                    }

                    setHost(settings.host);
                    setPort(settings.port);
                    setUsername(settings.username);
                    setPassword(settings.password);
                    setFromName(settings.from_name);
                    setFromMail(settings.from_mail);
                },
                (err) => toast.error(err.message),
            ),
        );
    };

    /**
     * Save the settings
     */
    const saveSettings = () => {
        Api.admin.mailSettings
            .set({
                host,
                port,
                username,
                password,
                from_mail: fromMail,
                from_name: fromName,
            })
            .then((res) => {
                res.match(
                    () => toast.success(t("admin.mail-settings.toast.settings-saved")),
                    (err) => toast.error(err.message),
                );
            });
    };

    /**
     * Send a test mail
     */
    const sendTestMail = () => {
        const toastId = toast.loading(t("admin.mail-settings.toast.sending-test-mail"));

        Api.admin.mailSettings.test(testReceiver).then((res) =>
            res.match(
                (_) => {
                    toast.update(toastId, {
                        render: t("admin.mail-settings.toast.test-mail-sent"),
                        type: "success",
                        autoClose: 1500,
                        isLoading: false,
                    });
                },
                (err) => {
                    toast.update(toastId, { render: err.message, type: "error", autoClose: 1500, isLoading: false });
                },
            ),
        );
    };

    useEffect(() => {
        fetchSettings();
    }, []);

    return (
        <>
            <HeadingLayout heading={t("label.mail-settings")}>
                <form
                    method={"post"}
                    onSubmit={(e) => {
                        e.preventDefault();
                        saveSettings();
                    }}
                >
                    <Fieldset>
                        <FieldGroup>
                            <Legend>{t("label.network")}</Legend>
                            <Field className={"grid grid-cols-1 items-start gap-3 sm:grid-cols-2"}>
                                <span>
                                    <Label>{t("label.host")}</Label>
                                    <Text>{t("admin.mail-settings.description.host")}</Text>
                                </span>
                                <Input value={host} onChange={(e) => setHost(e.target.value)} required={true} />
                            </Field>
                            <Field className={"grid grid-cols-1 items-start gap-3 sm:grid-cols-2"}>
                                <span>
                                    <Label>{t("label.port")}</Label>
                                    <Text>{t("admin.mail-settings.description.port")}</Text>
                                </span>
                                <Input
                                    value={port}
                                    onChange={(e) => setPort(parseInt(e.target.value))}
                                    required={true}
                                    type={"number"}
                                />
                            </Field>

                            <Divider />

                            <Legend>{t("label.login")}</Legend>

                            <Field className={"grid grid-cols-1 items-start gap-3 sm:grid-cols-2"}>
                                <span>
                                    <Label>{t("label.user-name")}</Label>
                                    <Text>{t("admin.mail-settings.description.username")}</Text>
                                </span>
                                <Input value={username} onChange={(e) => setUsername(e.target.value)} required={true} />
                            </Field>
                            <Field className={"grid grid-cols-1 items-start gap-3 sm:grid-cols-2"}>
                                <span>
                                    <Label>{t("label.password")}</Label>
                                    <Text>{t("admin.mail-settings.description.password")}</Text>
                                </span>
                                <span className={"flex gap-3"}>
                                    <Input
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                        type={showPassword ? "text" : "password"}
                                        required={true}
                                    />

                                    <Button
                                        plain={true}
                                        onClick={() => {
                                            setShowPassword((prev) => !prev);
                                        }}
                                    >
                                        {showPassword ? <EyeSlashIcon /> : <EyeIcon />}
                                    </Button>
                                </span>
                            </Field>

                            <Divider />

                            <Legend>{t("label.sender")}</Legend>

                            <Field className={"grid grid-cols-1 items-start gap-3 sm:grid-cols-2"}>
                                <span>
                                    <Label>{t("label.from-name")}</Label>
                                    <Text>{t("admin.mail-settings.description.name")}</Text>
                                </span>
                                <Input value={fromName} onChange={(e) => setFromName(e.target.value)} required={true} />
                            </Field>
                            <Field className={"grid grid-cols-1 items-start gap-3 sm:grid-cols-2"}>
                                <span>
                                    <Label>{t("label.from-mail")}</Label>
                                    <Text>{t("admin.mail-settings.description.mail")}</Text>
                                </span>
                                <Input
                                    value={fromMail}
                                    onChange={(e) => setFromMail(e.target.value)}
                                    required={true}
                                    type={"email"}
                                />
                            </Field>
                            <Divider />
                            <div className={"flex justify-end gap-6"}>
                                <Button type={"submit"} color={"blue"}>
                                    {t("button.save-settings")}
                                </Button>
                            </div>
                        </FieldGroup>
                    </Fieldset>
                </form>
            </HeadingLayout>
            <HeadingLayout className={"mt-12"} heading={t("admin.mail-settings.heading")}>
                <form
                    method={"post"}
                    onSubmit={(e) => {
                        e.preventDefault();
                        sendTestMail();
                    }}
                >
                    <Fieldset className={"max-w-2xl"}>
                        <FieldGroup>
                            <Field>
                                <Label>{t("label.receiver")}</Label>
                                <Text>{t("admin.mail-settings.description.receiver")}</Text>
                                <Input
                                    className={"mt-3"}
                                    value={testReceiver}
                                    onChange={(e) => setTestReceiver(e.target.value)}
                                    required={true}
                                    type={"email"}
                                />
                            </Field>
                            <Button type={"submit"} color={"blue"}>
                                {t("button.send-mail")}
                            </Button>
                        </FieldGroup>
                    </Fieldset>
                </form>
            </HeadingLayout>
        </>
    );
}

export const Route = createFileRoute("/_admin/a/settings/mail")({
    component: MailSettings,
});
