import { createFileRoute } from "@tanstack/react-router";

import React, { useEffect } from "react";
import { Api } from "../../../../../../../../api/api";
import { toast } from "react-toastify";
import {
    FullQuestionnaireQuestion,
    SimpleQuestionnaireItem,
    SubmitCurrentAnswerRequest,
} from "../../../../../../../../api/generated";
import { Button } from "../../../../../../../../components/base/button";
import { useTranslation } from "react-i18next";
import Question from "../../../../../../../../components/questionnaire/question";
import HeadingLayout from "../../../../../../../../components/base/heading-layout";
import { Heading } from "../../../../../../../../components/base/heading";
import { ChevronLeftIcon } from "@heroicons/react/20/solid";
import { Text } from "../../../../../../../../components/base/text";
import { AnimatePresence, motion } from "framer-motion";

/** Helper type to bundle all relevant information for a question */
type CurrQuestion = {
    /** The question itself */
    question: FullQuestionnaireQuestion;
    /** The group the question is in */
    group: SimpleQuestionnaireItem;
    /** The template the question belongs to */
    template: SimpleQuestionnaireItem;
};

/**
 * The properties for {@link Questionnaire}
 */
export type QuestionnaireProps = {};

/**
 * A questionnaire for the customer
 */
export default function Questionnaire(props: QuestionnaireProps) {
    const { projectId, questionnaireId } = Route.useParams();
    const [t] = useTranslation();

    const [currQuestion, setCurrQuestion] = React.useState<CurrQuestion | "completed">();

    /** Retrieve the current question */
    const getCurrQuestion = async () => {
        const res = await Api.customer.questionnaires.getCurrQuestion(questionnaireId);

        res.match(
            (res) => {
                if (res.result === "Question") {
                    setCurrQuestion({ group: res.group, template: res.template, question: res.question });
                } else if (res.result === "Completed") {
                    setCurrQuestion("completed");
                } else {
                    toast.error("Questionnaire question was not found");
                }
            },
            (err) => toast.error(err.message),
        );
    };

    /**
     * Submit the answer to the current question
     *
     * @param submit The request to submit
     */
    const submitQuestion = async (submit: SubmitCurrentAnswerRequest) => {
        const res = await Api.customer.questionnaires.submitCurrQuestion(questionnaireId, submit);
        res.match(
            (res) => {
                if (res === "Ok") {
                    getCurrQuestion().then();
                }
            },
            (err) => toast.error(err.message),
        );
    };

    useEffect(() => {
        getCurrQuestion().then();
    }, []);

    if (currQuestion === "completed") {
        return (
            <div className="flex flex-col gap-4">
                <Text>{t("customer.questionnaire.description.questionnaire-finished")}</Text>
                <Button
                    className={"place-self-start"}
                    color={"blue"}
                    onClick={() => history.back()}
                    params={{ projectId }}
                >
                    {t("button.back-to-overview")}
                </Button>
            </div>
        );
    }

    return (
        currQuestion !== undefined && (
            <>
                <Button plain={true} href={"/c/projects/pentests/$projectId"} params={{ projectId }}>
                    <ChevronLeftIcon />
                    <Text>{t("button.back-to-overview")}</Text>
                </Button>

                <HeadingLayout
                    className={"mt-6"}
                    heading={currQuestion.template.title}
                    headingDescription={currQuestion.template.description}
                >
                    <div className={"overflow-hidden"}>
                        <div className={"flex flex-col gap-3"}>
                            <Heading>{currQuestion.group.title}</Heading>
                            <Text>{currQuestion.group.description}</Text>
                        </div>
                        <AnimatePresence mode={"wait"} initial={false}>
                            <motion.div
                                initial={{ x: 300, opacity: 0 }}
                                animate={{ x: 0, opacity: 1 }}
                                exit={{ x: -300, opacity: 0 }}
                                className={"mt-6"}
                                key={currQuestion.question.uuid}
                            >
                                <Question question={currQuestion.question} onNext={submitQuestion} />
                            </motion.div>
                        </AnimatePresence>
                    </div>
                </HeadingLayout>
            </>
        )
    );
}

export const Route = createFileRoute(
    "/_customer/c/projects/pentests/$projectId/_pentest/questionnaire/$questionnaireId",
)({
    component: Questionnaire,
});
