import { createFileRoute } from "@tanstack/react-router";
import { useTranslation } from "react-i18next";
import { FullPentestProject } from "src/api/generated";
import React from "react";
import PENTEST_PROJECT_CONTEXT from "src/context/pentest-project-internal";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "src/components/base/table";
import { sortDates } from "src/utils/sorter";
import { displayDeadline } from "src/utils/tasks";
import { Badge } from "src/components/base/badge";
import { Text } from "src/components/base/text";

/**
 * The properties for {@link PentestProjectSchedule}
 */
export type PentestProjectScheduleProps = {};

/**
 * Type of the appointment
 */
type AppointmentType = "Kickoff" | "Scan" | "Dialog" | "Debrief" | "Report" | "Mgmt";

/**
 * An event in the timeline
 */
type TimelineEvent = {
    /** Label of the event */
    label: string;
    /** Date of the event */
    date: string | Date;
    /** Description of the event */
    description: string;
    /** The type of the appointment */
    type: AppointmentType;
};

/**
 * The general view for a pentest project
 */
function PentestProjectSchedule(props: PentestProjectScheduleProps) {
    const [t] = useTranslation();
    const { obj } = React.useContext(PENTEST_PROJECT_CONTEXT);
    const pentest = obj as FullPentestProject;

    // all events with date here, order is important
    const timeline: Array<TimelineEvent> = [
        {
            label: t("label.kickoff"),
            date: pentest.kick_off ? new Date(pentest.kick_off) : "",
            description: pentest.kickoff_results ? pentest.kickoff_results.notes : "",
            type: "Kickoff",
        },
        {
            label: t("label.scan-date"),
            date:
                pentest.kickoff_results && pentest.kickoff_results.scan_date
                    ? new Date(pentest.kickoff_results.scan_date)
                    : "",
            description: "",
            type: "Scan",
        },
        {
            label: t("label.dialog"),
            date: pentest.dialog ? new Date(pentest.dialog) : "",
            description: pentest.dialog_results ? pentest.dialog_results.notes : "",
            type: "Dialog",
        },
        {
            label: t("label.report-deadline"),
            date:
                pentest.kickoff_results && pentest.kickoff_results.report_deadline
                    ? new Date(pentest.kickoff_results.report_deadline)
                    : "",
            description: pentest.kickoff_results ? pentest.kickoff_results.report_delivery : "",
            type: "Report",
        },
        {
            label: t("label.debrief"),
            date: pentest.debrief ? new Date(pentest.debrief) : "",
            description: pentest.debrief_results ? pentest.debrief_results.notes : "",
            type: "Debrief",
        },
        {
            label: t("label.mgmt-presentation"),
            date: pentest.mgmt_presentation ? new Date(pentest.mgmt_presentation) : "",
            description: pentest.mgmt_presentation_results ? pentest.mgmt_presentation_results.notes : "",
            type: "Mgmt",
        },
    ];

    return (
        <div>
            <Table className={"dark:text-zinc-200"} dense={true}>
                <TableHead>
                    <TableRow>
                        <TableHeader>{t("label.date")}</TableHeader>
                        <TableHeader>{t("label.appointment")}</TableHeader>
                        <TableHeader className={"w-0"}>
                            <span className={"sr-only"}>{t("label.actions")}</span>
                        </TableHeader>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {timeline
                        .sort((a, b) =>
                            sortDates(a.date ? new Date(a.date) : undefined, b.date ? new Date(b.date) : undefined),
                        )
                        .filter((event) => {
                            if (event.type === "Scan") {
                                return !!pentest.modules.external;
                            } else {
                                return true;
                            }
                        })
                        .map((event) => (
                            <TableRow key={event.type}>
                                {event.date === "" ? (
                                    <TableCell>
                                        <Badge color={"blue"}>{t("label.not-scheduled-yet")}</Badge>
                                    </TableCell>
                                ) : (
                                    <TableCell title={event.date && new Date(event.date).toLocaleString()}>
                                        {event.date && displayDeadline(new Date(event.date))}
                                    </TableCell>
                                )}
                                {event.type === "Report" ? (
                                    <TableCell>
                                        <div className={"flex flex-col"}>
                                            <span>{event.label}</span>
                                            <Text>{event.description}</Text>
                                        </div>
                                    </TableCell>
                                ) : (
                                    <TableCell>{event.label}</TableCell>
                                )}

                                <TableCell>
                                    {/*
                            {new Date() < event.date && event.type !== "Report" && event.type !== "Scan" ? (
                                <Dropdown>
                                    <DropdownButton plain={true}>
                                        <EllipsisVerticalIcon />
                                    </DropdownButton>
                                    <DropdownMenu anchor={"bottom end"}>
                                        <DropdownItem
                                            href={
                                                event.type === "Kickoff"
                                                    ? "/i/pentests/$projectId/schedule/change-kickoff-date"
                                                    : event.type === "Dialog"
                                                      ? "/i/pentests/$projectId/schedule/change-dialog-date"
                                                      : event.type === "Debrief"
                                                        ? "i/"
                                                        : "/i/pentests/$projectId/schedule/change-mgmt-date"
                                            }
                                            params={{ projectId: obj.uuid }}
                                        >
                                            {t("button.change-date")}
                                        </DropdownItem>
                                    </DropdownMenu>
                                </Dropdown>
                                    ) : (
                                        <div className={"h-9"}></div>
                                    )}*/}
                                </TableCell>
                            </TableRow>
                        ))}
                </TableBody>
            </Table>
        </div>
    );
}

export const Route = createFileRoute("/_internal/i/pentests/$projectId/_pentest/schedule/")({
    component: PentestProjectSchedule,
});
