import { createFileRoute, Outlet } from "@tanstack/react-router";

import React from "react";
import TabLayout from "src/components/base/tab-layout";
import { useTranslation } from "react-i18next";
import { Tab, TabMenu } from "src/components/base/tab-menu";
import { PentestProjectProvider } from "src/context/pentest-project-internal";

/**
 * The properties for {@link PentestProjectLayout}
 */
export type PentestProjectLayoutProps = {};

/**
 * The layout for the pentest project
 */
export default function PentestProjectLayout(props: PentestProjectLayoutProps) {
    const [t] = useTranslation();
    const { projectId } = Route.useParams();

    return (
        <PentestProjectProvider pentestProject={projectId} role={"Customer"}>
            <TabLayout
                heading={"Pentest Projekt"}
                tabs={
                    <TabMenu>
                        <Tab href={"/c/projects/pentests/$projectId/general"}>{t("label.general")}</Tab>
                        <Tab href={"/c/projects/pentests/$projectId/files"}>{t("label.files")}</Tab>
                        <Tab href={"/c/projects/pentests/$projectId/contacts"}>{t("label.contacts")}</Tab>
                        <Tab href={"/c/projects/pentests/$projectId/schedule"}>{t("label.schedule")}</Tab>
                    </TabMenu>
                }
            >
                <Outlet />
            </TabLayout>
        </PentestProjectProvider>
    );
}

export const Route = createFileRoute("/_customer/c/projects/pentests/$projectId/_pentest")({
    component: PentestProjectLayout,
});
