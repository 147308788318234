import React, { useEffect } from "react";
import { InternalGetReadyTasksResponse } from "../api/generated";
import { Api } from "../api/api";
import { toast } from "react-toastify";
import WS from "../api/ws";

/** Data provided by the {@link INTERNAL_TASK_CONTEXT} */
export type InternalTaskContext = {
    /** The current tasks of the customer */
    tasks: InternalGetReadyTasksResponse;

    /** Reload the customer's tasks */
    reset: () => void;
};

/** {@link React.Context} to access tasks */
const INTERNAL_TASK_CONTEXT = React.createContext<InternalTaskContext>({
    tasks: { my_tasks: [], pool_tasks: [] },

    /**
     * Reset the user's information
     */
    reset: () => {},
});
INTERNAL_TASK_CONTEXT.displayName = "InternalTasksContext";
export default INTERNAL_TASK_CONTEXT;

/**
 * The properties for {@link CustomerTaskProvider}
 */
export type InternalTaskProviderProps = {
    /** The children of the provider */
    children: React.ReactNode;
};

/**
 * The task provider for a customer
 */
export function InternalTaskProvider(props: InternalTaskProviderProps) {
    const [tasks, setTasks] = React.useState<InternalGetReadyTasksResponse>({
        my_tasks: [],
        pool_tasks: [],
    });

    /**
     * Helper function to fetch the tasks of the user
     */
    const fetchReadyTasks = async () => {
        const res = await Api.internal.tasks.ready();

        res.match(
            (tasks) => setTasks(tasks),
            (err) => toast.error(err.message),
        );
    };

    useEffect(() => {
        const handler = WS.addEventListener("message.ReloadTasks", () => fetchReadyTasks());
        fetchReadyTasks().then();

        return () => {
            WS.removeEventListener(handler);
        };
    }, []);

    return (
        <INTERNAL_TASK_CONTEXT.Provider
            value={{
                tasks,
                reset: fetchReadyTasks,
            }}
        >
            {props.children}
        </INTERNAL_TASK_CONTEXT.Provider>
    );
}
