/* tslint:disable */
/* eslint-disable */
/**
 * Unnamed API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ApiErrorResponse,
  CreateOfferPotentialTopicRequest,
  FormResultForNullAndUpdateOfferPotentialTopicErrors,
  FormResultForSingleUuidAndCreateOfferPotentialTopicErrors,
  ListForSimpleOfferPotentialTopic,
  UpdateOfferPotentialTopicRequest,
} from '../models/index';

export interface CreateOfferPotentialTopicOperationRequest {
    CreateOfferPotentialTopicRequest: CreateOfferPotentialTopicRequest;
}

export interface UpdateOfferPotentialTopicOperationRequest {
    uuid: string;
    UpdateOfferPotentialTopicRequest: UpdateOfferPotentialTopicRequest;
}

/**
 * 
 */
export class OfferPotentialTopicsApi extends runtime.BaseAPI {

    /**
     * Creates a new topic for offer potentials
     * Creates a new topic for offer potentials
     */
    async createOfferPotentialTopicRaw(requestParameters: CreateOfferPotentialTopicOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FormResultForSingleUuidAndCreateOfferPotentialTopicErrors>> {
        if (requestParameters['CreateOfferPotentialTopicRequest'] == null) {
            throw new runtime.RequiredError(
                'CreateOfferPotentialTopicRequest',
                'Required parameter "CreateOfferPotentialTopicRequest" was null or undefined when calling createOfferPotentialTopic().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/frontend/v1/internal/offer-potential-topics/topic`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['CreateOfferPotentialTopicRequest'],
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Creates a new topic for offer potentials
     * Creates a new topic for offer potentials
     */
    async createOfferPotentialTopic(requestParameters: CreateOfferPotentialTopicOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FormResultForSingleUuidAndCreateOfferPotentialTopicErrors> {
        const response = await this.createOfferPotentialTopicRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Gets all topics for offer potentials
     * Gets all topics for offer potentials
     */
    async getOfferPotentialTopicsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListForSimpleOfferPotentialTopic>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/frontend/v1/internal/offer-potential-topics/topic`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Gets all topics for offer potentials
     * Gets all topics for offer potentials
     */
    async getOfferPotentialTopics(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListForSimpleOfferPotentialTopic> {
        const response = await this.getOfferPotentialTopicsRaw(initOverrides);
        return await response.value();
    }

    /**
     * Updates any existing topic\'s name
     * Updates any existing topic\'s name
     */
    async updateOfferPotentialTopicRaw(requestParameters: UpdateOfferPotentialTopicOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FormResultForNullAndUpdateOfferPotentialTopicErrors>> {
        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling updateOfferPotentialTopic().'
            );
        }

        if (requestParameters['UpdateOfferPotentialTopicRequest'] == null) {
            throw new runtime.RequiredError(
                'UpdateOfferPotentialTopicRequest',
                'Required parameter "UpdateOfferPotentialTopicRequest" was null or undefined when calling updateOfferPotentialTopic().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/frontend/v1/internal/offer-potential-topics/topic/{uuid}`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['UpdateOfferPotentialTopicRequest'],
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Updates any existing topic\'s name
     * Updates any existing topic\'s name
     */
    async updateOfferPotentialTopic(requestParameters: UpdateOfferPotentialTopicOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FormResultForNullAndUpdateOfferPotentialTopicErrors> {
        const response = await this.updateOfferPotentialTopicRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
