import { createFileRoute } from "@tanstack/react-router";
import { useTranslation } from "react-i18next";
import React, { useEffect } from "react";
import PENTEST_PROJECT_CONTEXT from "src/context/pentest-project-internal";
import { FullPentestProject, InternalUser, SimpleUser } from "src/api/generated";
import { Field, FieldGroup, Fieldset, Label, Legend } from "src/components/base/fieldset";
import { Button } from "src/components/base/button";
import { ChevronLeftIcon, TrashIcon } from "@heroicons/react/20/solid";
import { ComboboxOption, StatelessCombobox } from "src/components/base/combobox";
import { Api } from "src/api/api";
import { toast } from "react-toastify";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "src/components/base/table";

/**
 * The properties for {@link EditTechnicalAssistants}
 */
export type EditTechnicalAssistantsProps = {};

/**
 * Update technical assistants in pentest project
 */
function EditTechnicalAssistants(props: EditTechnicalAssistantsProps) {
    const [t] = useTranslation();

    const { obj, reset } = React.useContext(PENTEST_PROJECT_CONTEXT);
    const project = obj as FullPentestProject;

    const [internalList, setInternalList] = React.useState<Array<InternalUser>>([]);
    const [techAssistantQuery, setTechAssistantQuery] = React.useState("");
    const [techAssistants, setTechAssistants] = React.useState<Array<SimpleUser>>(project.technical_assistants);

    const techs = internalList.filter((c) => c.groups.technical);
    const filteredTechAssistants =
        techAssistantQuery === ""
            ? techs
                  .filter((user) => !techAssistants.some((ta) => ta.uuid === user.uuid))
                  .filter((user) => user.uuid !== project.technical_lead.uuid)
            : techs
                  .filter((user) => !techAssistants.some((ta) => ta.uuid === user.uuid))
                  .filter((user) => user.display_name.toLowerCase().includes(techAssistantQuery.toLowerCase()));

    useEffect(() => {
        Api.internal.internalUsers.all().then((res) =>
            res.match(
                (list) => setInternalList(list.list),
                (err) => toast.error(err.message),
            ),
        );
    }, []);

    /**
     * Api call to update the technical assistants
     *
     * @param assistants assistant list to update
     */
    const updateTechnicalAssistants = async (assistants: SimpleUser[]) => {
        const update = assistants.map((ta) => {
            return ta.uuid;
        });
        await Api.internal.projects.pentest
            .update(project.uuid, {
                technical_assistant: update,
            })
            .then((res) => {
                res.match(
                    (res) => {
                        if (res.result === "Ok") {
                            reset();
                        }
                    },
                    (err) => toast.error(err.message),
                );
            });
    };

    return (
        <Fieldset className={"max-w-lg"}>
            <div className={"flex items-center gap-3"}>
                <Button plain={true} onClick={() => window.history.back()} params={{ projectId: obj.uuid }}>
                    <ChevronLeftIcon />
                </Button>
                <Legend>{t("label.edit-ta")}</Legend>
            </div>

            <FieldGroup>
                <Field>
                    <StatelessCombobox<SimpleUser | null>
                        onChange={(ta) => {
                            if (ta !== null) {
                                const assistants = [...techAssistants, ta];
                                setTechAssistants(assistants);
                                updateTechnicalAssistants(assistants).then();
                            }
                        }}
                        onClose={() => setTechAssistantQuery("")}
                        onQueryChange={(query) => setTechAssistantQuery(query)}
                    >
                        {filteredTechAssistants.map((ta) => (
                            <ComboboxOption value={ta}>
                                <Label>{ta.display_name}</Label>
                            </ComboboxOption>
                        ))}
                    </StatelessCombobox>
                    {techAssistants.length !== 0 && (
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableHeader>{t("label.name")}</TableHeader>
                                    <TableHeader className={"w-0"}>
                                        <span className={"sr-only"}>{t("accessibility.actions")}</span>
                                    </TableHeader>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {techAssistants.map((ta) => (
                                    <TableRow key={ta.uuid}>
                                        <TableCell>{ta.display_name}</TableCell>
                                        <TableCell>
                                            <Button
                                                plain={true}
                                                onClick={() => {
                                                    const assistants = [
                                                        ...techAssistants.filter((user) => user.uuid !== ta.uuid),
                                                    ];
                                                    setTechAssistants(assistants);
                                                    updateTechnicalAssistants(assistants).then();
                                                }}
                                            >
                                                <TrashIcon />
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    )}
                </Field>
            </FieldGroup>
        </Fieldset>
    );
}

export const Route = createFileRoute("/_internal/i/pentests/$projectId/_pentest/general/edit-ta")({
    component: EditTechnicalAssistants,
});
