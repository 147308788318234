import { createFileRoute } from "@tanstack/react-router";

import React from "react";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "../../../../../components/base/table";
import INTERNAL_TASK_CONTEXT from "../../../../../context/tasks-internal";
import { useTranslation } from "react-i18next";
import { sortDates } from "../../../../../utils/sorter";

/**
 * The properties for {@link OpenTasks}
 */
export type OpenTasksProps = {};

/**
 * The claimable tasks of the user
 */
export default function OpenTasks(props: OpenTasksProps) {
    const [t] = useTranslation();
    const { tasks } = React.useContext(INTERNAL_TASK_CONTEXT);

    return (
        <Table>
            <TableHead>
                <TableRow>
                    <TableHeader>{t("label.due-until")}</TableHeader>
                    <TableHeader>{t("label.task")}</TableHeader>
                    <TableHeader>{t("label.project")}</TableHeader>
                    <TableHeader>{t("label.legal-business-name")}</TableHeader>
                </TableRow>
            </TableHead>
            <TableBody>
                {tasks.pool_tasks
                    .sort((a, b) =>
                        sortDates(
                            a.deadline ? new Date(a.deadline) : undefined,
                            b.deadline ? new Date(b.deadline) : undefined,
                        ),
                    )
                    .map((task) => (
                        <TableRow key={task.uuid}>
                            <TableCell>{task.deadline && new Date(task.deadline).toLocaleString()}</TableCell>
                            <TableCell>{task.data.kind}</TableCell>
                            <TableCell>{JSON.stringify(task.data)}</TableCell>
                            <TableCell>{task.project?.customer.legal_name}</TableCell>
                        </TableRow>
                    ))}
            </TableBody>
        </Table>
    );
}

export const Route = createFileRoute("/_internal/i/_tasks/tasks/pool")({
    component: OpenTasks,
});
