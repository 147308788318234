import { createFileRoute } from "@tanstack/react-router";

import React from "react";
import PENTEST_PROJECT_CONTEXT from "src/context/pentest-project-internal";
import { CustomerFullPentestProject } from "src/api/generated";
import { useTranslation } from "react-i18next";
import { sortDates } from "src/utils/sorter";
import StepBar from "src/components/base/step-bar";

/**
 * The properties for {@link Timeline}
 */
export type TimelineProps = {};

/**
 * The representation of a single event
 */
type Event = {
    /**
     * Title of the event
     */
    title: string;
    /**
     * Point in time
     */
    date: Date;
};

/**
 * The timeline view for the customer
 */
function Timeline(props: TimelineProps) {
    const [t] = useTranslation();

    const { obj } = React.useContext(PENTEST_PROJECT_CONTEXT);
    const project = obj as CustomerFullPentestProject;

    const dates: Array<Event> = [];
    project.kickoff && dates.push({ title: t("label.kickoff"), date: new Date(project.kickoff) });
    project.scan && dates.push({ title: t("label.scan-date"), date: new Date(project.scan) });
    project.dialog && dates.push({ title: t("label.dialog"), date: new Date(project.dialog) });
    project.debrief && dates.push({ title: t("label.debrief"), date: new Date(project.debrief) });
    project.mgmt_presentation &&
        dates.push({
            title: t("label.mgmt-presentation"),
            date: new Date(project.mgmt_presentation),
        });

    const sorted = dates.sort((a, b) => sortDates(a.date, b.date));

    const now = new Date();
    return (
        <div>
            <StepBar
                steps={sorted.map((event) => ({
                    label: event.title,
                    description: event.date.toLocaleString(),
                    state:
                        new Date(event.date.getTime() + 1000 * 60 * 30) < now
                            ? "finished"
                            : event.date < now
                              ? "active"
                              : "pending",
                }))}
            />
        </div>
    );
}

export const Route = createFileRoute("/_customer/c/projects/pentests/$projectId/_pentest/schedule")({
    component: Timeline,
});
