import { Badge } from "../components/base/badge";
import React from "react";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";

/**
 * Display the deadline in different ways, depending on urgency
 *
 * @param deadline The deadline to display
 *
 * @returns The rendered deadline
 */
export function displayDeadline(deadline: Date) {
    const now = new Date();
    const tomorrow = new Date(now.getDate() + 1);

    dayjs.extend(relativeTime);
    const date = dayjs(deadline).fromNow();

    if (now.getTime() - deadline.getTime() > 0) {
        if (tomorrow.getTime() - deadline.getTime() < 0) {
            return <Badge color={"red"}>{date}</Badge>;
        } else {
            return <Badge color={"red"}>{date}</Badge>;
        }
    } else if (now.getTime() - deadline.getTime() < 0 && tomorrow.getTime() - deadline.getTime() > 0) {
        return <Badge color={"yellow"}>{date}</Badge>;
    } else {
        return <Badge color={"green"}>{date}</Badge>;
    }
}
