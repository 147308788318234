import { createFileRoute } from "@tanstack/react-router";

import React, { useEffect, useState } from "react";
import HeadingLayout from "../../../../components/base/heading-layout";
import { useTranslation } from "react-i18next";
import { Api, UUID } from "../../../../api/api";
import { toast } from "react-toastify";
import { SimpleCustomer } from "../../../../api/generated";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "../../../../components/base/table";
import { Button } from "../../../../components/base/button";
import { EllipsisVerticalIcon, PlusIcon } from "@heroicons/react/20/solid";
import DialogCreateCustomer from "../../../../components/dialogs/internal/dialog-create-customer";
import { Dropdown, DropdownButton, DropdownItem, DropdownMenu } from "../../../../components/base/dropdown";
import { Dialog, DialogActions, DialogBody, DialogTitle } from "../../../../components/base/dialog";
import UserAvatar from "../../../../components/user-avatar";

/**
 * The properties for {@link CustomerManagement}
 */
export type CustomerManagementProps = {};

/**
 * The management view for customers
 */
export default function CustomerManagement(props: CustomerManagementProps) {
    const [t] = useTranslation();

    const [customers, setCustomers] = useState<Array<SimpleCustomer>>([]);

    const [openDeleteCustomerDialog, setOpenDeleteCustomerDialog] = useState<UUID>();
    const [openNewCustomerDialog, setOpenNewCustomerDialog] = useState(false);

    /** Load the customers from the API */
    const loadCustomers = () => {
        Api.internal.customers.all().then((res) =>
            res.match(
                (list) => setCustomers(list.list),
                (err) => toast.error(err.message),
            ),
        );
    };

    /**
     * Get the name of a customer that matches the uuid
     *
     * @param uuid of the customer
     * @returns name of the customer
     */
    const getCustomerName = (uuid: UUID) => {
        const customer = customers.map((c) => {
            if (c.uuid === uuid) return c;
        });
        if (customer.length === 1 && customer[0] !== undefined) {
            return customer[0].legal_name;
        }
    };

    useEffect(() => {
        loadCustomers();
    }, []);

    return (
        <HeadingLayout
            heading={t("internal.customer.heading.customer-management")}
            headingChildren={
                <Button onClick={() => setOpenNewCustomerDialog(true)}>
                    <PlusIcon />
                    {t("internal.customer.button.create-customer")}
                </Button>
            }
        >
            <Table dense={true} className={"dark:text-zinc-200"}>
                <TableHead>
                    <TableRow>
                        <TableHeader>
                            <span className={"block text-wrap"}>{t("label.legal-business-name")}</span>
                        </TableHeader>
                        <TableHeader>{t("label.project-manager")}</TableHeader>
                        <TableHeader className={"max-lg:hidden"}>{t("label.created-at")}</TableHeader>
                        <TableHeader className={"w-0"}>
                            <span className={"sr-only"}>{t("label.actions")}</span>
                        </TableHeader>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {customers.map((customer) => (
                        <TableRow
                            key={customer.uuid}
                            href={"/i/customers/$customerId/general"}
                            params={{ customerId: customer.uuid }}
                        >
                            <TableCell>{customer.legal_name}</TableCell>
                            <TableCell>
                                <div className={"flex items-center gap-3"}>
                                    <UserAvatar
                                        className={"size-8"}
                                        user={{
                                            ...customer.project_manager,
                                            name: customer.project_manager.display_name,
                                        }}
                                    />
                                    <span>{customer.project_manager.display_name}</span>
                                </div>
                            </TableCell>
                            <TableCell className={"max-lg:hidden"}>
                                {new Date(customer.created_at).toLocaleDateString()}
                            </TableCell>
                            <TableCell>
                                <Dropdown>
                                    <DropdownButton plain={true}>
                                        <EllipsisVerticalIcon />
                                    </DropdownButton>
                                    <DropdownMenu anchor={"bottom end"}>
                                        <DropdownItem href={"/i/projects/create"} search={{ customer: customer.uuid }}>
                                            {t("button.create-project")}
                                        </DropdownItem>
                                        <DropdownItem onClick={() => setOpenDeleteCustomerDialog(customer.uuid)}>
                                            {t("button.delete")}
                                        </DropdownItem>
                                    </DropdownMenu>
                                </Dropdown>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>

            {openNewCustomerDialog && (
                <DialogCreateCustomer
                    open={true}
                    onClose={() => setOpenNewCustomerDialog(false)}
                    onSubmit={() => {
                        setOpenNewCustomerDialog(false);
                        loadCustomers();
                    }}
                />
            )}

            {openDeleteCustomerDialog && (
                <Dialog open={true} onClose={() => setOpenDeleteCustomerDialog(undefined)}>
                    <DialogBody>
                        <DialogTitle>
                            {t("internal.delete-customer.heading", { name: getCustomerName(openDeleteCustomerDialog) })}
                        </DialogTitle>
                        <DialogActions>
                            <Button onClick={() => setOpenDeleteCustomerDialog(undefined)}>{t("button.no")}</Button>
                            <Button
                                color={"red"}
                                onClick={() => {
                                    toast.info("Deleting customers is currently unsupported");
                                    setOpenDeleteCustomerDialog(undefined);
                                }}
                            >
                                {t("button.yes")}
                            </Button>
                        </DialogActions>
                    </DialogBody>
                </Dialog>
            )}
        </HeadingLayout>
    );
}

export const Route = createFileRoute("/_internal/i/customers/")({
    component: CustomerManagement,
});
