import clsx from "clsx";
import { Text } from "./text";

/**
 * Props for a {@link Divider}
 */
export type DividerProps = {
    /** Show a softer line */
    soft?: boolean;
} & React.ComponentPropsWithoutRef<"hr">;

/**
 * A line.
 */
export function Divider({ soft = false, className, ...props }: DividerProps) {
    return (
        <hr
            {...props}
            className={clsx(
                className,
                "w-full border-t",
                soft && "border-zinc-950/5 dark:border-white/5",
                !soft && "border-zinc-950/10 dark:border-white/10",
            )}
        />
    );
}

/**
 * The props for {@link DividerText}
 */
export type DividerTextProps = {
    /** The text that should be in between both Dividers */
    text: string;
} & DividerProps;

/**
 *  A divider with text inside
 */
export function DividerText({ text, ...props }: DividerTextProps) {
    return (
        <span className={"flex items-center gap-3"}>
            <Divider {...props} />
            <Text>{text}</Text>
            <Divider {...props} />
        </span>
    );
}
