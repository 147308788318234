import React from "react";
import { FullQuestionData } from "../../api/generated";
import { InformationCircleIcon } from "@heroicons/react/24/outline";
import { CheckCircleIcon } from "@heroicons/react/16/solid";

/**
 * The properties for {@link QuestionTypeIcon}
 */
export type QuestionTypeIconProps = {
    /** The classname to set */
    className?: string;
    /** The type of the question */
    type: FullQuestionData["kind"];
};

/**
 * The icon for a question type
 */
export default function QuestionTypeIcon(props: QuestionTypeIconProps) {
    const { type, className } = props;

    return type === "Info" ? (
        <InformationCircleIcon className={className} />
    ) : type === "Boolean" ? (
        <CheckCircleIcon className={className} />
    ) : type === "Number" ? (
        <div>{"1"}</div>
    ) : type === "SingleChoice" ? (
        "Single Choice"
    ) : (
        "Multiple Choices"
    );
}
