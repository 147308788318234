import { createFileRoute } from "@tanstack/react-router";

import React from "react";

/**
 * The properties for {@link AdminDashboard}
 */
export type AdminDashboardProps = {};

/**
 * The dashboard for administrative users
 */
function AdminDashboard(props: AdminDashboardProps) {
    return <div></div>;
}

export const Route = createFileRoute("/_admin/a/dashboard")({
    component: AdminDashboard,
});
