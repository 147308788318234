import React, { useEffect } from "react";
import { Dialog, DialogActions, DialogProps, DialogTitle } from "../../base/dialog";
import { useTranslation } from "react-i18next";
import { Description, ErrorMessage, Field, FieldGroup, Fieldset, Label, RequiredLabel } from "../../base/fieldset";
import { Input } from "../../base/input";
import { Button } from "../../base/button";
import { Listbox, ListboxLabel, ListboxOption } from "../../base/listbox";
import { Checkbox, CheckboxField, CheckboxGroup } from "../../base/checkbox";
import { Api } from "../../../api/api";
import { AdminCustomer, InternalGroups } from "../../../api/generated";
import { toast } from "react-toastify";
import { AnimatePresence, motion } from "framer-motion";
import Form from "../../base/form";
import LanguageSelect from "../../base/language-select";
import { Combobox, ComboboxOption } from "../../base/combobox";

/** The available roles */
type Roles = "Administrator" | "Internal" | "Customer";

/**
 * The properties for {@link CreateUserDialog}
 */
export type CreateUserDialogProps = DialogProps;

/**
 * The dialogs to create a new user
 */
export default function CreateUserDialog(props: CreateUserDialogProps) {
    const [t] = useTranslation();

    const [mail, setMail] = React.useState("");
    const [mailError, setMailError] = React.useState<string>();

    const [name, setName] = React.useState("");
    const [role, setRole] = React.useState<Roles>("Administrator");
    const [preferredLang, setPreferredLang] = React.useState<"EN" | "DE">("DE");
    const [groups, setGroups] = React.useState<InternalGroups>({
        technical: false,
        project_management: false,
        billing: false,
        sales: false,
        quality_assurance: false,
        support: false,
        power_user: false,
    });

    const [customerList, setCustomerList] = React.useState<Array<AdminCustomer>>([]);
    const [customerQuery, setCustomerQuery] = React.useState("");
    const [customerCurrent, setCustomerCurrent] = React.useState<AdminCustomer | null>(null);

    /**
     * Create the invite_ for the user
     */
    const createInvite = () => {
        if (role === "Customer" && customerCurrent === undefined) {
            return;
        }

        Api.admin.userInvites
            .create({
                mail,
                display_name: name,
                preferred_lang: preferredLang,
                permissions:
                    role === "Administrator"
                        ? { role }
                        : role === "Customer"
                          ? {
                                role,
                                // Checked before
                                customer: customerCurrent!.uuid,
                            }
                          : { role, groups },
            })
            .then((res) =>
                res.match(
                    (invite) => {
                        if (invite.result === "Ok") {
                            navigator.clipboard.writeText(invite.value.link).then(() => {
                                toast.success(t("admin.user-creation.toast.added-link-to-clipboard"));
                                props.onClose(false);
                            });
                        } else {
                            if (invite.error.mail) {
                                setMailError(t("admin.user-creation.error.already-user"));
                            }
                        }
                    },
                    (err) => toast.error(err.message),
                ),
            );
    };

    useEffect(() => {
        Api.admin.customers.all().then((res) =>
            res.match(
                (list) => setCustomerList(list.list),
                (err) => toast.error(err.message),
            ),
        );
    }, []);

    const filteredCustomers =
        customerQuery === ""
            ? customerList
            : customerList.filter((c) => c.legal_name.toLowerCase().includes(customerQuery.toLowerCase()));

    return (
        <Dialog className={"overflow-clip"} {...props}>
            <Form onSubmit={createInvite}>
                <Fieldset>
                    <DialogTitle className={"mb-3"}>{t("admin.user-creation.heading")}</DialogTitle>
                    <Description>{t("admin.user-creation.label.submit")}</Description>
                    <FieldGroup>
                        <Field>
                            <RequiredLabel>{t("label.mail")}</RequiredLabel>
                            <Input
                                autoFocus={true}
                                type={"email"}
                                invalid={mailError !== undefined}
                                value={mail}
                                onChange={(e) => setMail(e.target.value)}
                                required={true}
                            />
                            {mailError && <ErrorMessage>{mailError}</ErrorMessage>}
                        </Field>

                        <Field>
                            <RequiredLabel>{t("label.name")}</RequiredLabel>
                            <Input
                                type={"text"}
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                required={true}
                            />
                        </Field>

                        <Field>
                            <RequiredLabel>{t("label.preferred-lang")}</RequiredLabel>
                            <LanguageSelect lang={preferredLang} setLang={setPreferredLang} />
                        </Field>

                        <Field>
                            <RequiredLabel>{t("label.role")}</RequiredLabel>

                            <Listbox value={role} onChange={(e) => setRole(e)}>
                                <ListboxOption value={"Administrator"}>
                                    <ListboxLabel>{t("Administrator")}</ListboxLabel>
                                </ListboxOption>
                                <ListboxOption value={"Internal"}>
                                    <ListboxLabel>{t("Internal")}</ListboxLabel>
                                </ListboxOption>
                                <ListboxOption value={"Customer"}>
                                    <ListboxLabel>{t("Customer")}</ListboxLabel>
                                </ListboxOption>
                            </Listbox>
                        </Field>

                        <AnimatePresence>
                            {role === "Internal" && (
                                <motion.div
                                    initial={{ x: 300, opacity: 0 }}
                                    animate={{ x: 0, opacity: 1 }}
                                    exit={{ x: -300, opacity: 0 }}
                                >
                                    <CheckboxGroup>
                                        <RequiredLabel>{t("label.groups")}</RequiredLabel>
                                        <CheckboxField>
                                            <Checkbox
                                                color={"blue"}
                                                onChange={(e) => setGroups({ ...groups, sales: e })}
                                            />
                                            <Label>{t("label.sales")}</Label>
                                        </CheckboxField>
                                        <CheckboxField>
                                            <Checkbox
                                                color={"blue"}
                                                onChange={(e) =>
                                                    setGroups({
                                                        ...groups,
                                                        quality_assurance: e,
                                                    })
                                                }
                                            />
                                            <Label>{t("label.qa")}</Label>
                                        </CheckboxField>
                                        <CheckboxField>
                                            <Checkbox
                                                color={"blue"}
                                                onChange={(e) =>
                                                    setGroups({
                                                        ...groups,
                                                        technical: e,
                                                    })
                                                }
                                            />
                                            <Label>{t("label.technical")}</Label>
                                        </CheckboxField>
                                        <CheckboxField>
                                            <Checkbox
                                                color={"blue"}
                                                onChange={(e) =>
                                                    setGroups({
                                                        ...groups,
                                                        billing: e,
                                                    })
                                                }
                                            />
                                            <Label>{t("label.billing")}</Label>
                                        </CheckboxField>
                                        <CheckboxField>
                                            <Checkbox
                                                color={"blue"}
                                                onChange={(e) =>
                                                    setGroups({
                                                        ...groups,
                                                        project_management: e,
                                                    })
                                                }
                                            />
                                            <Label>{t("label.project-management")}</Label>
                                        </CheckboxField>
                                        <CheckboxField>
                                            <Checkbox
                                                color={"blue"}
                                                onChange={(e) =>
                                                    setGroups({
                                                        ...groups,
                                                        support: e,
                                                    })
                                                }
                                            />
                                            <Label>{t("label.support")}</Label>
                                        </CheckboxField>
                                    </CheckboxGroup>
                                </motion.div>
                            )}

                            {role === "Customer" && (
                                <motion.div
                                    initial={{ x: 300, opacity: 0 }}
                                    animate={{ x: 0, opacity: 1 }}
                                    exit={{ x: -300, opacity: 0 }}
                                >
                                    <Field>
                                        <RequiredLabel>{t("label.customer")}</RequiredLabel>
                                        <Combobox
                                            value={customerCurrent}
                                            onChange={(c) => setCustomerCurrent(c)}
                                            onClose={() => setCustomerQuery("")}
                                            queryDisplay={(c) => (c ? c.legal_name : "")}
                                            onQueryChange={(query) => setCustomerQuery(query)}
                                        >
                                            {filteredCustomers.map((c) => (
                                                <ComboboxOption value={c}>
                                                    <Label>{c.legal_name}</Label>
                                                </ComboboxOption>
                                            ))}
                                        </Combobox>
                                    </Field>
                                </motion.div>
                            )}
                        </AnimatePresence>

                        <DialogActions>
                            <span className={"flex flex-col items-end gap-3"}>
                                <Button type={"submit"} color={"blue"}>
                                    {t("admin.user-creation.submit")}
                                </Button>
                            </span>
                        </DialogActions>
                    </FieldGroup>
                </Fieldset>
            </Form>
        </Dialog>
    );
}
