import React from "react";
import { Dialog, DialogActions, DialogBody, DialogProps, DialogTitle } from "src/components/base/dialog";
import { useTranslation } from "react-i18next";
import { useForm } from "@tanstack/react-form";
import Form from "src/components/base/form";
import { Button } from "src/components/base/button";
import { Field, FieldGroup, Fieldset, Label } from "src/components/base/fieldset";
import { Input } from "src/components/base/input";
import { SimpleQuestionChoice } from "src/api/generated";
import { Api } from "src/api/api";
import { toast } from "react-toastify";
import { Textarea } from "src/components/base/textarea";

/**
 * The properties for {@link DialogEditChoice}
 */
export type DialogEditChoiceProps = DialogProps & {
    /** The default value for the form */
    choice: SimpleQuestionChoice;

    /** The callback to execute when the form is submitted */
    onFormSubmit: (choice: SimpleQuestionChoice) => void;
};

/**
 * The dialog to edit a questionnaire choice
 */
export default function DialogEditChoice(props: DialogEditChoiceProps) {
    const [t] = useTranslation();

    const { open, onClose, choice, onFormSubmit } = props;

    const editForm = useForm({
        defaultValues: { title: choice.title, description: choice.description },
        // eslint-disable-next-line
        onSubmit: async ({ value }) => {
            const res = await Api.internal.questionnaires.questions.updateChoices(choice.uuid, {
                title: value.title,
                description: value.description,
            });

            res.match(
                () => onFormSubmit({ uuid: choice.uuid, title: value.title, description: value.description }),
                (err) => toast.error(err.message),
            );
        },
    });

    return (
        <Dialog open={open} onClose={onClose}>
            <Form onSubmit={editForm.handleSubmit}>
                <DialogTitle>{t("dialog.edit-questionnaire-choice.heading")}</DialogTitle>
                <DialogBody>
                    <Fieldset>
                        <FieldGroup>
                            <editForm.Field name={"title"}>
                                {(fieldApi) => (
                                    <Field>
                                        <Label>{t("label.questionnaire-choice-title")}</Label>
                                        <Input
                                            value={fieldApi.state.value}
                                            onChange={(e) => fieldApi.handleChange(e.target.value)}
                                        />
                                    </Field>
                                )}
                            </editForm.Field>

                            <editForm.Field name={"description"}>
                                {(fieldApi) => (
                                    <Field>
                                        <Label>{t("label.questionnaire-choice-description")}</Label>
                                        <Textarea
                                            value={fieldApi.state.value}
                                            onChange={(e) => fieldApi.handleChange(e.target.value)}
                                        />
                                    </Field>
                                )}
                            </editForm.Field>

                            <DialogActions>
                                <Button color={"blue"} type={"submit"}>
                                    {t("button.save")}
                                </Button>
                            </DialogActions>
                        </FieldGroup>
                    </Fieldset>
                </DialogBody>
            </Form>
        </Dialog>
    );
}
