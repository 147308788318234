/* tslint:disable */
/* eslint-disable */
/**
 * Unnamed API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ApiErrorResponse,
  CreateQuestionnaireTemplateRequest,
  FormResultForNullAndDeleteQuestionaireTemplateErrors,
  FormResultForNullAndUpdateQuestionnaireTemplateErrors,
  FormResultForSingleUuidAndCreateQuestionnaireTemplateErrors,
  ListForSimpleQuestionnaireTemplate,
  OptionalForFullQuestionnaireTemplate,
  UpdateQuestionnaireTemplateRequest,
} from '../models/index';

export interface CreateQuestionnaireTemplateOperationRequest {
    CreateQuestionnaireTemplateRequest: CreateQuestionnaireTemplateRequest;
}

export interface DeleteQuestionnaireTemplateRequest {
    uuid: string;
}

export interface GetQuestionnaireTemplateRequest {
    uuid: string;
}

export interface UpdateQuestionnaireTemplateOperationRequest {
    uuid: string;
    UpdateQuestionnaireTemplateRequest: UpdateQuestionnaireTemplateRequest;
}

/**
 * 
 */
export class QuestionnaireTemplatesApi extends runtime.BaseAPI {

    /**
     * Creates a new questionnaire template
     * Creates a new questionnaire template
     */
    async createQuestionnaireTemplateRaw(requestParameters: CreateQuestionnaireTemplateOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FormResultForSingleUuidAndCreateQuestionnaireTemplateErrors>> {
        if (requestParameters['CreateQuestionnaireTemplateRequest'] == null) {
            throw new runtime.RequiredError(
                'CreateQuestionnaireTemplateRequest',
                'Required parameter "CreateQuestionnaireTemplateRequest" was null or undefined when calling createQuestionnaireTemplate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/frontend/v1/internal/questionnaire/templates`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['CreateQuestionnaireTemplateRequest'],
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Creates a new questionnaire template
     * Creates a new questionnaire template
     */
    async createQuestionnaireTemplate(requestParameters: CreateQuestionnaireTemplateOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FormResultForSingleUuidAndCreateQuestionnaireTemplateErrors> {
        const response = await this.createQuestionnaireTemplateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Deletes a single unused questionnaire template
     * Deletes a single unused questionnaire template
     */
    async deleteQuestionnaireTemplateRaw(requestParameters: DeleteQuestionnaireTemplateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FormResultForNullAndDeleteQuestionaireTemplateErrors>> {
        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling deleteQuestionnaireTemplate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/frontend/v1/internal/questionnaire/templates/{uuid}`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Deletes a single unused questionnaire template
     * Deletes a single unused questionnaire template
     */
    async deleteQuestionnaireTemplate(requestParameters: DeleteQuestionnaireTemplateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FormResultForNullAndDeleteQuestionaireTemplateErrors> {
        const response = await this.deleteQuestionnaireTemplateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Retrieves all existing questionnaire templates
     * Retrieves all existing questionnaire templates
     */
    async getAllQuestionnaireTemplatesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListForSimpleQuestionnaireTemplate>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/frontend/v1/internal/questionnaire/templates`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Retrieves all existing questionnaire templates
     * Retrieves all existing questionnaire templates
     */
    async getAllQuestionnaireTemplates(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListForSimpleQuestionnaireTemplate> {
        const response = await this.getAllQuestionnaireTemplatesRaw(initOverrides);
        return await response.value();
    }

    /**
     * Retrieves a single existing questionnaire template
     * Retrieves a single existing questionnaire template
     */
    async getQuestionnaireTemplateRaw(requestParameters: GetQuestionnaireTemplateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OptionalForFullQuestionnaireTemplate>> {
        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling getQuestionnaireTemplate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/frontend/v1/internal/questionnaire/templates/{uuid}`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Retrieves a single existing questionnaire template
     * Retrieves a single existing questionnaire template
     */
    async getQuestionnaireTemplate(requestParameters: GetQuestionnaireTemplateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OptionalForFullQuestionnaireTemplate> {
        const response = await this.getQuestionnaireTemplateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Updates an existing questionnaire template  Currently, groups can only be updated if the template ist not used yet by any questionnaire.
     * Updates an existing questionnaire template
     */
    async updateQuestionnaireTemplateRaw(requestParameters: UpdateQuestionnaireTemplateOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FormResultForNullAndUpdateQuestionnaireTemplateErrors>> {
        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling updateQuestionnaireTemplate().'
            );
        }

        if (requestParameters['UpdateQuestionnaireTemplateRequest'] == null) {
            throw new runtime.RequiredError(
                'UpdateQuestionnaireTemplateRequest',
                'Required parameter "UpdateQuestionnaireTemplateRequest" was null or undefined when calling updateQuestionnaireTemplate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/frontend/v1/internal/questionnaire/templates/{uuid}`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['UpdateQuestionnaireTemplateRequest'],
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Updates an existing questionnaire template  Currently, groups can only be updated if the template ist not used yet by any questionnaire.
     * Updates an existing questionnaire template
     */
    async updateQuestionnaireTemplate(requestParameters: UpdateQuestionnaireTemplateOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FormResultForNullAndUpdateQuestionnaireTemplateErrors> {
        const response = await this.updateQuestionnaireTemplateRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
