import { useTranslation } from "react-i18next";
import { Dialog, DialogBody, DialogProps, DialogTitle } from "src/components/base/dialog";
import { Text } from "../base/text";
import React, { useEffect, useState } from "react";
import { Api } from "src/api/api";
import { toast } from "react-toastify";
import { useForm } from "@tanstack/react-form";
import Form from "src/components/base/form";
import { ErrorMessage, Field, FieldGroup, Fieldset, Label } from "src/components/base/fieldset";
import { Input } from "src/components/base/input";
import { Button } from "src/components/base/button";
import { ChevronLeftIcon, DeviceTabletIcon, FingerPrintIcon } from "@heroicons/react/20/solid";
import { authenticate } from "src/api/auth";
import { LocalTab, TabMenu } from "src/components/base/tab-menu";
import USER_CONTEXT from "src/context/user";
import { MdPassword } from "react-icons/md";

/**
 * The properties for {@link DialogSudoMode}
 */
export type DialogSudoModeProps = DialogProps & {
    /** Callback function that should be executed if successfully entered sudo mode*/
    onSubmit: () => void;
};

/**
 * The types to authenticate to enter sudo mode
 */
type AuthenticationType = "password" | "password-less" | "totp";

/**
 * Dialog to enter sudo mode
 */
export default function DialogSudoMode(props: DialogSudoModeProps) {
    const { onSubmit } = props;
    const { user } = React.useContext(USER_CONTEXT);
    const [t] = useTranslation();

    const [password, setPassword] = useState(false);
    const [totp, setTotp] = useState(false);
    const [webauthn, setWebauthn] = useState(false);
    const [tab, setTab] = React.useState<AuthenticationType>("password-less");

    useEffect(() => {
        Api.common.sudoMode.getOptions().then((res) =>
            res.match(
                (res) => {
                    setPassword(res.password);
                    setTotp(res.totp);
                    setWebauthn(res.webauthn);
                    setTab(res.webauthn ? "password-less" : res.password ? "password" : "totp");
                },
                (err) => toast.error(err.message),
            ),
        );
    }, []);

    const passwordForm = useForm({
        defaultValues: {
            password: "",
        },
        // eslint-disable-next-line
        onSubmit: async ({ value, formApi }) => {
            Api.common.sudoMode.passwordVerify(value.password).then((res) =>
                res.match(
                    (res) => {
                        if (res.result === "Ok") {
                            onSubmit();
                        } else {
                            toast.error(t("error.wrong-pw"));
                            formApi.setFieldMeta("password", (meta) => {
                                meta.errors.push(t("error.wrong-pw"));
                                return meta;
                            });
                        }
                    },
                    (err) => toast.error(err.message),
                ),
            );
        },
    });

    const totpForm = useForm({
        defaultValues: { token: "" },
        // eslint-disable-next-line
        onSubmit: async ({ value, formApi }) => {
            Api.common.sudoMode.totpVerify(value.token).then((res) =>
                res.match(
                    (res) => {
                        if (res.result === "Ok") {
                            onSubmit();
                        } else {
                            toast.error(t("error.invalid-token"));
                            formApi.setFieldMeta("token", (meta) => {
                                meta.errors.push(t("error.invalid-token"));
                                return meta;
                            });
                        }
                    },
                    (err) => toast.error(err.message),
                ),
            );
        },
    });

    const webauthnForm = useForm({
        /** call to authenticate user using webauthn */
        onSubmit: async () => {
            authenticate("sudo", { undefined }).then((res) =>
                res.match(
                    () => {
                        onSubmit();
                    },
                    (err) => toast.error(err.message),
                ),
            );
        },
    });

    return (
        <Dialog {...props}>
            <DialogTitle className={"mb-3"}>{t("dialog.sudo-mode.heading")}</DialogTitle>
            <Text>{t("dialog.sudo-mode.description")}</Text>
            <div className={"mt-4"}>
                <TabMenu>
                    {webauthn && (
                        <LocalTab active={tab === "password-less"} onClick={() => setTab("password-less")}>
                            <FingerPrintIcon className={"h-5"} />
                        </LocalTab>
                    )}
                    {password && (
                        <LocalTab active={tab === "password"} onClick={() => setTab("password")}>
                            <MdPassword className={"h-5 w-full"} />
                        </LocalTab>
                    )}
                    {totp && (
                        <LocalTab active={tab === "totp"} onClick={() => setTab("totp")}>
                            <DeviceTabletIcon className={"h-5"} />
                        </LocalTab>
                    )}
                </TabMenu>
            </div>

            <DialogBody>
                {tab === "password" && (
                    <Form onSubmit={passwordForm.handleSubmit}>
                        <Fieldset>
                            <FieldGroup>
                                <passwordForm.Field name={"password"}>
                                    {(fieldApi) => (
                                        <Field>
                                            <Label>{t("dialog.sudo-mode.label.old-pw")}</Label>
                                            <Input
                                                type={"password"}
                                                required={true}
                                                value={fieldApi.state.value}
                                                invalid={fieldApi.state.meta.errors.length > 0}
                                                onChange={(e) => fieldApi.handleChange(e.target.value)}
                                            />
                                            {fieldApi.state.meta.errors.map((e) => (
                                                <ErrorMessage>{e}</ErrorMessage>
                                            ))}
                                        </Field>
                                    )}
                                </passwordForm.Field>
                                <Button type={"submit"} color={"blue"}>
                                    {t("button.confirm")}
                                </Button>
                            </FieldGroup>
                        </Fieldset>
                    </Form>
                )}

                {tab === "totp" && (
                    <Form onSubmit={totpForm.handleSubmit}>
                        <Fieldset>
                            <FieldGroup>
                                <totpForm.Field name={"token"}>
                                    {(fieldApi) => (
                                        <Field>
                                            <Label>{t("label.totp-token")}</Label>
                                            <Input
                                                required={true}
                                                value={fieldApi.state.value}
                                                invalid={fieldApi.state.meta.errors.length > 0}
                                                onChange={(e) => fieldApi.handleChange(e.target.value)}
                                            />
                                        </Field>
                                    )}
                                </totpForm.Field>
                                <Button type={"submit"} color={"blue"}>
                                    {t("button.verify")}
                                </Button>
                            </FieldGroup>
                        </Fieldset>
                    </Form>
                )}

                {tab === "password-less" && (
                    <Form onSubmit={webauthnForm.handleSubmit}>
                        <Fieldset>
                            <FieldGroup>
                                <webauthnForm.Field name={"security-key"}>
                                    {() => (
                                        <Field>
                                            <Label>{t("label.security-key")}</Label>
                                            <Button type={"submit"} color={"blue"} className={"mt-3 w-full"}>
                                                <FingerPrintIcon />
                                                {t("button.webauthn")}
                                            </Button>
                                        </Field>
                                    )}
                                </webauthnForm.Field>
                            </FieldGroup>
                        </Fieldset>
                    </Form>
                )}
            </DialogBody>

            <Button
                className={"mt-6"}
                plain={true}
                href={
                    user.permissions.role === "Internal"
                        ? "/i/profile/general"
                        : user.permissions.role === "Customer"
                          ? "/c/profile/general"
                          : "/a/profile/general"
                }
            >
                <ChevronLeftIcon />
                <Text className={"font-normal"}> {t("dialog.sudo-mode.label.back")}</Text>
            </Button>
        </Dialog>
    );
}
