import { Api, UUID } from "../api/api";
import { Link } from "./base/link";
import { clsx } from "clsx";
import { Subheading } from "./base/heading";
import React, { useEffect } from "react";
import { FullPentestProject } from "../api/generated";
import { toast } from "react-toastify";
import { GiArmorPunch, GiChoice, GiCombinationLock, GiLaptop, GiMagnifyingGlass } from "react-icons/gi";
import { Badge } from "./base/badge";
import { useTranslation } from "react-i18next";

/**
 * Properties for {@link PentestCard}
 */
export type PentestCardProps = {
    /** UUID of pentest project */
    projectId: UUID;
    /** Actions that should be rendered */
    actions?: React.ReactNode;
};

/**
 * A pentest card that displays modules and date of a pentest
 */
export default function PentestCard(props: PentestCardProps) {
    const [t] = useTranslation();
    const { projectId, actions } = props;
    const [pentest, setPentest] = React.useState<FullPentestProject | null>(null);

    useEffect(() => {
        Api.internal.projects.pentest.get(projectId).then((res) => {
            res.match(
                (ok) => {
                    if (ok.optional !== undefined) {
                        setPentest(ok.optional);
                    }
                },
                (err) => toast.error(err.message),
            );
        });
    }, []);

    return (
        <>
            {pentest && (
                <Link
                    key={projectId}
                    className={clsx(
                        "grid grid-cols-[1fr_auto] gap-3 rounded-md border border-zinc-100 bg-zinc-50 p-3 dark:border-zinc-800 dark:bg-zinc-800",
                        "dark:bg-red-900",
                        // Hover
                        "transition-colors duration-100 hover:border-blue-500",
                    )}
                    href={"/i/pentests/$projectId/general"}
                    params={{ projectId: projectId }}
                >
                    <div className={"flex flex-col"}>
                        <div className={"flex gap-3"}>
                            <Subheading>{pentest.kind}</Subheading>
                            <Badge color={"blue"}>
                                {pentest.state === "NotDue"
                                    ? t("customer.pentest-project.badge.not-due")
                                    : pentest.state === "Running"
                                      ? t("customer.pentest-project.badge.running")
                                      : pentest.state === "Finished"
                                        ? t("customer.pentest-project.badge.finished")
                                        : "Undefined"}
                            </Badge>
                        </div>
                        <span className={"text-xs text-zinc-600 dark:text-zinc-400"}>
                            {new Date(pentest.created_at).toLocaleDateString()}
                        </span>
                    </div>
                    {actions}
                    <div className={"col-span-2 grid grid-cols-5 "}>
                        <GiArmorPunch
                            size="20"
                            className={clsx(
                                // default
                                "text-white dark:text-neutral-600",
                                pentest.modules.external
                                    ? // active
                                      "drop-shadow-glow dark:!text-neutral-300 dark:drop-shadow-glow-dark"
                                    : // disabled
                                      "!drop-shadow-glow-disabled dark:!drop-shadow-glow-dark-disabled",
                            )}
                        />
                        <GiChoice
                            size="20"
                            className={clsx(
                                // default
                                "text-white dark:text-neutral-600",
                                pentest.modules.assessment
                                    ? // active
                                      "drop-shadow-glow dark:!text-neutral-300 dark:drop-shadow-glow-dark"
                                    : // disabled
                                      "!drop-shadow-glow-disabled dark:!drop-shadow-glow-dark-disabled",
                            )}
                        />
                        <GiMagnifyingGlass
                            size="20"
                            className={clsx(
                                // default
                                "text-white dark:text-neutral-600",
                                pentest.modules.internal
                                    ? // active
                                      "drop-shadow-glow dark:!text-neutral-300 dark:drop-shadow-glow-dark"
                                    : // disabled
                                      "!drop-shadow-glow-disabled dark:!drop-shadow-glow-dark-disabled",
                            )}
                        />
                        <GiCombinationLock
                            size="20"
                            className={clsx(
                                // default
                                "text-white dark:text-neutral-600",
                                pentest.modules.physical
                                    ? // active
                                      "drop-shadow-glow dark:!text-neutral-300 dark:drop-shadow-glow-dark"
                                    : // disabled
                                      "!drop-shadow-glow-disabled dark:!drop-shadow-glow-dark-disabled",
                            )}
                        />

                        <GiLaptop
                            size="20"
                            className={clsx(
                                // default
                                "text-white dark:text-neutral-600",
                                pentest.modules.device
                                    ? // active
                                      "drop-shadow-glow dark:!text-neutral-300 dark:drop-shadow-glow-dark"
                                    : // disabled
                                      "!drop-shadow-glow-disabled dark:!drop-shadow-glow-dark-disabled",
                            )}
                        />
                    </div>

                    {/*TODO insert technical lead here

                                 <Badge className={"w-fit"} color={"blue"}>
                                 <div className={"flex items-center gap-3 p-2"}>
                                    <StarIcon className="size-4" />
                                    <UserAvatar
                                        user={{
                                            ...project.customer.project_manager,
                                            name: project.customer.project_manager.display_name,
                                        }}
                                        className={"size-8"}
                                    />
                                    <span className={"text-sm"}>{project.customer.project_manager.display_name}</span>
                                      </div>
                                    </Badge>
           */}
                </Link>
            )}
        </>
    );
}
