import { createFileRoute, Navigate } from "@tanstack/react-router";

import React from "react";

/**
 * The properties for {@link QuestionnaireRedirect}
 */
export type QuestionnaireRedirectProps = {};

/**
 * Redirect to specific questionnaire
 */
function QuestionnaireRedirect(props: QuestionnaireRedirectProps) {
    const { questionnaireId: _, projectId } = Route.useParams();

    // TODO: As there is no questionnaire view yet, redirecting to generic file view of the pentest project

    return <Navigate to={"/i/pentests/$projectId/files"} params={{ projectId }} />;
}

export const Route = createFileRoute("/_links/links/_i/i/pentests/$projectId/questionnaires/$questionnaireId")({
    component: QuestionnaireRedirect,
});
