import { useTranslation } from "react-i18next";
import { Field, Label } from "src/components/base/fieldset";
import { ComboboxOption, StatelessCombobox } from "src/components/base/combobox";
import { Button } from "src/components/base/button";
import { clsx } from "clsx";
import { Badge } from "src/components/base/badge";
import { TrashIcon } from "@heroicons/react/20/solid";
import { Input } from "src/components/base/input";
import React, { useEffect } from "react";
import { CreateOfferPotentialRequest, SimpleOfferPotentialTopic } from "src/api/generated";
import { Api } from "src/api/api";
import { toast } from "react-toastify";

/**
 * The properties for {@link UpsellOptions}
 */
export type UpsellOptionsProps = {
    /** The form field value for selected offers */
    // eslint-disable-next-line
    selectedOfferSuggestions: Array<CreateOfferPotentialRequest & { name: string; label?: string }>;
    /** Callback if offer is added or deleted */
    // eslint-disable-next-line
    handleChange: (list: Array<CreateOfferPotentialRequest & { name: string; label?: string }>) => void;
};

/**
 * Combobox to add Upsell options in enter-[meeting]-results
 */
export default function UpsellOptions(props: UpsellOptionsProps) {
    const [tg] = useTranslation();
    const { selectedOfferSuggestions, handleChange } = props;

    const [offerSuggestions, setOfferSuggestions] = React.useState<Array<SimpleOfferPotentialTopic>>([]);
    const [searchOfferSuggestions, setSearchOfferSuggestions] = React.useState("");

    /**
     * Refresh the offer suggestions
     */
    const refreshOfferSuggestions = async () => {
        const res = await Api.internal.offerPotentialTopics.all();
        if (res.isErr) {
            toast.error(res.err.message);
            return;
        }

        setOfferSuggestions(res.ok.list);
    };

    useEffect(() => {
        refreshOfferSuggestions().then();
    }, []);

    const filteredOfferSuggestions =
        searchOfferSuggestions === ""
            ? offerSuggestions.filter((x) => !selectedOfferSuggestions.some((selected) => selected.topic === x.uuid))
            : offerSuggestions
                  .filter((x) => !selectedOfferSuggestions.some((selected) => selected.topic === x.uuid))
                  .filter((x) => x.name.toLowerCase().includes(searchOfferSuggestions.toLowerCase()));

    return (
        <form
            onSubmit={(e) => {
                e.preventDefault();
                e.stopPropagation();
            }}
        >
            <Field>
                <Label>{tg("label.upsell-options")}</Label>

                <div className={"flex h-fit items-center gap-3"}>
                    <StatelessCombobox<null | SimpleOfferPotentialTopic>
                        onChange={(offerSuggestionCurrent) => {
                            if (!offerSuggestionCurrent) {
                                return;
                            }

                            const currentList = [...selectedOfferSuggestions];

                            let data;
                            let label;
                            if (offerSuggestionCurrent.data.type === "None") {
                                data = { type: offerSuggestionCurrent.data.type };
                            } else if (offerSuggestionCurrent.data.type === "Size") {
                                data = { type: offerSuggestionCurrent.data.type, size: 0 };
                                label = offerSuggestionCurrent.data.label;
                            } else {
                                data = { type: offerSuggestionCurrent.data.type, text: "" };
                                label = offerSuggestionCurrent.data.label;
                            }
                            currentList.push({
                                topic: offerSuggestionCurrent.uuid,
                                name: offerSuggestionCurrent.name,
                                label,
                                data,
                            });

                            handleChange(currentList);
                        }}
                        onClose={() => setSearchOfferSuggestions("")}
                        onQueryChange={(q) => setSearchOfferSuggestions(q)}
                    >
                        {filteredOfferSuggestions.map((offerSuggestion) => (
                            <ComboboxOption value={offerSuggestion}>
                                <Label>{offerSuggestion.name}</Label>
                            </ComboboxOption>
                        ))}
                    </StatelessCombobox>
                </div>
            </Field>

            {selectedOfferSuggestions.length !== 0 && (
                <div className={"flex flex-col gap-3"}>
                    {selectedOfferSuggestions.map((selected, idx) => (
                        <div
                            key={selected.topic}
                            className={clsx(
                                "grid grid-cols-[1fr_auto] gap-3",
                                selectedOfferSuggestions.length !== idx + 1 && "border-b pb-3",
                            )}
                        >
                            <Badge className={""} color={"blue"}>
                                {selected.name}
                            </Badge>
                            <Button
                                plain={true}
                                onClick={() => {
                                    const currentList = selectedOfferSuggestions.filter(
                                        (x) => selected.topic !== x.topic,
                                    );

                                    handleChange(currentList);
                                }}
                            >
                                <TrashIcon />
                            </Button>
                            {selected.data.type !== "None" && (
                                <Field>
                                    <Label>{selected.label}</Label>
                                    <Input
                                        type={selected.data.type === "Size" ? "number" : "text"}
                                        value={selected.data.type === "Size" ? selected.data.size : selected.data.text}
                                        onChange={(e) => {
                                            const list = [...selectedOfferSuggestions];

                                            if (list[idx].data.type === "Size") {
                                                list[idx].data.size = parseInt(e.target.value);
                                            } else if (list[idx].data.type === "Custom") {
                                                list[idx].data.text = e.target.value;
                                            }

                                            handleChange(list);
                                        }}
                                    />
                                </Field>
                            )}
                        </div>
                    ))}
                </div>
            )}
        </form>
    );
}
