import { createFileRoute, Navigate } from "@tanstack/react-router";

import React from "react";

/**
 * The properties for {@link TaskRedirect}
 */
export type TaskRedirectProps = {};

/**
 * Navigate to a task
 */
function TaskRedirect(props: TaskRedirectProps) {
    const { taskId: _ } = Route.useParams();

    return <Navigate to={"/c/tasks"} />;
}

export const Route = createFileRoute("/_links/links/_c/c/tasks/$taskId")({
    component: TaskRedirect,
});
