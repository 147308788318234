import React from "react";
import Form from "../base/form";
import { Dialog, DialogActions, DialogDescription, DialogTitle } from "../base/dialog";
import { Button } from "../base/button";
import { useTranslation } from "react-i18next";

/**
 * The properties for {@link DialogDelete}
 */
export type DialogDeleteProps = {
    /** Title of the dialog */
    title: string;
    /** Optional description of the dialog */
    description?: string;
    /** Action to invoke on yes */
    onYes: () => void;
    /** Action to invoke on no */
    onNo: () => void;
    /** Action to invoke on cancel */
    onClose: () => void;
};

/**
 * A dialog to confirm deletion of things
 */
export default function DialogDelete(props: DialogDeleteProps) {
    const [t] = useTranslation();

    return (
        <Dialog open={true} onClose={props.onClose}>
            <Form onSubmit={props.onYes}>
                <DialogTitle>{props.title}</DialogTitle>
                {props.description && <DialogDescription>{props.description}</DialogDescription>}
                <DialogActions>
                    <Button onClick={props.onNo}>{t("button.no")}</Button>
                    <Button type={"submit"} color={"red"}>
                        {t("button.yes")}
                    </Button>
                </DialogActions>
            </Form>
        </Dialog>
    );
}
