/* tslint:disable */
/* eslint-disable */
/**
 * Unnamed API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ApiErrorResponse,
  CreateCustomerContactRequest,
  FormResultForNullAndDeleteCustomerContactErrors,
  FormResultForSingleLinkAndInviteCustomerUserErrors,
  FormResultForSingleLinkAndResetCustomerContactErrors,
  ListForFullCustomerContact,
  OptionalForFullCustomerContact,
  SingleUuid,
  UpdateCustomerContactRequest,
} from '../models/index';

export interface CreateCustomerContactCustomerRequest {
    CreateCustomerContactRequest: CreateCustomerContactRequest;
}

export interface CreateCustomerContactInternalRequest {
    uuid: string;
    CreateCustomerContactRequest: CreateCustomerContactRequest;
}

export interface DeleteCustomerContactInternalRequest {
    uuid: string;
}

export interface GetAllCustomerContactsInternalRequest {
    uuid: string;
}

export interface GetCustomerContactCustomerRequest {
    uuid: string;
}

export interface GetCustomerContactInternalRequest {
    uuid: string;
}

export interface InviteCustomerContactCustomerRequest {
    uuid: string;
}

export interface InviteCustomerContactInternalRequest {
    uuid: string;
}

export interface ResetCustomerContactInternalRequest {
    uuid: string;
}

export interface UpdateCustomerContactInternalRequest {
    uuid: string;
    UpdateCustomerContactRequest: UpdateCustomerContactRequest;
}

/**
 * 
 */
export class CustomerContactsApi extends runtime.BaseAPI {

    /**
     * Creates a new customer contact person
     * Creates a new customer contact person
     */
    async createCustomerContactCustomerRaw(requestParameters: CreateCustomerContactCustomerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SingleUuid>> {
        if (requestParameters['CreateCustomerContactRequest'] == null) {
            throw new runtime.RequiredError(
                'CreateCustomerContactRequest',
                'Required parameter "CreateCustomerContactRequest" was null or undefined when calling createCustomerContactCustomer().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/frontend/v1/customer/customers/contacts`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['CreateCustomerContactRequest'],
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Creates a new customer contact person
     * Creates a new customer contact person
     */
    async createCustomerContactCustomer(requestParameters: CreateCustomerContactCustomerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SingleUuid> {
        const response = await this.createCustomerContactCustomerRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Creates a new customer contact person
     * Creates a new customer contact person
     */
    async createCustomerContactInternalRaw(requestParameters: CreateCustomerContactInternalRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SingleUuid>> {
        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling createCustomerContactInternal().'
            );
        }

        if (requestParameters['CreateCustomerContactRequest'] == null) {
            throw new runtime.RequiredError(
                'CreateCustomerContactRequest',
                'Required parameter "CreateCustomerContactRequest" was null or undefined when calling createCustomerContactInternal().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/frontend/v1/internal/customers/{uuid}/contacts`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['CreateCustomerContactRequest'],
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Creates a new customer contact person
     * Creates a new customer contact person
     */
    async createCustomerContactInternal(requestParameters: CreateCustomerContactInternalRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SingleUuid> {
        const response = await this.createCustomerContactInternalRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Deletes a customer contact person
     * Deletes a customer contact person
     */
    async deleteCustomerContactInternalRaw(requestParameters: DeleteCustomerContactInternalRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FormResultForNullAndDeleteCustomerContactErrors>> {
        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling deleteCustomerContactInternal().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/frontend/v1/internal/customers/contacts/{uuid}`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Deletes a customer contact person
     * Deletes a customer contact person
     */
    async deleteCustomerContactInternal(requestParameters: DeleteCustomerContactInternalRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FormResultForNullAndDeleteCustomerContactErrors> {
        const response = await this.deleteCustomerContactInternalRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Retrieves a customer\'s contact persons
     * Retrieves a customer\'s contact persons
     */
    async getAllCustomerContactsCustomerRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListForFullCustomerContact>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/frontend/v1/customer/customers/contacts`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Retrieves a customer\'s contact persons
     * Retrieves a customer\'s contact persons
     */
    async getAllCustomerContactsCustomer(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListForFullCustomerContact> {
        const response = await this.getAllCustomerContactsCustomerRaw(initOverrides);
        return await response.value();
    }

    /**
     * Retrieves a customer\'s contact persons
     * Retrieves a customer\'s contact persons
     */
    async getAllCustomerContactsInternalRaw(requestParameters: GetAllCustomerContactsInternalRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListForFullCustomerContact>> {
        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling getAllCustomerContactsInternal().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/frontend/v1/internal/customers/{uuid}/contacts`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Retrieves a customer\'s contact persons
     * Retrieves a customer\'s contact persons
     */
    async getAllCustomerContactsInternal(requestParameters: GetAllCustomerContactsInternalRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListForFullCustomerContact> {
        const response = await this.getAllCustomerContactsInternalRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Retrieves a single customer contact person
     * Retrieves a single customer contact person
     */
    async getCustomerContactCustomerRaw(requestParameters: GetCustomerContactCustomerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OptionalForFullCustomerContact>> {
        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling getCustomerContactCustomer().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/frontend/v1/customer/customers/contacts/{uuid}`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Retrieves a single customer contact person
     * Retrieves a single customer contact person
     */
    async getCustomerContactCustomer(requestParameters: GetCustomerContactCustomerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OptionalForFullCustomerContact> {
        const response = await this.getCustomerContactCustomerRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Retrieves a single customer contact person
     * Retrieves a single customer contact person
     */
    async getCustomerContactInternalRaw(requestParameters: GetCustomerContactInternalRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OptionalForFullCustomerContact>> {
        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling getCustomerContactInternal().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/frontend/v1/internal/customers/contacts/{uuid}`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Retrieves a single customer contact person
     * Retrieves a single customer contact person
     */
    async getCustomerContactInternal(requestParameters: GetCustomerContactInternalRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OptionalForFullCustomerContact> {
        const response = await this.getCustomerContactInternalRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async inviteCustomerContactCustomerRaw(requestParameters: InviteCustomerContactCustomerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FormResultForSingleLinkAndInviteCustomerUserErrors>> {
        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling inviteCustomerContactCustomer().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/frontend/v1/customer/customers/contacts/{uuid}/invite`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async inviteCustomerContactCustomer(requestParameters: InviteCustomerContactCustomerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FormResultForSingleLinkAndInviteCustomerUserErrors> {
        const response = await this.inviteCustomerContactCustomerRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async inviteCustomerContactInternalRaw(requestParameters: InviteCustomerContactInternalRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FormResultForSingleLinkAndInviteCustomerUserErrors>> {
        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling inviteCustomerContactInternal().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/frontend/v1/internal/customers/contacts/{uuid}/invite`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async inviteCustomerContactInternal(requestParameters: InviteCustomerContactInternalRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FormResultForSingleLinkAndInviteCustomerUserErrors> {
        const response = await this.inviteCustomerContactInternalRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async resetCustomerContactInternalRaw(requestParameters: ResetCustomerContactInternalRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FormResultForSingleLinkAndResetCustomerContactErrors>> {
        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling resetCustomerContactInternal().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/frontend/v1/internal/customers/contacts/{uuid}/reset`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async resetCustomerContactInternal(requestParameters: ResetCustomerContactInternalRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FormResultForSingleLinkAndResetCustomerContactErrors> {
        const response = await this.resetCustomerContactInternalRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Updates an existing customer contact person  If the contact has its own user account, updating its `name` and `email` through this endpoint is forbidden.
     * Updates an existing customer contact person
     */
    async updateCustomerContactInternalRaw(requestParameters: UpdateCustomerContactInternalRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling updateCustomerContactInternal().'
            );
        }

        if (requestParameters['UpdateCustomerContactRequest'] == null) {
            throw new runtime.RequiredError(
                'UpdateCustomerContactRequest',
                'Required parameter "UpdateCustomerContactRequest" was null or undefined when calling updateCustomerContactInternal().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/frontend/v1/internal/customers/contacts/{uuid}`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['UpdateCustomerContactRequest'],
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Updates an existing customer contact person  If the contact has its own user account, updating its `name` and `email` through this endpoint is forbidden.
     * Updates an existing customer contact person
     */
    async updateCustomerContactInternal(requestParameters: UpdateCustomerContactInternalRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateCustomerContactInternalRaw(requestParameters, initOverrides);
    }

}
