/* tslint:disable */
/* eslint-disable */
/**
 * Unnamed API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ApiErrorResponse,
  EnterSudoPasswordRequest,
  EnterSudoTotpRequest,
  FormResultForNullAndNull,
  FormResultForWebAuthnSchemaAndNull,
  SudoState,
  SupportedSudoOptions,
} from '../models/index';

export interface CompleteSudoWebauthnRequest {
    body: any | null;
}

export interface EnterSudoPasswordOperationRequest {
    EnterSudoPasswordRequest: EnterSudoPasswordRequest;
}

export interface EnterSudoTotpOperationRequest {
    EnterSudoTotpRequest: EnterSudoTotpRequest;
}

/**
 * 
 */
export class SudoModeApi extends runtime.BaseAPI {

    /**
     * Complete the webauthn challenge for entering sudo mode
     * Complete the webauthn challenge for entering sudo mode
     */
    async completeSudoWebauthnRaw(requestParameters: CompleteSudoWebauthnRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FormResultForNullAndNull>> {
        if (requestParameters['body'] == null) {
            throw new runtime.RequiredError(
                'body',
                'Required parameter "body" was null or undefined when calling completeSudoWebauthn().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/frontend/v1/common/sudo/complete-webauthn`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['body'] as any,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Complete the webauthn challenge for entering sudo mode
     * Complete the webauthn challenge for entering sudo mode
     */
    async completeSudoWebauthn(requestParameters: CompleteSudoWebauthnRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FormResultForNullAndNull> {
        const response = await this.completeSudoWebauthnRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Enter sudo mode using a password
     * Enter sudo mode using a password
     */
    async enterSudoPasswordRaw(requestParameters: EnterSudoPasswordOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FormResultForNullAndNull>> {
        if (requestParameters['EnterSudoPasswordRequest'] == null) {
            throw new runtime.RequiredError(
                'EnterSudoPasswordRequest',
                'Required parameter "EnterSudoPasswordRequest" was null or undefined when calling enterSudoPassword().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/frontend/v1/common/sudo/password`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['EnterSudoPasswordRequest'],
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Enter sudo mode using a password
     * Enter sudo mode using a password
     */
    async enterSudoPassword(requestParameters: EnterSudoPasswordOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FormResultForNullAndNull> {
        const response = await this.enterSudoPasswordRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Enter sudo mode using a TOTP key
     * Enter sudo mode using a TOTP key
     */
    async enterSudoTotpRaw(requestParameters: EnterSudoTotpOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FormResultForNullAndNull>> {
        if (requestParameters['EnterSudoTotpRequest'] == null) {
            throw new runtime.RequiredError(
                'EnterSudoTotpRequest',
                'Required parameter "EnterSudoTotpRequest" was null or undefined when calling enterSudoTotp().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/frontend/v1/common/sudo/totp`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['EnterSudoTotpRequest'],
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Enter sudo mode using a TOTP key
     * Enter sudo mode using a TOTP key
     */
    async enterSudoTotp(requestParameters: EnterSudoTotpOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FormResultForNullAndNull> {
        const response = await this.enterSudoTotpRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Enter sudo mode using webauthn
     * Enter sudo mode using webauthn
     */
    async enterSudoWebauthnRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FormResultForWebAuthnSchemaAndNull>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/frontend/v1/common/sudo/webauthn`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Enter sudo mode using webauthn
     * Enter sudo mode using webauthn
     */
    async enterSudoWebauthn(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FormResultForWebAuthnSchemaAndNull> {
        const response = await this.enterSudoWebauthnRaw(initOverrides);
        return await response.value();
    }

    /**
     * Get the options for entering sudo mode available to a user  Calling this from an oidc user will result in a `BadRequest`.
     * Get the options for entering sudo mode available to a user
     */
    async getSudoOptionsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SupportedSudoOptions>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/frontend/v1/common/sudo/options`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Get the options for entering sudo mode available to a user  Calling this from an oidc user will result in a `BadRequest`.
     * Get the options for entering sudo mode available to a user
     */
    async getSudoOptions(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SupportedSudoOptions> {
        const response = await this.getSudoOptionsRaw(initOverrides);
        return await response.value();
    }

    /**
     * Retrieve the current state of the sudo mode
     * Retrieve the current state of the sudo mode
     */
    async getSudoStatusRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SudoState>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/frontend/v1/common/sudo/status`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Retrieve the current state of the sudo mode
     * Retrieve the current state of the sudo mode
     */
    async getSudoStatus(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SudoState> {
        const response = await this.getSudoStatusRaw(initOverrides);
        return await response.value();
    }

}
