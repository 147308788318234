/* tslint:disable */
/* eslint-disable */
/**
 * Unnamed API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ApiErrorResponse,
  CreatePentestProjectRequest,
  FormResultForNullAndUpdatePentestProjectErrors,
  FormResultForSingleUuidAndCreatePentestProjectErrors,
  OptionalForCustomerFullPentestProject,
  OptionalForFullPentestProject,
  UpdatePentestProjectRequest,
} from '../models/index';

export interface CreatePentestProjectOperationRequest {
    CreatePentestProjectRequest: CreatePentestProjectRequest;
}

export interface GetPentestProjectCustomerRequest {
    uuid: string;
}

export interface GetPentestProjectInternalRequest {
    uuid: string;
}

export interface UpdatePentestProjectOperationRequest {
    uuid: string;
    UpdatePentestProjectRequest: UpdatePentestProjectRequest;
}

/**
 * 
 */
export class PentestProjectsApi extends runtime.BaseAPI {

    /**
     * Creates a new pentest project
     * Creates a new pentest project
     */
    async createPentestProjectRaw(requestParameters: CreatePentestProjectOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FormResultForSingleUuidAndCreatePentestProjectErrors>> {
        if (requestParameters['CreatePentestProjectRequest'] == null) {
            throw new runtime.RequiredError(
                'CreatePentestProjectRequest',
                'Required parameter "CreatePentestProjectRequest" was null or undefined when calling createPentestProject().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/frontend/v1/internal/pentest/projects`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['CreatePentestProjectRequest'],
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Creates a new pentest project
     * Creates a new pentest project
     */
    async createPentestProject(requestParameters: CreatePentestProjectOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FormResultForSingleUuidAndCreatePentestProjectErrors> {
        const response = await this.createPentestProjectRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Retrieves a single pentest project  If the project doesn\'t exist, `null` will be returned. However, if the project does exist but is not of kind `Pentest`, a 400 will be returned.
     * Retrieves a single pentest project
     */
    async getPentestProjectCustomerRaw(requestParameters: GetPentestProjectCustomerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OptionalForCustomerFullPentestProject>> {
        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling getPentestProjectCustomer().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/frontend/v1/customer/pentest/projects/{uuid}`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Retrieves a single pentest project  If the project doesn\'t exist, `null` will be returned. However, if the project does exist but is not of kind `Pentest`, a 400 will be returned.
     * Retrieves a single pentest project
     */
    async getPentestProjectCustomer(requestParameters: GetPentestProjectCustomerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OptionalForCustomerFullPentestProject> {
        const response = await this.getPentestProjectCustomerRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Retrieves a single pentest project  If the project doesn\'t exist, `null` will be returned. However, if the project does exist but is not of kind `Pentest`, a 400 will be returned.
     * Retrieves a single pentest project
     */
    async getPentestProjectInternalRaw(requestParameters: GetPentestProjectInternalRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OptionalForFullPentestProject>> {
        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling getPentestProjectInternal().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/frontend/v1/internal/pentest/projects/{uuid}`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Retrieves a single pentest project  If the project doesn\'t exist, `null` will be returned. However, if the project does exist but is not of kind `Pentest`, a 400 will be returned.
     * Retrieves a single pentest project
     */
    async getPentestProjectInternal(requestParameters: GetPentestProjectInternalRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OptionalForFullPentestProject> {
        const response = await this.getPentestProjectInternalRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Updates a pentest project
     * Updates a pentest project
     */
    async updatePentestProjectRaw(requestParameters: UpdatePentestProjectOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FormResultForNullAndUpdatePentestProjectErrors>> {
        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling updatePentestProject().'
            );
        }

        if (requestParameters['UpdatePentestProjectRequest'] == null) {
            throw new runtime.RequiredError(
                'UpdatePentestProjectRequest',
                'Required parameter "UpdatePentestProjectRequest" was null or undefined when calling updatePentestProject().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/frontend/v1/internal/pentest/projects/{uuid}`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['UpdatePentestProjectRequest'],
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Updates a pentest project
     * Updates a pentest project
     */
    async updatePentestProject(requestParameters: UpdatePentestProjectOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FormResultForNullAndUpdatePentestProjectErrors> {
        const response = await this.updatePentestProjectRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
