/* tslint:disable */
/* eslint-disable */
/**
 * Unnamed API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ApiErrorResponse,
  FormResultForNullAndTestMailSettingsFormErrors,
  FormResultForSetMailSettingsResponseAndSetMailSettingsFormErrors,
  FullMailSettings,
  OptionalForFullMailSettings,
  TestMailSettingsRequest,
} from '../models/index';

export interface SetMailSettingsRequest {
    FullMailSettings: FullMailSettings;
}

export interface TestMailSettingsOperationRequest {
    TestMailSettingsRequest: TestMailSettingsRequest;
}

/**
 * 
 */
export class MailSettingsApi extends runtime.BaseAPI {

    /**
     * Deletes the current mail settings, disabling mails as a result
     * Deletes the current mail settings, disabling mails as a result
     */
    async deleteMailSettingsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/frontend/v1/admin/mail-settings`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Deletes the current mail settings, disabling mails as a result
     * Deletes the current mail settings, disabling mails as a result
     */
    async deleteMailSettings(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteMailSettingsRaw(initOverrides);
    }

    /**
     * Retrieves the current mail settings
     * Retrieves the current mail settings
     */
    async getMailSettingsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OptionalForFullMailSettings>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/frontend/v1/admin/mail-settings`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Retrieves the current mail settings
     * Retrieves the current mail settings
     */
    async getMailSettings(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OptionalForFullMailSettings> {
        const response = await this.getMailSettingsRaw(initOverrides);
        return await response.value();
    }

    /**
     * Sets the current mail settings
     * Sets the current mail settings
     */
    async setMailSettingsRaw(requestParameters: SetMailSettingsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FormResultForSetMailSettingsResponseAndSetMailSettingsFormErrors>> {
        if (requestParameters['FullMailSettings'] == null) {
            throw new runtime.RequiredError(
                'FullMailSettings',
                'Required parameter "FullMailSettings" was null or undefined when calling setMailSettings().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/frontend/v1/admin/mail-settings`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['FullMailSettings'],
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Sets the current mail settings
     * Sets the current mail settings
     */
    async setMailSettings(requestParameters: SetMailSettingsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FormResultForSetMailSettingsResponseAndSetMailSettingsFormErrors> {
        const response = await this.setMailSettingsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Sends a mail to test the current settings
     * Sends a mail to test the current settings
     */
    async testMailSettingsRaw(requestParameters: TestMailSettingsOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FormResultForNullAndTestMailSettingsFormErrors>> {
        if (requestParameters['TestMailSettingsRequest'] == null) {
            throw new runtime.RequiredError(
                'TestMailSettingsRequest',
                'Required parameter "TestMailSettingsRequest" was null or undefined when calling testMailSettings().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/frontend/v1/admin/mail-settings/test`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['TestMailSettingsRequest'],
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Sends a mail to test the current settings
     * Sends a mail to test the current settings
     */
    async testMailSettings(requestParameters: TestMailSettingsOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FormResultForNullAndTestMailSettingsFormErrors> {
        const response = await this.testMailSettingsRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
