import React, { useContext } from "react";
import { Avatar } from "./base/avatar";
import USER_CONTEXT from "../context/user";
import { getInitials } from "../utils/initials";
import { useTranslation } from "react-i18next";

/**
 * The properties for {@link UserAvatar}
 */
export type UserAvatarProps = {
    /** additional class names */
    className?: string;
    /** Optional user to render instead of logged-in user */
    user?: {
        /** Name of the user */
        name: string;
        /** uuid of the User */
        uuid?: string;
        /** Whether the user has a profile image */
        has_profile_image: boolean;
    };
};

/**
 * The icon of a user
 */
export default function UserAvatar(props: UserAvatarProps) {
    const [t] = useTranslation();
    const userContext = useContext(USER_CONTEXT);

    const has_profile_image = props.user ? props.user.has_profile_image : userContext.user.has_profile_image;
    const name = props.user ? props.user.name : userContext.user.display_name;
    const uuid = props.user ? props.user.uuid : userContext.user.uuid;

    return (
        <Avatar
            className={props.className}
            alt={t("accessibility.profile-image")}
            initials={has_profile_image ? undefined : getInitials(name)}
            src={has_profile_image && uuid ? `/api/frontend/v1/common/users/${uuid}/profile-image` : undefined}
        />
    );
}
