import { createFileRoute } from "@tanstack/react-router";

import React, { useEffect, useState } from "react";
import HeadingLayout from "src/components/base/heading-layout";
import { useTranslation } from "react-i18next";
import { Api } from "src/api/api";
import { toast } from "react-toastify";
import { FullQuestion, FullQuestionGroup } from "src/api/generated";
import { Button } from "src/components/base/button";
import Form from "src/components/base/form";
import { useForm } from "@tanstack/react-form";
import { clsx } from "clsx";
import { Field, FieldGroup, Fieldset, Label } from "src/components/base/fieldset";
import { Input } from "src/components/base/input";
import { Description } from "@headlessui/react";
import { Textarea } from "src/components/base/textarea";
import { Divider } from "src/components/base/divider";
import { ArrowUpRightIcon, ChevronLeftIcon, TrashIcon } from "@heroicons/react/20/solid";
import { ReorderList, ReorderItem } from "src/components/base/reorder-list";
import { Combobox, ComboboxOption } from "src/components/base/combobox";
import { Text } from "src/components/base/text";

const fieldClassName = clsx("grid grid-cols-2");

/**
 * The properties for {@link EditQuestionnaireGroup}
 */
export type EditQuestionnaireGroupProps = {};

/**
 * Edit a questionnaire group
 */
export default function EditQuestionnaireGroup(props: EditQuestionnaireGroupProps) {
    const [t] = useTranslation();
    const { groupId } = Route.useParams();
    const navigate = Route.useNavigate();

    const [questions, setQuestions] = useState<Array<FullQuestion>>([]);
    const [group, setGroup] = useState<FullQuestionGroup>();

    const [currQuestion, setCurrQuestion] = useState<FullQuestion | null>(null);
    const [questionQuery, setQuestionQuery] = React.useState("");

    const editForm = useForm({
        defaultValues: {
            name: group?.name,
            notes: group?.notes,
            title: group?.title,
            description: group?.description,
            questions: group ? group.questions : [],
        },
        // eslint-disable-next-line
        onSubmit: async ({ value, formApi }) => {
            const res = await Api.internal.questionnaires.groups.update(groupId, {
                name: value.name,
                notes: value.notes,
                title: value.title,
                description: value.description,
                questions: value.questions.map((question) => question.uuid),
            });

            res.match(
                (res) => {
                    if (res.result === "Ok") {
                        toast.success(t("toast.saved-changes"));
                        retrieveGroup().then(() => {
                            formApi.reset();
                        });
                    } else {
                        if (res.error.in_use) {
                            toast.error(t("error.in-use"));
                        }
                    }
                },
                (err) => toast.error(err.message),
            );
        },
    });
    const isDirty = editForm.useStore((state) => state.isDirty);
    const groupQuestions = editForm.useStore((state) => state.values.questions);

    /**
     * Retrieve the current group
     */
    const retrieveGroup = async () => {
        (await Api.internal.questionnaires.groups.get(groupId)).match(
            (group) => setGroup(group.optional),
            (err) => toast.error(err.message),
        );
    };

    useEffect(() => {
        Api.internal.questionnaires.questions.all().then((res) =>
            res.match(
                (questions) => setQuestions(questions.list),
                (err) => toast.error(err.message),
            ),
        );
        retrieveGroup().then();
    }, []);

    const filteredQuestions =
        questionQuery === ""
            ? questions.filter((q) => !groupQuestions.some((gq) => gq.uuid === q.uuid)).slice(0, 10)
            : questions
                  .filter((q) => !groupQuestions.some((gq) => gq.uuid === q.uuid))
                  .filter((q) => q.name.toLowerCase().includes(questionQuery.toLowerCase()))
                  .slice(0, 10);

    return (
        <Form onSubmit={editForm.handleSubmit} className={"h-full"}>
            <Button plain={true} onClick={() => navigate({ to: "/i/questionnaires/groups" })}>
                <ChevronLeftIcon />
                <Text>{t("button.back-to-overview")}</Text>
            </Button>

            <HeadingLayout
                className={"mt-6 h-full"}
                heading={t("internal.edit-questionnaire-group.heading.edit-group", { group: group?.name })}
                headingChildren={
                    <>
                        <Button plain={true} type={"submit"} disabled={!isDirty} onClick={() => editForm.reset()}>
                            {t("button.reset")}
                        </Button>
                        <Button color={"blue"} type={"submit"} disabled={!isDirty}>
                            {t("button.save")}
                        </Button>
                    </>
                }
            >
                <Fieldset>
                    <FieldGroup>
                        <editForm.Field name={"name"}>
                            {(fieldApi) => (
                                <Field className={fieldClassName}>
                                    <span>
                                        <Label>{t("label.name")}</Label>
                                        <Description></Description>
                                    </span>
                                    <Input
                                        className={"place-self-start"}
                                        required={true}
                                        value={fieldApi.state.value}
                                        onChange={(e) => fieldApi.handleChange(e.target.value)}
                                    />
                                </Field>
                            )}
                        </editForm.Field>
                        <editForm.Field name={"notes"}>
                            {(fieldApi) => (
                                <Field className={fieldClassName}>
                                    <span>
                                        <Label>{t("label.notes")}</Label>
                                        <Description></Description>
                                    </span>
                                    <Textarea
                                        className={"place-self-start"}
                                        value={fieldApi.state.value}
                                        onChange={(e) => fieldApi.handleChange(e.target.value)}
                                    />
                                </Field>
                            )}
                        </editForm.Field>

                        <Divider soft={true} />

                        <editForm.Field name={"title"}>
                            {(fieldApi) => (
                                <Field className={fieldClassName}>
                                    <span>
                                        <Label>{t("label.title")}</Label>
                                        <Description></Description>
                                    </span>
                                    <Input
                                        className={"place-self-start"}
                                        value={fieldApi.state.value}
                                        onChange={(e) => fieldApi.handleChange(e.target.value)}
                                    />
                                </Field>
                            )}
                        </editForm.Field>
                        <editForm.Field name={"description"}>
                            {(fieldApi) => (
                                <Field className={fieldClassName}>
                                    <span>
                                        <Label>{t("label.description")}</Label>
                                        <Description></Description>
                                    </span>
                                    <Textarea
                                        className={"place-self-start"}
                                        value={fieldApi.state.value}
                                        onChange={(e) => fieldApi.handleChange(e.target.value)}
                                    />
                                </Field>
                            )}
                        </editForm.Field>

                        <Divider soft={true} />
                    </FieldGroup>
                </Fieldset>

                <editForm.Field name={"questions"}>
                    {(fieldApi) => (
                        <div className={"grid h-full grid-cols-2 gap-20"}>
                            <Fieldset>
                                <FieldGroup>
                                    <Field>
                                        <Label>{t("label.search")}</Label>
                                        <Combobox
                                            value={currQuestion}
                                            onChange={(q) => setCurrQuestion(q)}
                                            onClose={() => setQuestionQuery("")}
                                            queryDisplay={(q) => (q ? q.name : "")}
                                            onQueryChange={(q) => setQuestionQuery(q)}
                                        >
                                            {filteredQuestions.map((c) => (
                                                <ComboboxOption value={c}>
                                                    <Label>{c.name}</Label>
                                                </ComboboxOption>
                                            ))}
                                        </Combobox>
                                    </Field>
                                    <Button
                                        disabled={!currQuestion}
                                        onClick={() => {
                                            const curr = currQuestion;
                                            if (!curr) {
                                                return;
                                            }

                                            setCurrQuestion(null);
                                            fieldApi.handleChange([...fieldApi.state.value, curr]);
                                        }}
                                    >
                                        {t("button.add")}
                                    </Button>
                                </FieldGroup>
                            </Fieldset>

                            <ReorderList axis={"y"} values={fieldApi.state.value} onReorder={fieldApi.handleChange}>
                                {fieldApi.state.value.map((q, idx) => (
                                    <ReorderItem key={q.uuid} value={q} className={"items-center justify-between"}>
                                        <span>{q.name}</span>
                                        <div className={"flex"}>
                                            <Button
                                                plain={true}
                                                href={"/i/questionnaires/questions/$questionId"}
                                                params={{ questionId: q.uuid }}
                                            >
                                                <ArrowUpRightIcon />
                                            </Button>

                                            <Button
                                                plain={true}
                                                onClick={() => {
                                                    const questions = [...fieldApi.state.value];
                                                    questions.splice(idx, 1);
                                                    fieldApi.handleChange(questions);
                                                }}
                                            >
                                                <TrashIcon />
                                            </Button>
                                        </div>
                                    </ReorderItem>
                                ))}
                            </ReorderList>
                        </div>
                    )}
                </editForm.Field>
            </HeadingLayout>
        </Form>
    );
}

export const Route = createFileRoute("/_internal/i/questionnaires/groups/$groupId")({
    component: EditQuestionnaireGroup,
});
