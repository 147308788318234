import { createFileRoute, Navigate } from "@tanstack/react-router";

import React from "react";

/**
 * The properties for {@link RedirectCustomer}
 */
export type RedirectCustomerProps = {};

/**
 * Redirect to the customer view
 */
function RedirectCustomer(props: RedirectCustomerProps) {
    const { customerId } = Route.useParams();
    return <Navigate to={"/i/customers/$customerId/general"} params={{ customerId }} />;
}

export const Route = createFileRoute("/_links/links/_i/i/customers/$customerId")({
    component: RedirectCustomer,
});
