/* tslint:disable */
/* eslint-disable */
/**
 * Unnamed API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ApiErrorResponse,
  FormResultForLoginPasswordResponseAndLoginPasswordErrors,
  FormResultForWebAuthnSchemaAndLoginWebauthnErrors,
  FormResultForWebAuthnSchemaAndNever,
  LoginFlowsRequest,
  LoginPasswordRequest,
  LoginWebauthnRequest,
  OptionalForSupportedLoginFlows,
  VerifyTotpRequest,
  WebAuthnAuthenticateResult,
} from '../models/index';

export interface CompleteAuthWebauthnRequest {
    body: any | null;
}

export interface GetLoginFlowsRequest {
    LoginFlowsRequest: LoginFlowsRequest;
}

export interface LoginPasswordOperationRequest {
    LoginPasswordRequest: LoginPasswordRequest;
}

export interface LoginWebauthnOperationRequest {
    LoginWebauthnRequest: LoginWebauthnRequest;
}

export interface VerifyTotpOperationRequest {
    VerifyTotpRequest: VerifyTotpRequest;
}

/**
 * 
 */
export class AuthApi extends runtime.BaseAPI {

    /**
     * Complete the webauthn challenge for authentication
     * Complete the webauthn challenge for authentication
     */
    async completeAuthWebauthnRaw(requestParameters: CompleteAuthWebauthnRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<WebAuthnAuthenticateResult>> {
        if (requestParameters['body'] == null) {
            throw new runtime.RequiredError(
                'body',
                'Required parameter "body" was null or undefined when calling completeAuthWebauthn().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/frontend/v1/common/auth/complete-webauthn`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['body'] as any,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Complete the webauthn challenge for authentication
     * Complete the webauthn challenge for authentication
     */
    async completeAuthWebauthn(requestParameters: CompleteAuthWebauthnRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<WebAuthnAuthenticateResult> {
        const response = await this.completeAuthWebauthnRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get the login flows available to a user
     * Get the login flows available to a user
     */
    async getLoginFlowsRaw(requestParameters: GetLoginFlowsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OptionalForSupportedLoginFlows>> {
        if (requestParameters['LoginFlowsRequest'] == null) {
            throw new runtime.RequiredError(
                'LoginFlowsRequest',
                'Required parameter "LoginFlowsRequest" was null or undefined when calling getLoginFlows().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/frontend/v1/common/auth/flows`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['LoginFlowsRequest'],
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Get the login flows available to a user
     * Get the login flows available to a user
     */
    async getLoginFlows(requestParameters: GetLoginFlowsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OptionalForSupportedLoginFlows> {
        const response = await this.getLoginFlowsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Local login using a password  Might require another factor
     * Local login using a password
     */
    async loginPasswordRaw(requestParameters: LoginPasswordOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FormResultForLoginPasswordResponseAndLoginPasswordErrors>> {
        if (requestParameters['LoginPasswordRequest'] == null) {
            throw new runtime.RequiredError(
                'LoginPasswordRequest',
                'Required parameter "LoginPasswordRequest" was null or undefined when calling loginPassword().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/frontend/v1/common/auth/login-password`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['LoginPasswordRequest'],
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Local login using a password  Might require another factor
     * Local login using a password
     */
    async loginPassword(requestParameters: LoginPasswordOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FormResultForLoginPasswordResponseAndLoginPasswordErrors> {
        const response = await this.loginPasswordRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Local login using webauthn  Doesn\'t require another factor
     * Local login using webauthn
     */
    async loginWebauthnRaw(requestParameters: LoginWebauthnOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FormResultForWebAuthnSchemaAndLoginWebauthnErrors>> {
        if (requestParameters['LoginWebauthnRequest'] == null) {
            throw new runtime.RequiredError(
                'LoginWebauthnRequest',
                'Required parameter "LoginWebauthnRequest" was null or undefined when calling loginWebauthn().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/frontend/v1/common/auth/login-webauthn`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['LoginWebauthnRequest'],
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Local login using webauthn  Doesn\'t require another factor
     * Local login using webauthn
     */
    async loginWebauthn(requestParameters: LoginWebauthnOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FormResultForWebAuthnSchemaAndLoginWebauthnErrors> {
        const response = await this.loginWebauthnRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Drop the current session and logg-out
     * Drop the current session and logg-out
     */
    async logoutRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/frontend/v1/common/auth/logout`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Drop the current session and logg-out
     * Drop the current session and logg-out
     */
    async logout(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.logoutRaw(initOverrides);
    }

    /**
     * Verify a password login using an TOTP key
     * Verify a password login using an TOTP key
     */
    async verifyTotpRaw(requestParameters: VerifyTotpOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['VerifyTotpRequest'] == null) {
            throw new runtime.RequiredError(
                'VerifyTotpRequest',
                'Required parameter "VerifyTotpRequest" was null or undefined when calling verifyTotp().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/frontend/v1/common/auth/verify-totp`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['VerifyTotpRequest'],
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Verify a password login using an TOTP key
     * Verify a password login using an TOTP key
     */
    async verifyTotp(requestParameters: VerifyTotpOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.verifyTotpRaw(requestParameters, initOverrides);
    }

    /**
     * Verify a password login using an WebAuthn key
     * Verify a password login using an WebAuthn key
     */
    async verifyWebauthnRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FormResultForWebAuthnSchemaAndNever>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/frontend/v1/common/auth/verify-webauthn`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Verify a password login using an WebAuthn key
     * Verify a password login using an WebAuthn key
     */
    async verifyWebauthn(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FormResultForWebAuthnSchemaAndNever> {
        const response = await this.verifyWebauthnRaw(initOverrides);
        return await response.value();
    }

}
