import { createFileRoute } from "@tanstack/react-router";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { DescriptionDetails, DescriptionList, DescriptionTerm } from "src/components/base/description-list";
import { Subheading } from "src/components/base/heading";
import CUSTOMER_CONTEXT from "../../../../../../context/customer";
import { EllipsisVerticalIcon } from "@heroicons/react/20/solid";
import ContactCard from "../../../../../../components/contact-card";
import { Dropdown, DropdownButton, DropdownItem, DropdownLabel, DropdownMenu } from "src/components/base/dropdown";
import { Dialog, DialogActions, DialogBody, DialogTitle } from "src/components/base/dialog";
import { Field } from "@headlessui/react";
import { Combobox, ComboboxOption } from "src/components/base/combobox";
import { Button } from "src/components/base/button";
import Form from "../../../../../../components/base/form";
import { InternalUser, SimpleUser } from "src/api/generated";
import { Api } from "src/api/api";
import { toast } from "react-toastify";
import { Label } from "src/components/base/fieldset";
import { Avatar } from "src/components/base/avatar";
import PentestCard from "../../../../../../components/pentest-card";
import USER_CONTEXT from "src/context/user";
import { Text } from "src/components/base/text";

/**
 * The properties for {@link SingleCustomer}
 */
export type SingleCustomerProps = {};

/**
 * The view to display the properties of a single customer
 */
export default function SingleCustomer(props: SingleCustomerProps) {
    const [t] = useTranslation();

    const [projectManagers, setProjectManagers] = React.useState<Array<InternalUser>>([]);
    const [openDialog, setOpenDialog] = React.useState(false);
    const [changePm, setChangePm] = React.useState<SimpleUser | null>(null);
    const [projectManagerQuery, setProjectManagerQuery] = React.useState("");

    const { customer, reset } = React.useContext(CUSTOMER_CONTEXT);
    const { user } = React.useContext(USER_CONTEXT);

    useEffect(() => {
        Api.internal.internalUsers.all().then((res) =>
            res.match(
                (list) => setProjectManagers(list.list.filter((user) => user.groups.project_management)),
                (err) => toast.error(err.message),
            ),
        );
    }, []);

    const filteredPMs =
        projectManagerQuery === ""
            ? projectManagers
            : projectManagers.filter((pm) => pm.display_name.toLowerCase().includes(projectManagerQuery.toLowerCase()));

    /**
     * Api call to change customer PM
     */
    const changeProjectManager = () => {
        changePm &&
            Api.internal.customers.update(customer.uuid, { project_manager: changePm.uuid }).then((res) =>
                res.match(
                    () => reset(),
                    (err) => toast.error(err.message),
                ),
            );
    };

    return (
        <div className={"flex flex-col gap-6"}>
            <DescriptionList className={"max-w-2xl"}>
                <DescriptionTerm>{t("label.legal-business-name")}</DescriptionTerm>
                <DescriptionDetails>{customer.legal_name}</DescriptionDetails>

                {/*   <DescriptionTerm>{t("label.created-at")}</DescriptionTerm>
                <DescriptionDetails>{new Date(customer.created_at).toLocaleDateString()}</DescriptionDetails>

                <DescriptionTerm>{t("label.created-by")}</DescriptionTerm>
                <DescriptionDetails>
                    {customer.created_by ? (
                        <UserInfo displayName={customer.created_by.display_name} mail={customer.created_by.mail} />
                    ) : (
                        <span>{t("label.anonymous")}</span>
                    )}
                </DescriptionDetails>

                <DescriptionTerm>{t("label.approved-at")}</DescriptionTerm>
                <DescriptionDetails>{new Date(customer.approved_at).toLocaleDateString()}</DescriptionDetails>

                <DescriptionTerm>{t("label.approved-by")}</DescriptionTerm>
                <DescriptionDetails>
                    {customer.approved_by ? (
                        <UserInfo displayName={customer.approved_by.display_name} mail={customer.approved_by.mail} />
                    ) : (
                        <span>{t("label.anonymous")}</span>
                    )}
                </DescriptionDetails> */}

                <DescriptionTerm>{t("label.notes")}</DescriptionTerm>
                <DescriptionDetails>{customer.notes}</DescriptionDetails>
            </DescriptionList>
            <div className={"grid sm:grid-cols-2 xl:grid-cols-3"}>
                <div className={"flex flex-col gap-3"}>
                    <Subheading>{t("label.project-manager")}</Subheading>
                    <ContactCard
                        key={customer.project_manager.uuid}
                        contact={{
                            name: customer.project_manager.display_name,
                            mail: customer.project_manager.mail,
                            user: customer.project_manager,
                        }}
                        actions={
                            <>
                                {((user.permissions.role === "Internal" && user.permissions.groups.power_user) ||
                                    (user.permissions.role === "Internal" &&
                                        user.permissions.groups.project_management)) && (
                                    <Dropdown>
                                        <DropdownButton plain={true}>
                                            <span className={"sr-only"}>{t("accessibility.actions")}</span>
                                            <EllipsisVerticalIcon />
                                        </DropdownButton>
                                        <DropdownMenu anchor={"bottom end"}>
                                            <DropdownItem
                                                onClick={() => {
                                                    setOpenDialog(true);
                                                    setChangePm(customer.project_manager);
                                                }}
                                            >
                                                <DropdownLabel>{t("button.change")}</DropdownLabel>
                                            </DropdownItem>
                                        </DropdownMenu>
                                    </Dropdown>
                                )}
                            </>
                        }
                    />
                </div>
            </div>
            <div className={"flex flex-col gap-3"}>
                <Subheading>{t("label.projects")}</Subheading>
                {customer.projects.length === 0 ? (
                    <Text>{t("internal.customer.description.no-projects")}</Text>
                ) : (
                    <div className={"grid grid-cols-4 gap-6"}>
                        {customer.projects
                            .sort((a, b) =>
                                a.archived === b.archived
                                    ? a.created_at < b.created_at
                                        ? -1
                                        : 1
                                    : a.archived < b.archived
                                      ? -1
                                      : 1,
                            )
                            .map((project) =>
                                project.kind === "Pentest" ? <PentestCard projectId={project.uuid} /> : undefined,
                            )}
                    </div>
                )}
            </div>

            {openDialog && (
                <Dialog
                    open={true}
                    onClose={() => {
                        setChangePm(null);
                        setOpenDialog(false);
                    }}
                >
                    <Form
                        onSubmit={() => {
                            changeProjectManager();
                            setChangePm(null);
                            setOpenDialog(false);
                        }}
                    >
                        <DialogTitle>{t("internal.customer.heading.change-pm")}</DialogTitle>
                        <DialogBody>
                            <Field>
                                <Combobox
                                    value={changePm}
                                    onChange={(pm) => {
                                        setChangePm(pm);
                                    }}
                                    onClose={() => setProjectManagerQuery("")}
                                    queryDisplay={(pm) => (pm ? pm.display_name : "")}
                                    onQueryChange={(query) => setProjectManagerQuery(query)}
                                >
                                    {filteredPMs.map((pm) => (
                                        <ComboboxOption key={pm.uuid} value={pm}>
                                            <Label className={"flex items-center gap-2"}>
                                                <Avatar
                                                    square={true}
                                                    className={"size-5"}
                                                    initials={pm.display_name
                                                        .split(" ")
                                                        .map((c) => c[0])
                                                        .join("")}
                                                />
                                                <span>{pm.display_name}</span>
                                            </Label>
                                        </ComboboxOption>
                                    ))}
                                </Combobox>
                            </Field>
                        </DialogBody>
                        <DialogActions>
                            <Button type={"submit"} color={"blue"}>
                                {t("button.change")}
                            </Button>
                        </DialogActions>
                    </Form>
                </Dialog>
            )}
        </div>
    );
}

export const Route = createFileRoute("/_internal/i/customers/$customerId/_customer/general")({
    component: SingleCustomer,
});
