import { createFileRoute, Navigate } from "@tanstack/react-router";

/**
 * The properties for {@link PentestTargetsRedirect}
 */
export type QuestionnaireRedirectProps = {};

/**
 * Redirect to specific questionnaire
 */
function PentestTargetsRedirect(props: QuestionnaireRedirectProps) {
    const { projectId } = Route.useParams();

    return <Navigate to={"/i/pentests/$projectId/files"} params={{ projectId }} />;
}

export const Route = createFileRoute("/_links/links/_i/i/pentests/$projectId/targets")({
    component: PentestTargetsRedirect,
});
