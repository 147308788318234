/* tslint:disable */
/* eslint-disable */
/**
 * Unnamed API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ApiErrorResponse,
  FormResultForNullAndTestMatrixSettingsErrors,
  FormResultForSetMatrixSettingsResponseAndSetMatrixSettingsFormErrors,
  FullMatrixSettings,
  OptionalForFullMatrixSettings,
  TestMatrixSettingsRequest,
} from '../models/index';

export interface SetMatrixSettingsRequest {
    FullMatrixSettings: FullMatrixSettings;
}

export interface TestMatrixSettingsOperationRequest {
    TestMatrixSettingsRequest: TestMatrixSettingsRequest;
}

/**
 * 
 */
export class MatrixSettingsApi extends runtime.BaseAPI {

    /**
     * Deletes the current matrix settings, disabling matrix messages as a result
     * Deletes the current matrix settings, disabling matrix messages as a result
     */
    async deleteMatrixSettingsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/frontend/v1/admin/matrix-settings`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Deletes the current matrix settings, disabling matrix messages as a result
     * Deletes the current matrix settings, disabling matrix messages as a result
     */
    async deleteMatrixSettings(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteMatrixSettingsRaw(initOverrides);
    }

    /**
     * Retrieves the current matrix settings
     * Retrieves the current matrix settings
     */
    async getMatrixSettingsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OptionalForFullMatrixSettings>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/frontend/v1/admin/matrix-settings`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Retrieves the current matrix settings
     * Retrieves the current matrix settings
     */
    async getMatrixSettings(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OptionalForFullMatrixSettings> {
        const response = await this.getMatrixSettingsRaw(initOverrides);
        return await response.value();
    }

    /**
     * Sets the current matrix settings
     * Sets the current matrix settings
     */
    async setMatrixSettingsRaw(requestParameters: SetMatrixSettingsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FormResultForSetMatrixSettingsResponseAndSetMatrixSettingsFormErrors>> {
        if (requestParameters['FullMatrixSettings'] == null) {
            throw new runtime.RequiredError(
                'FullMatrixSettings',
                'Required parameter "FullMatrixSettings" was null or undefined when calling setMatrixSettings().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/frontend/v1/admin/matrix-settings`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['FullMatrixSettings'],
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Sets the current matrix settings
     * Sets the current matrix settings
     */
    async setMatrixSettings(requestParameters: SetMatrixSettingsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FormResultForSetMatrixSettingsResponseAndSetMatrixSettingsFormErrors> {
        const response = await this.setMatrixSettingsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Sends a matrix message to test the current settings
     * Sends a matrix message to test the current settings
     */
    async testMatrixSettingsRaw(requestParameters: TestMatrixSettingsOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FormResultForNullAndTestMatrixSettingsErrors>> {
        if (requestParameters['TestMatrixSettingsRequest'] == null) {
            throw new runtime.RequiredError(
                'TestMatrixSettingsRequest',
                'Required parameter "TestMatrixSettingsRequest" was null or undefined when calling testMatrixSettings().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/frontend/v1/admin/matrix-settings/test`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['TestMatrixSettingsRequest'],
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Sends a matrix message to test the current settings
     * Sends a matrix message to test the current settings
     */
    async testMatrixSettings(requestParameters: TestMatrixSettingsOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FormResultForNullAndTestMatrixSettingsErrors> {
        const response = await this.testMatrixSettingsRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
