import { createFileRoute } from "@tanstack/react-router";

import React from "react";
import HeadingLayout from "src/components/base/heading-layout";
import { useTranslation } from "react-i18next";
import { Api } from "src/api/api";
import Form from "src/components/base/form";
import { ErrorMessage, Field, FieldGroup, Fieldset, Label, RequiredLabel } from "src/components/base/fieldset";
import { useForm } from "@tanstack/react-form";
import { Button } from "src/components/base/button";
import { Input } from "src/components/base/input";
import { toast } from "react-toastify";
import { Checkbox, CheckboxGroup } from "src/components/base/checkbox";

/**
 * The properties for {@link ScheduleDebrief}
 */
export type ScheduleDebriefProps = {};

/**
 * Schedule the kickoff meeting
 */
function ScheduleDebrief(props: ScheduleDebriefProps) {
    const [t] = useTranslation();

    const { taskId } = Route.useParams();

    const scheduleForm = useForm({
        defaultValues: {
            date: "",
            skipDebrief: false,
        },
        // eslint-disable-next-line
        onSubmit: async ({ formApi, value }) => {
            const res = await Api.internal.projects.pentest.scheduleDebriefV0(taskId, {
                debrief_date: value.skipDebrief
                    ? { state: "Canceled" }
                    : {
                          state: "Scheduled",
                          date: new Date(value.date).toISOString(),
                      },
            });

            res.match(
                (res) => {
                    if (res.result === "Ok") {
                        history.back();
                    } else {
                        if (res.error.debrief_date) {
                            formApi.setFieldMeta("date", (meta) => {
                                meta.errors.push(t("error.invalid-date"));
                                return meta;
                            });
                        }
                    }
                },
                (err) => toast.error(err.message),
            );
        },
    });
    const skipDebrief = scheduleForm.useStore((store) => store.values.skipDebrief);

    return (
        <HeadingLayout heading={t("internal.schedule-debrief-v0.heading")}>
            <Form onSubmit={scheduleForm.handleSubmit} className={"max-w-lg"}>
                <Fieldset>
                    <FieldGroup>
                        <scheduleForm.Field name={"date"}>
                            {(fieldApi) => (
                                <Field>
                                    <RequiredLabel>{t("label.debrief-date")}</RequiredLabel>
                                    <Input
                                        disabled={skipDebrief}
                                        type={"datetime-local"}
                                        required={true}
                                        value={fieldApi.state.value}
                                        onChange={(e) => fieldApi.handleChange(e.target.value)}
                                        invalid={fieldApi.state.meta.errors.length > 0}
                                    />
                                    {fieldApi.state.meta.errors.map((e) => (
                                        <ErrorMessage>{e}</ErrorMessage>
                                    ))}
                                </Field>
                            )}
                        </scheduleForm.Field>

                        <scheduleForm.Field name={"skipDebrief"}>
                            {(fieldApi) => (
                                <Field>
                                    <Label>{t("label.skip-debrief")}</Label>
                                    <CheckboxGroup>
                                        <Checkbox checked={fieldApi.state.value} onChange={fieldApi.handleChange} />
                                    </CheckboxGroup>
                                </Field>
                            )}
                        </scheduleForm.Field>

                        <Button type={"submit"}>{t("button.submit")}</Button>
                    </FieldGroup>
                </Fieldset>
            </Form>
        </HeadingLayout>
    );
}

export const Route = createFileRoute("/_internal/i/forms/tasks/$taskId/schedule-debrief-v0")({
    component: ScheduleDebrief,
});
