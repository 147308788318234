/* tslint:disable */
/* eslint-disable */
/**
 * Unnamed API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ApiErrorResponse,
  CreateWebauthnRequest,
  FormResultForNullAndDeleteWebauthnKeyErrors,
  FormResultForWebAuthnSchemaAndCreateWebauthnErrors,
  ListForSimpleWebAuthnKey,
  WebAuthnRegisterResult,
} from '../models/index';

export interface CompleteUsersWebauthnRequest {
    body: any | null;
}

export interface CreateWebauthnKeyRequest {
    CreateWebauthnRequest: CreateWebauthnRequest;
}

export interface DeleteWebauthnKeyRequest {
    uuid: string;
}

/**
 * 
 */
export class UserWebAuthnKeysApi extends runtime.BaseAPI {

    /**
     * Complete the webauthn challenge for registering a new key
     * Complete the webauthn challenge for registering a new key
     */
    async completeUsersWebauthnRaw(requestParameters: CompleteUsersWebauthnRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<WebAuthnRegisterResult>> {
        if (requestParameters['body'] == null) {
            throw new runtime.RequiredError(
                'body',
                'Required parameter "body" was null or undefined when calling completeUsersWebauthn().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/frontend/v1/common/users/me/complete-webauthn`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['body'] as any,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Complete the webauthn challenge for registering a new key
     * Complete the webauthn challenge for registering a new key
     */
    async completeUsersWebauthn(requestParameters: CompleteUsersWebauthnRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<WebAuthnRegisterResult> {
        const response = await this.completeUsersWebauthnRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Adds a WebAuthn key to the logged-in user.  This may only be called by local users.
     * Adds a WebAuthn key to the logged-in user.
     */
    async createWebauthnKeyRaw(requestParameters: CreateWebauthnKeyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FormResultForWebAuthnSchemaAndCreateWebauthnErrors>> {
        if (requestParameters['CreateWebauthnRequest'] == null) {
            throw new runtime.RequiredError(
                'CreateWebauthnRequest',
                'Required parameter "CreateWebauthnRequest" was null or undefined when calling createWebauthnKey().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/frontend/v1/common/users/me/webauthn`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['CreateWebauthnRequest'],
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Adds a WebAuthn key to the logged-in user.  This may only be called by local users.
     * Adds a WebAuthn key to the logged-in user.
     */
    async createWebauthnKey(requestParameters: CreateWebauthnKeyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FormResultForWebAuthnSchemaAndCreateWebauthnErrors> {
        const response = await this.createWebauthnKeyRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Removes a webauthn key  This may only be called by local users.
     * Removes a webauthn key
     */
    async deleteWebauthnKeyRaw(requestParameters: DeleteWebauthnKeyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FormResultForNullAndDeleteWebauthnKeyErrors>> {
        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling deleteWebauthnKey().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/frontend/v1/common/users/me/webauthn/{uuid}`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Removes a webauthn key  This may only be called by local users.
     * Removes a webauthn key
     */
    async deleteWebauthnKey(requestParameters: DeleteWebauthnKeyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FormResultForNullAndDeleteWebauthnKeyErrors> {
        const response = await this.deleteWebauthnKeyRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Retrieves WebAuthn keys for the logged-in user.  This may only be called by local users.
     * Retrieves WebAuthn keys for the logged-in user.
     */
    async listWebauthnKeysRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListForSimpleWebAuthnKey>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/frontend/v1/common/users/me/webauthn`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Retrieves WebAuthn keys for the logged-in user.  This may only be called by local users.
     * Retrieves WebAuthn keys for the logged-in user.
     */
    async listWebauthnKeys(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListForSimpleWebAuthnKey> {
        const response = await this.listWebauthnKeysRaw(initOverrides);
        return await response.value();
    }

}
