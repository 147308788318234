import { createFileRoute, Navigate } from "@tanstack/react-router";

import React from "react";

/**
 * The properties for {@link RedirectTasks}
 */
export type RedirectTasksProps = {};

/**
 * Redirect the user to his tasks
 */
function RedirectTasks(props: RedirectTasksProps) {
    return <Navigate to={"/i/tasks/my"} />;
}

export const Route = createFileRoute("/_links/links/_i/i/tasks/")({
    component: RedirectTasks,
});
