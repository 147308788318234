import { createFileRoute } from "@tanstack/react-router";
import ChangeDate from "../../../../../../../components/internal/change-date";

export const Route = createFileRoute("/_internal/i/pentests/$projectId/_pentest/schedule/change-dialog-date")({
    /**
     * Redirect do Change date component
     *
     * @returns page to change date for the Dialog presentation in a pentest
     */
    component: () => <ChangeDate type={"dialog"} />,
});
