import React, { useEffect } from "react";
import { CustomerReadyTask } from "../api/generated";
import { Api } from "../api/api";
import { toast } from "react-toastify";
import WS from "../api/ws";

/** Data provided by the {@link CUSTOMER_TASKS_CONTEXT} */
export type CustomerTasksContext = {
    /** The current tasks of the customer */
    tasks: Array<CustomerReadyTask>;

    /** Reload the customer's tasks */
    reset: () => void;
};

/** {@link React.Context} to access {@link FullUser user information} */
const CUSTOMER_TASKS_CONTEXT = React.createContext<CustomerTasksContext>({
    tasks: [],

    /**
     * Reset the user's information
     */
    reset: () => {},
});
CUSTOMER_TASKS_CONTEXT.displayName = "CustomerTasksContext";
export default CUSTOMER_TASKS_CONTEXT;

/**
 * The properties for {@link CustomerTaskProvider}
 */
export type CustomerTaskProviderProps = {
    /** The children of the provider */
    children: React.ReactNode;
};

/**
 * The task provider for a customer
 */
export function CustomerTaskProvider(props: CustomerTaskProviderProps) {
    const [tasks, setTasks] = React.useState<Array<CustomerReadyTask>>([]);

    /**
     * Helper function to fetch the tasks of the user
     */
    const fetchReadyTasks = async () => {
        const res = await Api.customer.tasks.ready();

        res.match(
            (tasks) => setTasks(tasks.list),
            (err) => toast.error(err.message),
        );
    };

    useEffect(() => {
        const handler = WS.addEventListener("message.ReloadTasks", () => fetchReadyTasks());
        fetchReadyTasks().then();
        return () => {
            WS.removeEventListener(handler);
        };
    }, []);

    return (
        <CUSTOMER_TASKS_CONTEXT.Provider
            value={{
                tasks,
                reset: fetchReadyTasks,
            }}
        >
            {props.children}
        </CUSTOMER_TASKS_CONTEXT.Provider>
    );
}
