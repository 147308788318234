import React, { useEffect } from "react";
import { Dialog, DialogActions, DialogBody, DialogProps, DialogTitle } from "../../base/dialog";
import { useTranslation } from "react-i18next";
import Form from "../../base/form";
import { Button } from "../../base/button";
import { Api } from "../../../api/api";
import { ErrorMessage, Field, Label, RequiredLabel } from "../../base/fieldset";
import { Input } from "../../base/input";
import { toast } from "react-toastify";
import { Textarea } from "../../base/textarea";
import { InternalUser } from "../../../api/generated";
import { Combobox, ComboboxOption } from "../../base/combobox";
import { Avatar } from "../../base/avatar";

/**
 * The properties for {@link DialogCreateCustomer}
 */
export type DialogCreateCustomerProps = DialogProps & {
    /** The action that should be called if the form was submitted successfully */
    onSubmit: () => void;
};

/**
 * The dialogs to create a new customer
 */
export default function DialogCreateCustomer(props: DialogCreateCustomerProps) {
    const [t] = useTranslation();

    const [legalName, setLegalName] = React.useState("");
    const [legalNameError, setLegalNameError] = React.useState("");
    const [notes, setNotes] = React.useState("");

    const [projectManagers, setProjectManagers] = React.useState<Array<InternalUser>>([]);
    const [projectManagerCurrent, setProjectManagerCurrent] = React.useState<InternalUser | null>(null);
    const [projectManagerQuery, setProjectManagerQuery] = React.useState("");

    /**
     * The api call to create the customer
     */
    const createCustomer = () => {
        projectManagerCurrent &&
            Api.internal.customers
                .create({
                    legal_name: legalName,
                    notes,
                    project_manager: projectManagerCurrent.uuid,
                })
                .then((res) =>
                    res.match(
                        (res) => {
                            if (res.result === "Ok") {
                                props.onSubmit();
                            } else {
                                if (res.error.legal_name) {
                                    setLegalNameError(t("internal.create-customer.error.legal-name-exists"));
                                }
                            }
                        },
                        (err) => toast.error(err.message),
                    ),
                );
    };

    useEffect(() => {
        Api.internal.internalUsers.all().then((res) =>
            res.match(
                (list) => setProjectManagers(list.list.filter((user) => user.groups.project_management)),
                (err) => toast.error(err.message),
            ),
        );
    }, []);

    const filteredPMs =
        projectManagerQuery === ""
            ? projectManagers
            : projectManagers.filter((pm) => pm.display_name.toLowerCase().includes(projectManagerQuery.toLowerCase()));

    return (
        <Dialog open={props.open} onClose={props.onClose}>
            <Form onSubmit={() => createCustomer()}>
                <DialogTitle>{t("internal.create-customer.heading")}</DialogTitle>
                <DialogBody>
                    <Field>
                        <RequiredLabel>{t("label.legal-business-name")}</RequiredLabel>
                        <Input
                            autoFocus={true}
                            type={"text"}
                            autoComplete={"billing name"}
                            value={legalName}
                            required={true}
                            invalid={legalNameError !== ""}
                            onChange={(e) => setLegalName(e.target.value)}
                        />
                        <ErrorMessage>{legalNameError}</ErrorMessage>
                    </Field>

                    <Field>
                        <RequiredLabel>{t("label.project-manager")}</RequiredLabel>
                        <Combobox
                            value={projectManagerCurrent}
                            onChange={(pm) => setProjectManagerCurrent(pm)}
                            onClose={() => setProjectManagerQuery("")}
                            queryDisplay={(pm) => (pm ? pm.display_name : "")}
                            onQueryChange={(query) => setProjectManagerQuery(query)}
                        >
                            {filteredPMs.map((pm) => (
                                <ComboboxOption key={pm.uuid} value={pm}>
                                    <Label className={"flex items-center gap-2"}>
                                        <Avatar
                                            square={true}
                                            className={"size-5"}
                                            initials={pm.display_name
                                                .split(" ")
                                                .map((c) => c[0])
                                                .join("")}
                                        />
                                        <span>{pm.display_name}</span>
                                    </Label>
                                </ComboboxOption>
                            ))}
                        </Combobox>
                    </Field>

                    <Field>
                        <Label>{t("label.notes")}</Label>
                        <Textarea value={notes} onChange={(e) => setNotes(e.target.value)} />
                    </Field>
                </DialogBody>
                <DialogActions>
                    <Button type={"submit"} color={"blue"}>
                        {t("internal.create-customer.button")}
                    </Button>
                </DialogActions>
            </Form>
        </Dialog>
    );
}
