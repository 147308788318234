import { createFileRoute, Outlet } from "@tanstack/react-router";

import React from "react";
import USER_CONTEXT from "src/context/user";

/**
 * The properties for {@link CustomerGuard}
 */
export type CustomerGuardProps = {};

/**
 * A guard that only
 */
function CustomerGuard(props: CustomerGuardProps) {
    const { user } = React.useContext(USER_CONTEXT);

    if (user.permissions.role !== "Customer") {
        // TODO: Better error view
        return undefined;
    }

    return <Outlet />;
}

export const Route = createFileRoute("/_links/links/_c")({
    component: CustomerGuard,
});
