import { createFileRoute, Navigate } from "@tanstack/react-router";

import React from "react";
import USER_CONTEXT, { UserProvider } from "../context/user";

/**
 * The properties for {@link RoleGuard}
 */
export type RoleGuardProps = {};

/**
 * A guard for distributing on sub-routes based on the user role
 */
export default function RoleGuard(props: RoleGuardProps) {
    const userContext = React.useContext(USER_CONTEXT);

    const role = userContext.user.permissions.role;

    if (role === "Customer") {
        return <Navigate to={"/c/dashboard"} />;
    } else if (role === "Internal") {
        return <Navigate to={"/i/dashboard"} />;
    } else if (role === "Administrator") {
        return <Navigate to={"/a/dashboard"} />;
    }
}

export const Route = createFileRoute("/")({
    /**
     * Wrapper around the index route to forward to the correct sub-path
     *
     * @returns JSX
     */
    component: () => (
        <UserProvider>
            <RoleGuard />
        </UserProvider>
    ),
});
