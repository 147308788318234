import { createFileRoute } from "@tanstack/react-router";

import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Api } from "src/api/api";
import { toast } from "react-toastify";
import { FullQuestion } from "src/api/generated";
import { Text } from "src/components/base/text";
import EditQuestionInfo from "src/components/internal/edit-question/edit-question-info";
import EditQuestionNumber from "src/components/internal/edit-question/edit-question-number";
import EditQuestionBoolean from "src/components/internal/edit-question/edit-question-boolean";
import EditQuestionSingleChoice from "src/components/internal/edit-question/edit-question-single-choice";
import EditQuestionMultipleChoices from "src/components/internal/edit-question/edit-question-multiple-choices";

/**
 * The properties for {@link EditQuestionnaireQuestion}
 */
export type EditQuestionnaireQuestionProps = {};

/**
 * The edit view for a questionnaire question
 */
function EditQuestionnaireQuestion(props: EditQuestionnaireQuestionProps) {
    const [t] = useTranslation();
    const { questionId } = Route.useParams();

    const [question, setQuestion] = React.useState<FullQuestion>();

    /**
     * Helper function to retrieve the current question
     */
    const loadQuestion = async () => {
        const res = await Api.internal.questionnaires.questions.get(questionId);
        res.match(
            (question) => setQuestion(question.optional),
            (err) => toast.error(err.message),
        );
    };

    useEffect(() => {
        loadQuestion().then();
    }, []);

    return question === undefined ? (
        <Text>{t("label.loading")}</Text>
    ) : (
        <>
            {question.data.kind === "Info" && (
                <EditQuestionInfo question={question} data={question.data} reloadQuestion={loadQuestion} />
            )}
            {question.data.kind === "Number" && (
                <EditQuestionNumber question={question} data={question.data} reloadQuestion={loadQuestion} />
            )}
            {question.data.kind === "Boolean" && (
                <EditQuestionBoolean question={question} data={question.data} reloadQuestion={loadQuestion} />
            )}
            {question.data.kind === "SingleChoice" && (
                <EditQuestionSingleChoice question={question} data={question.data} reloadQuestion={loadQuestion} />
            )}
            {question.data.kind === "MultipleChoices" && (
                <EditQuestionMultipleChoices question={question} data={question.data} reloadQuestion={loadQuestion} />
            )}
        </>
    );
}

export const Route = createFileRoute("/_internal/i/questionnaires/questions/$questionId")({
    component: EditQuestionnaireQuestion,
});
