import { createFileRoute } from "@tanstack/react-router";

import React from "react";
import { Heading } from "../../../components/base/heading";
import { useTranslation } from "react-i18next";
import USER_CONTEXT from "../../../context/user";

/**
 * The properties for {@link InternalDashboard}
 */
export type InternalDashboardProps = {};

/**
 * The dashboard for internals
 */
export default function InternalDashboard(props: InternalDashboardProps) {
    const [t] = useTranslation();
    const { user } = React.useContext(USER_CONTEXT);

    return (
        <div>
            <Heading>{t("internal.dashboard.heading.welcome", { name: user.display_name })}</Heading>
        </div>
    );
}

export const Route = createFileRoute("/_internal/i/dashboard")({
    component: InternalDashboard,
});
