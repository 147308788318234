/* tslint:disable */
/* eslint-disable */
/**
 * Unnamed API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ApiErrorResponse,
  CreateCustomerRequest,
  CustomerFullCustomer,
  FormResultForSingleUuidAndCreateCustomerErrors,
  ListForAdminCustomer,
  ListForSimpleCustomer,
  OptionalForFullCustomer,
  UpdateCustomerInternalRequest,
} from '../models/index';

export interface CreateCustomerOperationRequest {
    CreateCustomerRequest: CreateCustomerRequest;
}

export interface GetCustomerInternalRequest {
    uuid: string;
}

export interface UpdateCustomerInternalOperationRequest {
    uuid: string;
    UpdateCustomerInternalRequest: UpdateCustomerInternalRequest;
}

/**
 * 
 */
export class CustomersApi extends runtime.BaseAPI {

    /**
     * Creates a new customer
     * Creates a new customer
     */
    async createCustomerRaw(requestParameters: CreateCustomerOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FormResultForSingleUuidAndCreateCustomerErrors>> {
        if (requestParameters['CreateCustomerRequest'] == null) {
            throw new runtime.RequiredError(
                'CreateCustomerRequest',
                'Required parameter "CreateCustomerRequest" was null or undefined when calling createCustomer().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/frontend/v1/internal/customers`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['CreateCustomerRequest'],
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Creates a new customer
     * Creates a new customer
     */
    async createCustomer(requestParameters: CreateCustomerOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FormResultForSingleUuidAndCreateCustomerErrors> {
        const response = await this.createCustomerRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Retrieves a list of all customers
     * Retrieves a list of all customers
     */
    async getAllCustomersAdminRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListForAdminCustomer>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/frontend/v1/admin/customers`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Retrieves a list of all customers
     * Retrieves a list of all customers
     */
    async getAllCustomersAdmin(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListForAdminCustomer> {
        const response = await this.getAllCustomersAdminRaw(initOverrides);
        return await response.value();
    }

    /**
     * Retrieves all customers the user has access to
     * Retrieves all customers the user has access to
     */
    async getAllCustomersInternalRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListForSimpleCustomer>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/frontend/v1/internal/customers`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Retrieves all customers the user has access to
     * Retrieves all customers the user has access to
     */
    async getAllCustomersInternal(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListForSimpleCustomer> {
        const response = await this.getAllCustomersInternalRaw(initOverrides);
        return await response.value();
    }

    /**
     * Retrieve the user\'s customer
     * Retrieve the user\'s customer
     */
    async getCustomerCustomerRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CustomerFullCustomer>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/frontend/v1/customer/customers`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Retrieve the user\'s customer
     * Retrieve the user\'s customer
     */
    async getCustomerCustomer(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CustomerFullCustomer> {
        const response = await this.getCustomerCustomerRaw(initOverrides);
        return await response.value();
    }

    /**
     * Retrieves more detail information about a single customer
     * Retrieves more detail information about a single customer
     */
    async getCustomerInternalRaw(requestParameters: GetCustomerInternalRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OptionalForFullCustomer>> {
        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling getCustomerInternal().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/frontend/v1/internal/customers/{uuid}`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Retrieves more detail information about a single customer
     * Retrieves more detail information about a single customer
     */
    async getCustomerInternal(requestParameters: GetCustomerInternalRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OptionalForFullCustomer> {
        const response = await this.getCustomerInternalRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Updates an existing customer
     * Updates an existing customer
     */
    async updateCustomerInternalRaw(requestParameters: UpdateCustomerInternalOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling updateCustomerInternal().'
            );
        }

        if (requestParameters['UpdateCustomerInternalRequest'] == null) {
            throw new runtime.RequiredError(
                'UpdateCustomerInternalRequest',
                'Required parameter "UpdateCustomerInternalRequest" was null or undefined when calling updateCustomerInternal().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/frontend/v1/internal/customers/{uuid}`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['UpdateCustomerInternalRequest'],
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Updates an existing customer
     * Updates an existing customer
     */
    async updateCustomerInternal(requestParameters: UpdateCustomerInternalOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateCustomerInternalRaw(requestParameters, initOverrides);
    }

}
