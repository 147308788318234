/**
 * Helper function to sort dates ascending
 *
 * @param a First date
 * @param b Second date
 *
 * @returns The Number for the sorting function
 */
export function sortDates(a: undefined | Date, b: undefined | Date): number {
    if (!a && !b) {
        return 0;
    } else if (!a) {
        return 1;
    } else if (!b) {
        return -1;
    } else if (a < b) {
        return -1;
    } else {
        return 1;
    }
}
